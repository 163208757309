@import "../../../styles/Variables.scss";
.saiko-select , .saiko-select__menu-portal {
  cursor: pointer;
  border: 0.1875em solid var(--borderInput);
  outline: none;
  border-radius: 0.3125em;
  height: 3.125em;
  * {
    color: var(--primaryColor) !important;
  }
  div {
    cursor: pointer;
    background-color: var(--cardBackground);
    border: none;
  }
  &:focus {
    outline: none;
  }
}

.saiko-select__menu{
  // z-index: 10000 !important;
  // background-color: red !important;
}

.saiko-select__single-value,
.saiko-select__indicator,
.saiko-select__placeholder {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 3.125em;
}

.saiko-select__control,
.saiko-select__value-container,
.saiko-select__indicators {
  height: 100%;
  cursor: pointer;
}

.saiko-select__indicator {
  height: 80%;
  cursor: pointer;
}

.saiko-select__menu , .saiko-select__menu-portal {
  * {
    color: var(--primaryColor) !important;
  }
}

.saiko-select__menu-portal{
  height: 0 !important;
}

@media (prefers-color-scheme: dark) {
  .saiko-select , .saiko-select__menu-portal {
    border-color: var(--primaryBackground);
    div {
      background: var(--primaryBackground);
    }
  }
}
