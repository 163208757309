.downloadBtn {
  border-radius: 999px;
  width: 40px;
  height: 40px;
  background: transparent;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease;

  &:hover {
    background: var(--primaryBackground);
    cursor: pointer;
  }

  &:nth-of-type(2) svg path {
    fill: #d62424;
  }

  &:nth-of-type(3) svg path {
    fill: rgb(24, 131, 180);
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (prefers-color-scheme: dark) {
  .downloadBtn {
    &:nth-of-type(2) svg path {
      fill: #ff7878;
    }
  
    &:nth-of-type(3) svg path {
      fill: rgb(102, 203, 250);
    }
  }
}