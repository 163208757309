.requested{
    display: flex;
    gap:0 30px;
    align-items: center;
    p{
        margin:0;
    }
}

.content{
    display: flex;
    flex-direction: column;
    gap:20px 0;
}