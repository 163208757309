.formProyect {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  margin: 20px 30%;
}

.headerForm {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

.buttonContainer {
  button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .formProyect {
    margin: 20px;
  }
}
