.filterAssociateFiles {
  display: flex;
  > div:nth-child(1) {
    width: 30%;
  }

  svg {
    display: none;
  }
  gap: 0 1rem;
  align-items: flex-end;
}

.row,
.header {
  p {
    margin: 0;
  }
  display: grid;
  padding: 0.5rem 0;
  grid-template-columns: 10% 15% 30% 10% 17.5% 17.5%;
}

.row {
  > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  border: 1px solid var(--primaryColor);
  border-style: none none solid none;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal !important;
  }
}

.header {
  text-align: center;
  font-weight: bold;
}

.containerRows {
  height: 40vh;
  overflow: auto;
  transition: all 0.25s ease;
  label {
    font-weight: normal !important;
    &:hover {
      background: var(--secondaryBackground);
    }
  }

  input:checked + label {
    background: var(--secondaryBackground);
  }

  .row:last-of-type {
    border: none;
  }
}

.tableFiles {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 10px;
}

.optionsDelete {
  display: flex;
  justify-content: flex-end;
  gap: 0 10px;
}

.container {
}

@media (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .filterAssociateFiles {
    > div:nth-child(1) {
      width: 60%;
    }

    span {
      display: none;
    }
    svg {
      display: inline-block;
    }
  }

  .row,
  .header {
    grid-template-columns: 20% 80%;
    > *:nth-child(2) {
      display: none;
    }

    > *:nth-child(n + 4) {
      display: none;
    }
  }
}
