.buttonContainer {
  button {
    width: 100%;
  }
}

.ivaContainer {
  display: flex;
  gap: 0 10px;
  align-items: center;
}

.ivaPercentageContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.positionStatus{
  display: flex;
  gap: 0 20px;
}