.containerBankModule {
  height: 100%;
  overflow: auto;

  > *:nth-child(1) {
    position: sticky;
    top: 0;
    padding: 0 0 1rem 0;
  }
}

.sectionCard {
  margin: 0 1rem;

  @media (max-width: 767px) {
    h2 {
      text-align: center;
    }
  }
}

.containerBankAccounts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 34%;
  overflow: auto;

  @media (max-width: 767px) {
    display: none;
  }
}

.containerBankAccounts > article {
  width: 350px;
  margin: 0 0 10px 0;
}

@media screen and (max-width: 576px) {
  .containerBankAccounts > article {
    width: 100%;
  }
}

.containerCards {
  display: grid;
  grid-template-columns: 45% 45%;
  grid-template-rows: 70vh;
  overflow: auto;
}

.movementOverview {
  transition: all 0.25s ease;

  color: var(--primaryColor);

  h4 {
    padding: 0 20px;
  }

  summary {
    padding: 15px 20px;
  }

  summary {
    text-align: left;
  }

  details {
    details {
      summary {
        padding: 15px 40px;
      }
    }
  }

  .row,
  .header {
    display: grid;
    grid-template-columns: repeat(9, 11.1%);
  }

  .row {
    font-weight: normal;
    margin: 10px 0;
  }

  .header {
    font-weight: bold;
  }
}

@media only screen and (min-width: 768px) {
  .movementOverview {
    summary {
      width: 1000px;
    }
  }
}
