.tableUens {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background: var(--inputBackground);
  border-radius: 10px;
  padding: 1rem;

  input:checked + label {
    background-color: var(--selectedRow);
  }

  label {
    transition: background 0.25s ease;
    min-height: 2rem;
    &:hover {
      background-color: var(--secondaryBackground);
    }
  }
}

.header {
  font-weight: bold;
  text-align: center;
}

.row,
.header {
  display: grid;
  justify-content: space-around;
  grid-template-columns: 10% 10% 10% 16% 16% 7.5% 7.5% 7.5% 7.5%;
  align-items: center;
  p {
    margin: 0;
    padding: 5px 0;
  }
}

.row {
  font-weight: normal;
  text-align: left;
  border: 1px solid var(--secondaryBackground);
  border-style: none none solid none;
}

.containerRows {
  overflow: auto;
  height: 40vh;
}

.percentage {
  &::after {
    content: "%";
  }
  text-align: right;
}

.uenMobile {
  padding:1rem;
  display: none;
  background: var(--primaryBackground);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  color:var(--primaryColor);
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .uenMobile {
    display: block;
    margin: 1rem 0 0 0;
  }

  .tableUens {
    display: none;
  }
}
