.iconCheck svg {
  height: 30px;
}

.error svg{
  color: var(--warning);
}

.correct svg {
  color: var(--success);
}
.question svg{
  color: var(--primaryColor);
}
