$gray-dialog: rgb(231, 231, 231);

.dialog[open] {
  -webkit-animation: myFadeIn 1s ease normal;
}

.dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.61);
}

.dialog {
  background: var(--primaryBackground);
  border: none !important;
  width: 50vw;
  max-height: 80vh;
  padding: 0;
  transition: opacity 0.5s;
  // margin:0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.dialogTitle {
  position: sticky;
  > p {
    white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide the overflow of text */
  text-overflow: ellipsis;
  }
  color: var(--primaryColor);
  top: 0;
  display: flex;
  padding:15px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $gray-dialog;
  border-style: none none solid none;
  z-index: 50;
  button {
    border: 1.5px solid var(--primaryBackground);
    background: var(--primaryBackground);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50%;
    transition: all 0.25s ease;
    svg {
      color: var(--primaryColor);
      display: inline-block !important;
    }
    &:hover {
      background: transparent;
      border-color: var(--primaryColor);
    }
  }
}

.dialogBody {
  padding: 0 15px;
  margin: 15px 0;
  overflow-y: auto;
  box-sizing: border-box;
}

.dialogFooter {
  padding: 15px;
  border: 1px solid $gray-dialog;
  border-style: solid none none none;
  position: sticky;
  bottom: 0;
}

.dialogFooter,
.dialog,
.dialogTitle {
  background: var(--secondaryBackground);
}

@-webkit-keyframes myFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 769px) {
  .dialog {
    width: 100vw !important;
    height: 100dvh !important;
    max-width: 100vw !important;
    min-height: 100dvh !important;
    border-radius: 0;
  }

  .dialogFooter,
  .dialogTitle {
    height: 10svh !important;
  }

  .dialogBody{
    height: 80svh !important;
  }
}

//////////////////////// DARK MODE ////////////////////////

// @media (prefers-color-scheme: dark) {
//   .loginForm {
//     box-shadow: rgba(0 0 0 / 20%) 0px 8px 24px;
//   }

//   .loginContainer {
//     > img:nth-child(1) {
//       content: url("https://images.unsplash.com/photo-1510519138101-570d1dca3d66?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGFyayUyMG9mZmljZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80");
//     }
//   }
// }
