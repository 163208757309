.titleMobile {
  display: none;
  align-items: center;
  justify-content: space-between;

  > button {
    display: none;
  }

  > p {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .titleMobile {
    // z-index: 0;
    display: flex;

    width: 100%;
    justify-content: space-between;
  }
}
