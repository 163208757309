.header {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  position: sticky;
  top: 0;
  padding: 0;
  background: #ffffff;
}

.module {
  height: 100%;
  overflow: auto;
}

.margin {
  margin: 0 5rem;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .margin {
    margin: 0 1rem;
  }

  .header {
    padding: 0 0 1rem 0;
  }
}

/////////////////////////////////////////////// MODO OSCURO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .header {
    background: var(--secondaryBackground);
  }
}
