.row,
.header {
  display: grid;
  grid-template-columns: repeat(8, 12%);
  justify-content: space-between;

  > p {
    margin: 0;
    padding: 5px 0;
  }

  margin: 0 10px ;
}

.header {
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  position: sticky;
  top:0;
}

.row {
  font-weight: normal;
 
  padding:5px 0;
  
   
  > *:nth-child(1),
  > *:nth-child(2),
  > *:nth-child(4) {
    text-align: center;
  }

  > *:nth-child(5),
  > *:nth-child(6),
  > *:nth-child(7) {
    text-align: right;
  }

  > *:nth-child(8) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.table{
  background: var(--inputBackground);
  max-height: 40vh;
  border-radius: 10px;
  margin:0 10%;
  position: relative;

  input:checked + label{
    background: var(--selectedRow);
  }

  label{
    
    transition: background 0.2s ease;
    &:hover{
      background: var(--secondaryBackground);
    }
  }

  >*:last-child{
    position: sticky;
    bottom: 0;
    padding: 10px;
    border-radius: 10px;

  }
}