.ocOverview {
  display: grid;
  gap: 0.5rem 0;
  background: var(--cardBackground);
  border-radius: 10px;
  color:var(--primaryColor);
  padding: 10px;

  grid-template-columns: repeat(5, 20%);
  p {
    margin: 0;
  }

  > div:nth-child(1) {
    text-align: left;
    display: flex;
    font-weight: bold;
    > p:nth-child(2) {
      margin: 0 0 0 1rem;
      &::before {
        content: "#";
      }
    }
  }

  > p:nth-child(2) {
    font-weight: bold;
  }

  > p:nth-child(3) {
    grid-column: 5/6;
    text-align: right;
    font-weight: bold;
  }

  > p:nth-child(4),
  > p:nth-child(5) {
    grid-column: 1/6;
    text-align: left;
  }
}

.invoiceRow,
.movementsRowHeader,
.invoiceRowHeader {
  p {
    margin: 0;
    padding:0.25rem 0;
  }
  display: grid;
  grid-template-columns: repeat(7, 14%);
  justify-content: space-between;
}

.header{
  font-weight: bold;
  font-size: 1.5rem;
  margin:0;
}

.invoiceRow {
  > *:nth-child(1) {
    text-align: right !important;
  }

  > *:nth-child(4),
  > *:nth-child(2),
  > *:nth-child(3) {
    text-align: center !important;
  }

  > *:nth-child(n + 5) {
    text-align: right !important;
  }
}

.invoiceRowHeader {
  background-color: rgb(201, 201, 201);

  > * {
    text-align: center !important;
  }
  font-weight: bold;
}

.movementsRowHeader {
  background: #ff95004d;
  font-weight: bold;
  > *  {
    text-align: center !important;
  }
}

.invoiceAndMovements {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  overflow: auto;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
