.customerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 767px) {
  .customerHeader {
    flex-direction: column;

    > *:nth-child(1) {
      width: 100% !important;
      flex-direction: column;
    }

    > button {
      width: 100%;
      margin:0.5rem 0;
    }
  }
}
