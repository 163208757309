.margin {
  margin: 0 5rem;
}

.filter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.desktop {
  display: flex;
  align-items: flex-end;
  gap: 0 1rem;
}

.module{
  height: 100%;
  overflow: auto;
}

.mobile {
}

@media (max-width: 767px) {
  .filter {
    justify-content: flex-start;
    gap: 0 1rem;
    button{
      padding: 0 1rem;
    }
  }

  .margin {
    margin: 0 1rem;
  }

  .mobile {
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem 0;
    }
  }

  .desktop {
    width: 100%;
    form {
      width: 100%;
      > *:nth-child(-n + 4) {
        display: none;
      }
      > div{
        width: 100%;
      }
    }
  }
}
