.filter {
  display: flex;
  gap: 0 1rem;
  align-items: flex-end;



  > *:nth-child(1) {
    width: 20%;
  }
}

////////////////////////////////////////////  [RESPONSIVE]  ////////////////////////////////////////////

@media (max-width: 767px) {
  .filter {

    margin:0 1rem !important;

    > *:nth-child(-n + 2) {
      display: none;
    }

    > *:nth-child(n + 4) {
      display: none;
    }

    > *:nth-child(6) , > *:nth-child(8) , > *:nth-child(7) {
      display: block;
      padding: 0 1rem !important;
    }

    > *:nth-child(7){
      padding:0 !important;
      button{
        padding: 0 1rem !important;
      }
    }

    > *:nth-child(3) {
      width: 100%;
    }
    
  }
}
