.containerItems {
  display: flex;
  gap: 0.5rem 0;
  flex-direction: column;
}

.module {
  width: 100%;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .module {
    overflow: auto;
  }

  .containerItems{
    width: 1500px;
  }
}
