.containerClaveProd {
  border-radius: 0.3125em 0 0 0.3125em;
  cursor: not-allowed;
  background: var(--inputBackground);
  padding: 0 2.5%;
  height: 3.125em;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  color: var(--primaryColor);
  border: 0.1875em solid #e5e5e5;
  border-style: solid none solid solid;
}

.editClave {
  border: 0.1875em solid #e5e5e5;
  border-radius: 0 0.3125em 0.3125em 0;
  cursor: not-allowed;
  border-style: none;
  background: var(--inputBackground);
  padding: 0 2.5%;
  color: var(--primaryColor);
  height: 3.125em;
  display: flex;
  align-items: center;
  cursor: default;
  border-style: solid solid solid none;
}

.searchClave {
  display: flex;
  align-items: flex-end;
  width: 100%;
  .searchBar {
    width: 80%;
    margin: 0 10px 0 0;
  }
}

.containerSearchFounded {
  max-height: 35vh;
  overflow: auto;
  margin: 10px 0;
}

.rowSearchFounded {
  font-weight: normal;
  display: flex;
  align-items: center;
  * {
    margin: 0;
  }

  transition: 0.15s ease-in;
  &:hover {
    background-color: #ebebeb;
  }
}

.newClaveProductoServicio {
  display: flex;
}

@media (prefers-color-scheme: dark) {
  .containerClaveProd,
  .editClave {
    border: none;
  }
}
