.container {
  * {
    font-size: 1rem;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;
}
