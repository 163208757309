.row {
  display: flex;
  gap: 0 2rem;
  color: var(--primaryColor);
  margin: 0 0 1rem 0;
}

.container{
  display: flex;
  flex-direction: column;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {

  .container{
    padding: 0 1rem;;
  }

  .row {
    padding:0 1rem;
    flex-direction: column;
    gap: 1rem 0;
  }
}
