.header {
  display: flex;
  gap: 0 20px;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 0 20px;
    align-items: center;
  }
}
