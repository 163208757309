.item {
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 15px;
  display: none;
  label {
    display: block;
    font-weight: bold;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .item {
    display: flex;
    margin: 0 0 1rem 0;
    flex-direction: column;
  }
}
