.createTicket {
  padding: 20px 5px;
  position: sticky;
  top: 0;
  background: var(--primaryBackground);
  cursor: default;
  font-weight: bold;
  transition: 0.4s ease;
  &:hover {
    background-color: var(--secondaryBackground);
  }
  border: 0.063rem solid var(--primaryColor);
  border-style: none none solid none;
  text-align: center;
}
