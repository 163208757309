.options {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.filterMobile {
  display: none;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .options {
    gap:0 1rem;
    > button {
      padding: 0 1rem;
      width: auto;
    }
  }

  .filterMobile {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
}
