.header {
  font-weight: bold;

  text-align: center;
}

.headerAmount {
  span {
    display: inline-block;
  }

  span {
    width: 50%;
  }

  span:nth-child(1) {
    width: 100%;
  }
}

.header,
.row {
  display: grid;
  grid-template-columns: 15% 15% 5% 13% 13% 13% 13% 13%;
}

.row {
  padding: 10px 0;

  span {
    padding: 0 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  span:nth-child(1),
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4),
  span:nth-child(5) {
    justify-content: center;
  }

  span:nth-child(6),
  span:nth-child(7),
  span:nth-child(8) {
    justify-content: flex-end;
  }
}

.amountRow {
  span {
    display: inline-block;
    width: 50%;
    text-align: right;
  }
}
