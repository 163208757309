.fieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  height: 60vh;
}

.loadingEmailComponent{
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerModalEmail{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom:0;
  padding:10px 0;
  background: var(--secondaryBackground);
  border:1px solid var(--primaryColor);
  border-style: solid none none none;
  margin:10px 0 0 0;
}

.emailFooterModal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
