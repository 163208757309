.containerTransference {
  display: grid;
  grid-template-columns: repeat(8, 11.5%);
  grid-template-rows: auto;
  justify-content: space-between;
}

.origin {
  grid-column: 1/5;
  grid-row: 1/2;
}

.destiny {
  grid-column: 5/9;
  grid-row: 1/2;
}

.date {
  margin: 10px 0;
}

.originAmounts {
  display: grid !important;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px 0;
  border: 2px solid rgb(240, 240, 240);
  border-style: solid none solid none;
  grid-column: 1/5;
  grid-row: 2/3;
  display: flex;
  justify-content: space-between;
  // > div {
  //   width: 20%;
  // }
}

.destinyAmounts {
  display: grid !important;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px 0;
  border: 2px solid rgb(240, 240, 240);
  border-style: solid none solid none;
  grid-column: 5/9;
  grid-row: 2/3;
  display: flex;
  justify-content: space-between;
  //  >div {
  //   width: 20%;
  // }
}

.originCard {
  grid-column: 1/5;
  grid-row: 3/4;
}

.destinyCard {
  grid-column: 5/9;
  grid-row: 3/4;
}

//////////////////// RESPONSIVE ////////////////////

@media (max-width: 767px) {
  .containerTransference {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
  }

  .originAmounts,
  .destinyAmounts {
    display: flex !important;
    flex-direction: column;
    gap: 1rem;
  }

  .originCard,
  .destinyCard {
    display: none !important;
  }
}
