.mobileItem {
  display: none;
}

.items {
  display: flex;
  gap: 0 1rem;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .mobileItem {
    color:var(--primaryColor);
    p {
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
    padding: 15px;
    border-radius: 10px;
    background: var(--primaryBackground);
    margin: 0 0 1rem 0;
  }
}
