$borderColor: #e9e9e9;

.comboOptions {
  border: 1px solid $borderColor;
  border-style: solid none none none;
  display: grid;
  grid-template-columns: repeat(4, 23.5%);
  justify-content: space-between;
  padding: 10px 0;
  margin: 10px 0;
}

.optionsToCreateInvoice{
  display: flex;
  flex-direction: column;
  gap:10px 0;
}

.warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  > p {
    font-weight: bold;
    margin: 0 0 5px 0;
    display: flex;
    align-items: center;
    svg {
      height: 20px;
      margin: 0 5px 0 0;
      color: rgb(211, 162, 0);
    }
  }
}

.containerCreateInvoice {
  display: flex;
  margin: 10px 0 0 0;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $borderColor;
  border-style: solid none none none;
  position: sticky;
  bottom: 0;
  background: var(--secondaryBackground);
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .comboOptions {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
  }
}
