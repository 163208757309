.tutorial {
  overflow: auto;
  .btnTutorial {
    background-color: var(--primaryBackground);
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px 0 0;
    border:none;
  }

  .btnTutorial:last-child {
    margin: 0;
  }

  input{
    display: none;
  }

  input:checked + label{
    border:2.5px solid var(--linkColor);
  }

  .icon {
    height: 30px;
    width: 30px;
  }

  background: var(--cardBackground);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px 0;
}

.browsers{
  display: flex;
  gap:0 10px;
  justify-content: center;
}