$gray: #e1e1e1;

.row,
.totals,
.totalReportRow,
.header {
  display: grid;
  grid-template-columns: 30% 10% 7% 7% 7% 17% 17% 5%;
  justify-content: space-between;
}

.header {
  align-items: center;
  > div {
    display: grid;
    grid-template-columns: 50% 50%;

    > p:nth-child(1) {
      grid-column: 1/3;
    }

    > p:nth-child(2) {
      grid-column: 1/2;
      grid-row: 2/3;
    }
    > p:nth-child(3) {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }
}

.amountCxp {
  display: grid;
  grid-template-columns: 50% 50%;
  > p {
    justify-content: flex-end;
    width: 100%;
    text-align: right;
  }
}

.header {
  font-weight: bold;
  text-align: center;
}

.row,
.totals,
.totalReportRow {
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0;
    display: flex;
    align-items: center;
  }

  > p:nth-child(4),
  > p:nth-child(5),
  > p:nth-child(6) {
    justify-content: center;
  }

  > p:last-of-type {
    justify-content: flex-end;

    margin: 0 10px;
    &::after {
      content: "%";
      margin: 0 0 0 2.5px;
    }
  }

  transition: background 0.25s ease;
  &:hover {
    background: var(--secondaryBackground);
  }
}

.totals {
  border: 1px solid var(--primaryColor);
  border-style: solid none solid none;
  p:last-of-type {
    &::after {
      content: "";
    }
  }
}

.tableContainer {
  background: var(--primaryBackground);
  padding: 15px;
  color: var(--primaryColor);
  border-radius: 15px;
  p {
    margin: 0;
    padding: 5px 0;
  }
}

.contentTable {
  height: 35vh;
  overflow: auto;
}

.totalReportRow {
  border: 1px solid var(--primaryColor);
  border-style: solid none none none;

  > p:last-of-type {
    &::after {
      content: "";
    }
  }
}

@media (max-width: 767px) {
  .tableContainer {
    width: 1700px;
  }
}
