.openComments {
  color:var(--primaryColor) !important;
  cursor: default;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
}

.list{
    li{
        list-style: decimal;
        margin:0 0 10px 0;
    }
}