.filter {
  display: none;
}

.containerFooter {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    height: 100%;
    padding: 0 1rem;
  }
}

.contentFilter {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

@media (max-width: 767px) {
  .filter {
    overflow: auto;
    margin: 0 1rem !important;
    display: flex;
    padding: 0 0 1rem 0;

    > * {
      margin:0 1rem 0 0;
      width: 100%;
    }

    > *:nth-child(1) {
      background: #ffffff;
      position: sticky;
      left: 0;
      padding: 0 1rem 0 0;
      margin:0;
      button {
        height: 100%;
      }
    }

    > button,
    > div {
      height: auto !important;
    }
  }
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .filter {
    background: var(--secondaryBackground);
    > *:nth-child(1) {
      background: var(--secondaryBackground);
    }
  }
}
