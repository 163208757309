.filter {
  display: flex;
  > div:nth-child(1),
  > div:nth-child(2),
  > div:nth-child(3) {
    width: 33%;
  }
  gap: 0.5rem;
  align-items: flex-start;
  height: 40vh;

}

.containerExcelDownload{
  display: flex;
  justify-content: flex-end;
}