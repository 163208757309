.container {
  height: 100%;
  overflow: auto;
}

.formProyect {
  background: var(--cardBackground);
  padding: 20px;
  border-radius: 10px;
}

//////////////////////////////////////

.header {
  display: flex;

  >*:nth-child(1){
    display: flex;
    width: 85%;
    gap: 40px;

  }

  margin:0 0 30px 0;
  align-items: center;
  gap: 40px;
  * {
    color: var(--primaryColor);
  }
  p {
    margin: 0;
  }
  justify-content: space-between;
}

.sr {
  width: 30%;
}

.content{
  display: grid;
  grid-template-columns: 80% 20%;
}

.footerDescription{
  display: flex;
  gap:0 25px;
  flex-wrap: wrap;
  b{
    &:after{
      content:":";
      margin:0 5px 0 0;
    }
  }
}

.actionsContainer{
  display: flex;
  justify-content: center;
  gap:20px;
}

.actions{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primaryBackground);
  border-radius: 999px;
  border:none;
  height: 30px;
  width: 30px;
  svg{
    height: 50%;
  }
}

.fieldsRight{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  *{
    color:var(--primaryColor);
  }
  p{
    margin:0;
  }

  > div{
    align-items: center;
    display: flex;
    gap: 10px;
  }
}

.containerFieldsRight{
  display: flex;
  flex-direction: column;
  gap:15px;
}