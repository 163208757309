.sortIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: rgb(255, 255, 255);
  }

  &:hover {
    cursor: move;
    
    svg {
        fill: black;
    }

    
  }
}

@media (prefers-color-scheme: dark) {
  .sortIcon {
    &:hover {
      fill: var(--primaryColor);
      svg {
        fill: black;
      }
    }

    svg {
      fill: var(--primaryColor);
    }
  }
}
