.options {
  display: flex;
  gap: 0 1rem;
  margin: 1rem 0;
  height: 3.125rem;
}

@media (max-width: 767px) {
  .options {
    display: none;
    height: 0;
  }
}
