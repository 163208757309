.containerApp {
  display: grid;
  grid-template-columns: 100dvw;
  grid-template-rows: 10svh 80svh 10svh;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

header {
  grid-area: header;
}

main {
  grid-area: content;
}

footer {
  grid-area: footer;
}

.contextmenu {
  background: var(--cardBackground);
}

.contextmenu__item {
  &:hover {
    background: var(--secondaryBackground) !important;
  }
}

.react-contextmenu {
  border: 1px solid var(--primaryColor);
  border-radius: 5px;
}

.react-contextmenu .react-contextmenu-item:first-of-type {
  border-radius: 5px 5px 0 0;
}

.react-contextmenu .react-contextmenu-item:last-of-type {
  border: none;
  border-radius: 0 0 5px 5px;
}

.react-contextmenu-item {
  overflow: auto;
  padding: 0.5rem 1rem;
  color: var(--primaryColor);
  background-color: var(--cardBackground);
  border: 1px solid var(--primaryColor);
  border-style: none none solid none;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    font-weight: bold;
  }
}

.ReactModal__Content {
  background: var(--cardBackground) !important;
}

@media (max-width: 767px) {
  .ReactModal__Content {
    width: 100vw !important;
    height: 100vh !important;
    padding: 0 !important;
  }
}
