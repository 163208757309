$gray: #e9e9e9;

.headerCatalogue{
  position: sticky;
  top:0;
  padding:0 0 0.5rem 0;
}

.contentCatalogue{
  padding:0 1rem;
}

.containerCatalogue{
  overflow: auto;
  height: 100%;
}

.row,
.header {
  display: grid;
  grid-template-columns: 8% 60% 8% 10% 10%;
  justify-content: space-between;

  > p {
    margin: 0;
    padding: 5px 0;
  }
}

.row {
  font-weight: normal;
  > p {
    white-space: nowrap; /* Prevents text from wrapping to multiple lines */
    overflow: hidden; /* Hides any overflow of the container */
    text-overflow: ellipsis;
  }

  > p:nth-child(3) {
    text-align: center;
  }

  > p:nth-child(1) {
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }

  > p:nth-child(4),
  p:nth-child(5),
  p:nth-child(1) {
    text-align: right;
  }
}

.header {
  font-weight: bold;
  text-align: center;
}

.table{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: var(--inputBackground);
  border-radius: 20px;

  padding: 10px;
}


.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerRows {
  overflow: auto;

  height: 40vh;
  input:checked + label {
    background: var(--selectedRow);
  }

  label {
    padding: 5px 0;
    transition: background 0.25s ease;
    &:hover {
      background: var(--secondaryBackground);
    }
  }
}

.mobileProduct{
  background: var(--primaryBackground);
  margin:1rem 0 0 0;
  border-radius: 10px;
  padding:10px;
}

.filterCatalogue {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}

.mobilePagination{
  display: none;
  margin:1rem 0;
}

.mobileProduct{
  display: none;
}

.pillFilter{
  color:var(--primaryColor);
  border-radius: 10px;
  padding:0.2rem 1rem;
  background: var(--primaryBackground);
  width: 100%;
  p{
    margin:0;
  }
}

.filterMobile{
  display: none;
  gap: 0.5rem;
  height: 100%;
  margin:0.5rem 0;
  button{
    height: auto;
  }
}

//////////////////////// RESPONSIVE ////////////////////////


@media (max-width: 767px) {

  .filterMobile{
    display: flex;
  }

  .mobileProduct{
    display: block;
  }

  .mobilePagination{
    display: block;
  }

  .table{
    display: none;
  }

  .filterCatalogue {

    > *:nth-child(2){
      width: 76%;
    }

    gap:0.45rem;
    > button{
      padding: 0 1rem !important;
    }

    > *:nth-child(1) {
      display: none;
    }
    > *:nth-child(3) {
      display: none;
    }
  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .headerCatalogue{
    background: var(--secondaryBackground);
  }
}
