.color {
  color: #73bdb3;
}

.searchRol {
  padding:1rem 0 0 0;
  display: flex;
  align-items: flex-end;
}

.searchRol > div:nth-child(1) {
  margin: 0 10px 0 0;
  width: 30%;
}

.rolTable {
  background: var(--inputBackground);
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  .row,
  .header {
    display: grid;
    grid-template-columns: 10% 85%;
    justify-content: space-between;
  }

  label {
    p {
      padding: 15px 5px;
      margin: 0;
    }
    &:hover {
      background-color: var(--secondaryBackground);
    }
  }

  input:checked + label {
    background-color: var(--selectedRow);
  }

  .header {
    font-weight: bold;
    text-align: center;
  }

  .row {
    font-weight: normal !important;
    p:nth-child(1) {
      text-align: center;
      text-decoration: underline;
    }
  }
  .rolBody {
    height: 40vh;
    overflow: auto;
    overflow-x: hidden;
  }
}

.rolSupervised > li {
  list-style-type: " - ";
}

.rolMobile {
  display: none;
}

.containerRols{
  height: 100%;
  overflow: auto;
}

.headerRols{
  position: sticky;
  top: 0;
  background: white;
}

.contentRols{
  margin:0 5rem;
}

.containerSteps{
  display: block;
}

.mobileNavigation{
  display: none;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {

  .mobileNavigation{
    margin:1rem 0;
    display: block;
    padding:0 0 1rem 0;
    border:1px solid var(--primaryColor);
    border-style: none none solid none;
  }
  
.containerSteps{
  display: none;
}

  .rolBody {
    height: 100% !important;
    
  }

  .contentRols{
    margin:0 1rem;
  }

  .rolTable{
    padding: 0;
    margin:0 0 1rem 0;
  }

  .headerRols{
    padding:0 0 1rem 0;
  }

  .spacingOptions{
    margin: 0 !important;
    height: 0;
     > button{
      display: none;
     }
  }

  

  .rolMobile {
    display: block;
    background: var(--primaryBackground);
    padding: 10px;
    border-radius: 10px;
    margin: 0 0 1rem 0;
  }

  .searchRol{
    justify-content: space-between;
    > div:nth-child(1){
      width: 80% !important;
    }
  }

  .rolTable {
    background: transparent;
    box-shadow: none;
    .row,
    .header {
      display: none;
    }
  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .headerRols{
    background: var(--secondaryBackground);
  }
}
