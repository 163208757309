.header {
  font-weight: bold;
  display: grid;
  grid-template-columns: 5% 25% 14% 14% 14% 14% 14%;
  text-align: center;

  span:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.headerAmount {
  span {
    display: inline-block;
  }

  span {
    width: 50%;
  }

  span:nth-child(1) {
    width: 100%;
  }
}

.row {
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  font-weight: normal;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 5% 25% 14% 14% 14% 14% 14%;
  span {
    padding: 0 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  span:nth-child(1),
  span:nth-child(3),
  span:nth-child(4) {
    justify-content: center;
  }

  span:nth-child(6) {
    justify-content: flex-end;
  }
}

.amountRow {
  span {
    display: inline-block;
    width: 50%;
    text-align: right;
  }
}
