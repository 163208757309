.row,
.header {
  display: grid;
  // grid-template-columns: repeat(9, calc(100% / 9));
  grid-template-columns: 5% 5% 10% 30% 10% 10% 10% 10% 10%;

  > * {
    margin: 0;
  }
}

.topHeader{
  display: grid;
  grid-template-columns: 50% 20% 10%;
  text-align: center;
  font-weight: bold;
  align-items: center;
}

.row {

  border:1px solid var(--primaryColor);

  border-style: none none solid none;

  > *:nth-child(n + 8) {
    text-align: right;
    &:after {
      content: " %";
    }
  }

  > *:nth-child(n + 4) {
    justify-content: flex-end;
  }

  > * {
    display: flex;
    align-items: center;
  }

  padding: 7.55px 0;
  transition: background 0.2s ease;
  &:hover {
    background: var(--cardBackground);
  }
}

.header {
  font-weight: bold;
  text-align: center;
}

.table {
  background: var(--primaryBackground);
  padding: 10px;
  border-radius: 5px;
  height: 55vh;
  overflow: auto;
  margin: 0 20px;
}

.bgSuccess {
  p {
    color: rgb(0, 85, 0) !important;
    font-weight: bold;
  }
}

.bgWarn {
  p {
    color: rgb(145, 118, 0) !important;
    font-weight: bold;
  }
}

.bgDanger {
  p {
    color: rgb(148, 0, 0) !important;
    font-weight: bold;
  }
}



@media only screen and (max-width: 1023px) {
.row,
.header,
.topHeader{
  width: 1200px;
}
}


@media (prefers-color-scheme: dark) {
  .bgSuccess {
    p {
      color: var(--green) !important;
    }
  }
  
  .bgWarn {
    p {
      color: var(--yellow) !important;
    }
  }

  .bgDanger {
    p {
      color: var(--red) !important;
      
    }
  }
}