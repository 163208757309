.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border: 1px solid var(--primaryColor);
  border-style: none none solid none;
}

.action{
  display: flex;
  flex-direction: column;
  gap:10px 0;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem 1.5rem;
  border: 1px solid var(--primaryColor);
  border-style: solid none none none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem 0;
  margin:1rem 2rem;
}

.fields{
  display: flex;
  flex-direction: column;
  gap:10px 0;
}