.containerDocNumber {
  display: flex;
  flex-direction: column;
  gap: 5px 0;
  width: 23%;
  p{
    margin:0;
  }

  * > :nth-child(n + 1) {
    font-weight: normal !important;
    margin: 0;
  }
}
