.container {
  * {
    margin: 0;
  }
}

.informative {
  display: flex;
  margin: 0 0 5px 0;
  p:first-child {
    margin: 0 8px 0 0;
    font-weight: bold;
    &::after {
      content: ": ";
    }
  }
  p:last-child {
    margin: 0;
  }
}

.divisor {
  border-top: 1px solid hsl(0, 0%, 69%);
  margin: 30px 0 0 0;
  padding: 10px 0;
}
