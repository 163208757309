.container {
  padding: 10px;
  overflow: auto;
  // * {
  //   color: var(--primaryColor);
  // }
  * {
    color: var(--primaryColor);
  }
  border-radius: 10px;
  margin: 10px;
}


.tutorialGuideStep{
  display: grid;
  grid-template-columns: repeat(10,10%);
  grid-template-rows: repeat(10,10%);
  height: 500px;
  img{
    grid-column: 1/11;
    grid-row: 1/10;
    width: 100%;
    height: 100%;
  }

  button{
    background: rgb(12, 12, 12);
    border:none;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      height: 80%;
    }
  }

  button:disabled{
    cursor: not-allowed;
  }

  button:hover{
    svg{
      fill: var(--toastify-color-info);
    }
  }

  button:first-of-type{
    grid-column: 1/6;
    grid-row:10/11;
  }

  button:last-child{
    grid-column: 6/11;
    grid-row:10/11;
  }
}

@media (prefers-color-scheme: light) {
  .container {
    * {
      color: white;
    }
  }

}