.form {
  display: grid;
  grid-template-columns: 16% 16% 16% 16% 16% 14%;
  justify-content: space-between;
  align-items: flex-end;
}

.containerCards {
  max-height: 50vh;
  overflow: auto;
}
