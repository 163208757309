.filter {
  display: flex;
  gap: 0 1rem;
  align-items: flex-end;
  margin: 0 5rem;

  > *:nth-child(1) {
    width: 15%;
  }

  > *:nth-child(2) {
    width: 25%;
  }

  > *:nth-child(3) {
    width: 10%;
  }

  > *:nth-child(4) {
    width: 15%;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .filter {
    margin: 0 1rem;

    > *:nth-child(-n + 7) {
      display: none !important;
    }

    > *:nth-child(4) {
      display: block !important;
      width: 100% !important;
    }

    > button {
      padding: 0 1rem !important;
    }
  }
}
