.containerTable {
  max-height: 50vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  font-weight: normal;
  margin: 0 0 0.3125rem 0;
  padding: 0.1563rem 0;
  &:hover {
    background-color: rgb(238, 238, 238);
  }
}

.headerTable {
  font-weight: bold;
  display: flex;
  justify-items: center;
  position: sticky;
  top: 0;
  padding: 10px 0;
}
