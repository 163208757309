.informativePipe {
  span:first-child {
    font-weight: bold;
    &::after {
      content: ":";
    }
    margin: 0 5px 0 0;
  }
  span:last-child {
    &::after {
      content: " | ";
    }
    margin: 0 5px 0 0;
  }
}
