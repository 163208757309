.containersaldo{
    display: inline-flex;
    margin: 15px 0;
    width: 100%;

    justify-content: space-between;
}

.containersaldo > div{
    width: 32%;
}

.containersaldo p{
    margin:0;
}