.containerTable {
  max-height: 50vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: var(--primaryBackground);
  border-radius: 1.06em;
  padding: 15px;
  
  margin: 5px;
  &:last-child {
    position: sticky;
    bottom: 0;
  }
}

.containerFilesAttached {
  display: flex;
  max-width: 100%;
  overflow-y: auto;
  gap:1rem;
  padding:0 0 1rem 0;
}

.row {
  display: flex;
  align-items: center;
  font-weight: normal;
  margin: 0 0 0.3125rem 0;
  padding: 10px 0;
  &:hover {
    background-color: var(--secondaryBackground)
  }
}

.headerTable {
  font-weight: bold;
  display: flex;
  color:var(--primaryColor);
  justify-items: center;
  position: sticky;
  top: 0;
   background-color: var(--primaryBackground)
}
