.creation {
  &::before {
    content: "-";
    margin: 0 10px;
  }
}

.containerTickets {
  margin: 10px;
  .ticket {
    border: 1px solid gray;
    border-style: none none solid none;
    margin: 0 0 10px 0;
    &:last-child {
      border: none;
    }
  }
}
