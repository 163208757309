.PositionForm{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
}
.LefttSideModal{
    grid-column-start: 1;
    grid-row-start: 1;
}
.RightSideModal{
    grid-column-start: 2;
}