.password {
  input {
    border-style: solid none solid solid;
    border-radius: 5px 0 0 5px !important;
  }
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
}

.inputPassword {
  display: flex;
  * {
    height: 3.125em;
    background-color: #f9f9f9;

    border: 0.1875em solid #e5e5e5;
  }
  button {
    border-style: solid solid solid none;
    border-radius: 0 0.1875em 0.1875em 0;
    transition: 0.2s ease;
    &:hover {
      background-color: #e5e5e5;
    }
  }
}
