.containerMenu {
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background: var(--primaryBackground);
  border-radius: 1.06em;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 30px 0 0 0;
  text-align: left;
  width: 20vw;
  cursor: default;
  a.item {
    text-decoration: none;
    color: var(--primaryColor);
  }
  p.item {
    color:var(--primaryColor);
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background-color: hsla(0, 0%, 50%, 0.316);
    }
  }
}
