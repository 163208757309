.moreInfoTag{
    display: flex;
    flex-direction: column;
    gap:10px 0;
    justify-content: flex-start;
    align-items: center;
    min-height: 500px;
    > div{
        width: 100%;
    }
}

