.containerModal {
  * {
    font-size: 15px;
  }
}

.personalInformation {
  display: grid;
  grid-template-columns: repeat(3, 31.5%);
  justify-content: space-between;
  .form-control {
    width: 100%;
  }
}

.profile {
  display: grid;
  grid-template-columns: 65% 35%;
  grid-template-areas:
    "profilePic info"
    "config info";
}

.nameContainer {
  grid-area: info;
}

.configuration {
  grid-area: config;
  display: flex;
  align-items: center;
  flex-direction: column;
}
