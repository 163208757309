.button {
  height: 50px;
  // width: 150px;
  padding: 0 20px;
  outline: none;
  border: 3px solid black;
  font-weight: bold;
  border-radius: 10px;
  // padding: 15px 36px;
  // padding: 10px, 30px;
  transition: ease-out 0.3s;
  outline: none;
  background-color: black;
  color: white;
  &:hover {
    background-color: white;
    color: black;
  }
}

.fitBtn {
  width: auto !important;
}

.header-menu-link {
  color: #000;
  font-family: $font-generl;
  font-size: $header-font-size;
  &:hover {
    background: #000;
    color: $white2;
  }
  .dropdown:hover .hoveringLink {
    background-color: #191919;
    color: #f9f9f9;
  }
}

.menuItemButton {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  color: #000;
  &:hover {
    text-decoration: underline;
  }
}

.dismissModalButton {
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  padding: 15px 36px;
  transition: ease-out 0.3s;
  outline: none;
  background-color: #ffffff;
  &:hover {
    background-color: #c30000;
    color: #fff;
  }
}
