.body_helpdesk{
    height: 60vh;
}
.inputJAM{
    margin-top: 50px;
}
.divfooter{
    display: flex;
    justify-content: end;
}
.InpText{
    margin-top: 5px;
}
.BtnAddTicket{
    background-color: black;
    color:whitesmoke;
    border-radius: 10px;
    border: none;
    padding: 10px 15px 10px 15px;
    box-shadow: 0px 0px 2px black;
    transition: 1s;
}

.BtnAddTicket:hover{
    background-color:white;
    color:black;
}

.addFiles{
    padding: 5px 0 5px 0;
}