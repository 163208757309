.filter {
  display: flex;
  gap: 0 1rem;
  align-items: flex-end;
  padding: 1rem;
  > *:nth-child(1) {
    width: 40%;
  }
}

.row,
.header {
  display: grid;
  grid-template-columns: 5% 10% 10% 10% 35% 10% 10% 10%;
}

.header {
  text-align: center;
  font-weight: bold !important;
}

.tableAdvertisements {
  margin: 1rem;
  background: var(--inputBackground);
  border-radius: 10px;
  padding: 1rem;
  p {
    margin: 0;
    font-weight: normal;
  }
  label {
    > p {
      font-weight: normal !important;
      display: flex;
      align-items: center;
      padding: 1rem 0;
    }
  }
}

.optionsAdvertisements {
  height: 3.125rem;
  margin: 0 1rem;
}

.containerRows {
  height: 40vh;
  overflow: auto;

  input:checked + label {
    background: var(--selectedRow);
  }
}

.abcAdvertisement {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  > div:nth-child(4) {
    grid-column: 1/4;
  }
}

.footerAbc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobileItem {
  display: none;
}

.headerAdvertisements {
  position: sticky;
  top: 0;
  padding: 0 0 1rem 0;
  background: white;
}

.row {
  transition: all 0.25s ease;
  &:hover {
    background: var(--secondaryBackground);
  }

  > p {
    overflow: hidden; /* Hide overflowing text */
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    text-overflow: ellipsis;
  }

  > div:nth-child(1) {
    display: flex;
    justify-content: center;
    font-weight: normal;
    align-items: center;
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }
}

.containerAdvertisements {
  height: 100%;
  overflow: auto;
}

.headerAdvertisements {
  padding: 0;
}

@media (max-width: 767px) {
  .filter {
    justify-content: space-between;
    > *:nth-child(1) {
      width: 85%;
    }
  }

  .optionsAdvertisements {
    display: none;
    height: 0;
  }

  .mobileItem {
    display: block;
    background: var(--primaryBackground);
    margin: 0 0 1rem 0;
    border-radius: 10px;
    padding: 1rem;
  }

  .tableAdvertisements {
    background: transparent;
    padding: 0;
  }

  .row,
  .header {
    display: none;
  }

  .containerRows {
    height: 100%;
  }

  .headerAdvertisements {
    padding: 0 0 1rem 0;
  }

  .abcAdvertisement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem 0;

    > * {
      width: 100%;
    }
  }
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .headerAdvertisements {
    background: var(--secondaryBackground);
  }
}
