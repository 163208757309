.container {
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  > *:nth-child(1) {
    max-height: 19%;
  }

  > *:nth-child(2) {
    max-height: 75%;
    height: 100%;
  }
}

.header {
  position: sticky;
  top: 0;
  background: white;

  > *:nth-child(n + 2) {
    margin: 0 5rem;
  }
}

.body {
  overflow: auto;
}

.margin {
  margin: 0 5rem;
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .header {
    background: var(--secondaryBackground);
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .margin {
    margin: 0 1rem;
  }

  .container {
    > * {
      max-height: 100% !important;
      height: 100% !important;
    }
  }
}
