.row,
.totals,
.header {
  display: grid;
  grid-template-columns: 15% 10% 10% 10% 10%;
  justify-content: space-between;
}

.header {
  font-weight: bold;
  text-align: center;
}

.contentRow {
  height: 40vh;
  overflow: auto;
}

.tableBankResidues {
  margin:0 20rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 10px 10px 0 10px;
  p {
    white-space: nowrap; /* Prevent line breaks */
    overflow: hidden; /* Hide any overflow */
    text-overflow: ellipsis;
  }
}

.marginModule{
  padding:0 20rem;
}

.totals {
  border: 1px solid rgb(231, 231, 231);
  border-style: solid none none none;
  padding: 10px 0;
}

.mobileItem {
  background: var(--primaryBackground);
  color: var(--primaryColor);
  display: none;
  padding: 1rem;
  border-radius: 10px;
  gap:1rem 0;
}

.mobileItemAmounts {
  display: flex;
  gap: 0 1rem;
}

.topModule{
  position: sticky;
  top:0;
  padding:0 0 1rem 0;
  background: white;
  display: flex;
  flex-direction: column;
  gap:1rem 0;
}

.containerModule {
  height: 100%;
  overflow: auto;
}

////////////////////////// [RESPONSIVE] //////////////////////////

@media (max-width: 767px) {
  .mobileItem {
    display: flex;
    flex-direction: column;
    margin: 0 0 1rem 0;
  }

  .totals {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    p {
      margin: 0;
    }

    > p:nth-child(3),
    > p:nth-child(1),
    > p:nth-child(2) {
      display: none;
    }

    > p:nth-child(5) {
      &::after {
        content: " USD";
      }
    }

    > p:nth-child(4) {
      &::after {
        content: " MXN";
      }
    }
  }

  .tableBankResidues {
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin:0 2rem;
  }

  .contentRow {
    height: 100%;
  }

  .marginModule{
    padding:0 2rem;
  }

  .row,
  .header {
    display: none;
  }
}

////////////////////// MODO OSCURO //////////////////////

@media (prefers-color-scheme: dark) {
  .topModule{
    background: var(--secondaryBackground);
  }
}