.label {
  display: flex;
  align-items: center;
  color: var(--primaryColor);
  span:nth-child(2) {
    margin: 0 0 0 0.25rem;
    &:after {
      content: "/";
    }
    &:before {
      content: "(";
    }
  }
  span:nth-child(3) {
    &:after {
      content: ")";
    }
  }
}
