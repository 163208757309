.containerCsd{
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    padding:30px;
    border-radius: 20px;
    margin:15px;
    color:var(--primaryColor);
}

.containerFile{
    display: inline-flex;
}

.fileCsd{
    display: flex;
    flex-direction: column;
    margin:0 10px 0 0;
    justify-content: center;
    align-items: center;
    button{
        width: 100%;
        margin:10px 0 0 0;
    }
}

.wrap{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
}


@media (max-width: 767px) {
    .containerCsd{
        margin: 0;
        padding: 0;
    }

    .containerFile,.fileCsd{
        display: block;
        text-align: center;
        
    }

    .containerFile{
        padding:1rem 0;
        border:1px solid var(--primaryColor);
        border-style: none none solid none;
    }
  }
  