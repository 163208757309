@import '../../../styles/Variables.scss';

.queueContainer{
    min-height: 500px;
    max-height: 500px;
    overflow: auto;
}

.addMov{
    height: 3.125em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    border-radius: 5px;
    cursor: pointer;
    border:0.1875em solid $border-input-color;
}

.error{
    border:1px solid var(--danger);
    color:var(--danger)
}