.chromeInstallation {
  > *:nth-child(1) {
    display: none;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .chromeInstallation {
    > *:nth-child(1) {
      display: block;
    }

    > *:nth-child(2) {
      display: none;
    }
  }
}
