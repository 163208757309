.container {
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .container {
    height: 0 !important;
    display: none !important;
  }
}
