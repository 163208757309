.containerMobileBankCards {
  > * {
    margin: 0 0 1.5rem 0;
  }

  > *:last-child {
    margin: 0;
  }

  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}
