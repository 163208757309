.containerHeader {
  display: flex;
  gap: 20px 0;
}

.overviewHeader {
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    // color: var(--primaryColor);
  }
  // gap: 5px 0;
}

.linkCustomer {
  color: var(--linkColor);
  text-decoration: underline;
  
  &:hover{
    cursor: pointer;
    font-weight: bold;
  }
}
