.emailExtensions {
  display: flex;
  align-items: center;
  height: 100%;
  b {
    &::after {
      content: ":";
      margin: 0 5px 0 0;
    }
  }

  p{
    margin:0;
  }

  span {
    margin: 0 5px 0 0;
    &::after {
      content: ",";
    }
  }

  span:last-of-type {
    &::after {
      content: ".";
    }
  }
}
