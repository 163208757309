.containerContact {
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 1.06em;
  padding: 1em;
  margin: 0.9375rem;
  display: flex;
  height: 25vh;

  p {
    display: flex;
    flex-direction: column;
    margin: 0 0 0.3125rem 0;
  }
}

.containerContactGlobal {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  justify-content: space-around;
}

.contactMedia {
  display: flex;
  flex-wrap: wrap;
  p {
    margin: 0 10px 0 0;
  }
}
