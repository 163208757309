.container {
  margin: 20px 5% 0 5%;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.header {
  display: flex;
  justify-content: space-between;
}

.overviewHeader {
  display: flex;
  gap: 0 20px;
}

.optionsRemition {
  display: flex;
  gap: 0 20px;
}
