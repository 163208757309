.filterConsecutivePurchaseOrder {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  > div:nth-child(4) {
    min-width: 20%;
  }
}

.container {
  margin: 0 20px;
}

.header {

  align-items: center;
  position: sticky;
  top:0;
  background: var(--primaryBackground);
  font-weight: bold;
  text-align: center;
  > div:last-child {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.row,
.header {
  display: grid;
  padding: 10px;
  grid-template-columns: 5.33% 8.33% 8.33% 8.33% 8.33% 8.33% 22% 22%;
  justify-content: space-between;
}

.containerRows {
  // height: 45vh;
  // overflow: auto;
}

.row {
  text-align: center;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.row {
  border: 1px solid rgb(243, 243, 243);
  border-style: none none solid none;
  > p:nth-child(1),
  > p:nth-child(6),
  > p:nth-child(5) {
    text-align: right;
  }

  > p {
    margin: 0;
    padding: 0.35rem 0;
  }

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }

  > p:nth-child(7),
  > p:last-child,
  p:nth-child(2),
  p:nth-child(4),
  p:nth-child(3) {
    text-align: left;
  }
}

.headerAmount {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  font-weight: bold;
  text-align: center;
  > p:nth-child(1) {
    grid-column: 1/5;
  }
}

.tableConsecutivePurchaseOrder {
  background: var(--inputBackground);
  color: var(--primaryColor);
  border-radius: 10px;

  height: 50vh;
  overflow: auto;
}

.headerContent {
  position: sticky;
  z-index: 0;
  top:0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  padding: 0 0 1rem 0;
}

.contentItem {
  margin: 0 5rem;
}

.contentModule {
  height: 100%;
  overflow: auto;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .contentItem {
    margin: 0 1rem;
  }

  .containerRows {
    height: 100%;
  }

  .tableConsecutivePurchaseOrder {
    width: 1750px;
    height: 40vh;
  }

  .filterConsecutivePurchaseOrder {
    > *:nth-child(-n + 3) {
      display: none;
    }

    > div:nth-child(4) {
      width: 100%;
    }

    > button {
      padding: 0 1rem;
    }
  }
}


/////////////////////////////////////////////// MODO OSCURO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .headerContent{
    background: var(--secondaryBackground);
  }
}