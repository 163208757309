.menuContent {
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 10dvh 80dvh 10dvh;
  grid-template-areas:
    "topMenu"
    "contentMenu"
    "footerMenu";

  > div:nth-child(1) {
    grid-area: topMenu;
  }

  > div:nth-child(2) {
    grid-area: contentMenu;
    background: var(--secondaryBackground);
    padding: 0 20px;
  }

  > footer {
    grid-area: footerMenu;
  }
}

.containerMenuContent{
  height: 100%;
  overflow: auto;
}