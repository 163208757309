.table {
  padding: 10px;
  background: var(--primaryBackground);
  border-radius: 10px;
}

.header,
.row {
  display: grid;
  grid-template-columns: 8% 32% 8% 8% 8% 8% 10% 8% 8%;
  justify-content: space-between;
}

.contentRows {
  height: 35vh;
  overflow: auto;

  input:checked + label {
    background: var(--secondaryBackground);
  }
}

.header {
  font-weight: bold;
  text-align: center;
}

.moreInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-decoration: underline;
}

.row {
  > *:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      font-weight: bold;
    }
  }

  transition: all 0.25s ease;
  font-weight: normal;
  border: 1px solid var(--primaryColor);
  border-style: none none solid none;
  > p {
    margin: 0;
    padding: 0.75rem 0;
  }
  &:hover {
    background: var(--secondaryBackground);
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {

  .loading{
    display: none;
  }

  .row,.header {
    display: none;
  }

  .table{
    background: transparent;
  }

  .contentRows{
    height: 100%;
  }
}
