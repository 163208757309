.formTc {
  display: flex;
  gap: 0.625rem;
  .dateInput {
    display: flex;
    flex-direction: column;
  }
  align-items: flex-end;
}

.formTc,
.table {
  color: var(--primaryColor);
}

.table {
  margin: 10px 0;
  width: 100%;
  table-layout: fixed;
  thead {
    tr {
      th {
        text-transform: uppercase;
      }
    }
  }
  tbody {
    tr {
      border: 1px solid rgb(221, 221, 221);

      border-style: none none solid none;
      td:nth-child(1) {
        text-align: center;
      }
      td {
        text-align: center;
      }
      td {
        padding: 10px 0;
      }
    }

    tr:last-child {
      border: none;
    }
  }
}

.tcMobile {
  display: none;
  padding: 0.5rem;
  border-radius: 10px;
}

.ammounts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap:0 2rem;
}

.tcCreatedBy{
  span{
    margin:0 0.5rem 0 0;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .formTc {
    flex-wrap: wrap;
    justify-content: space-around;
    > * {
      width: 48%;
    }
  }

  .tcMobile {
    display: block;
    margin: 1rem 0 0 0;
    background: var(--primaryBackground);
  }

  .table {
    display: none;
  }
}
