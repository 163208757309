.row,
.header {
  display: grid;
  grid-template-columns: repeat(6, calc(100% / 6));
  > p{
    margin:0;
  }
  padding: 10px 0;
}

.header {
  font-weight: bold;
  text-align: center;
  position: sticky;
  top: 0;
  background: var(--inputBackground);
  padding: 10px 0;
}

.row {
  font-weight: normal;

  &:hover {
    background: var(--secondaryBackground);
  }
}

.table {
  height: 50vh;
  overflow: auto;
  background: var(--inputBackground);
  border-radius: 10px;
  padding: 0 20px;

  > div:last-child{
    position: sticky;
    bottom:0;
    padding: 10px 0;
  }
  margin:20px 0 0 0;
}
