.row,
.header {
  display: grid;
  grid-template-columns: 45% 15.5% 15.5% 20.5%;
  justify-content: space-between;
  margin:0 10px !important;
}

.header {
  font-weight: bold;
  top:0;
  position: sticky;
  text-align: center;
  background-color: var(--primaryBackground);
  p{
    justify-content: center;
  }
}

.row{
  margin:0 0 10px 0;
}

.containerConceptEgresses{
  overflow: auto;
  p{
    margin:0;
    display: flex;
    align-items: center;
  }
}

.containerConceptEgresses > * {
  padding:7.5px 0;
}