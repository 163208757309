.item {
  display: flex;
  flex-direction: column;
  background: var(--primaryBackground);
  color: var(--primaryColor);
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  justify-content: center;
}

.container {
  display: none;
  gap: 0 1rem;
  
  > *{
    height: 3.325rem !important;
    padding: 0rem 1rem !important;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .container {
    margin:1rem 0 0 0;
    display: flex;
  }
}
