.rstm-tree-item-group {
  background: var(--primaryBackground);
  padding: 1rem !important;
  height: 100%;
  border-radius: 10px;
  * {
    color: var(--primaryColor) !important;
  }
  border-top: none !important;

  .rstm-tree-item:last-of-type {
    border: none !important;
  }
}

.rstm-tree-item {
  color: var(--primaryColor);
}

.rstm-tree-item--active {
  background: var(--secondaryBackground) !important;
}
