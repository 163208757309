.container {
  height: 3.125rem;
  min-height: 3.125rem;
  display: flex;
  gap: 0 1rem;
  margin: 0 0 1rem 0;
}

@media (max-width: 767px) {
  .container {
    display: none;
    height: 0;
    min-height: 0;
  }
}
