.steps {
  input {
    display: none;
  }
  display: grid;
  --sizeStep: 24px;
  --black: #191919;

  --hover: #ececec;

  input:checked + label {
    background: var(--hover);
    .overviewStep p {
      background: var(--hover);
    }
  }
}

.stepContainer {
  cursor: pointer;
  display: grid;
  grid-template-columns: var(--sizeStep) 1fr;
  border-radius: 5px;
  align-items: center;
  padding: 3.5px 0;
  &:hover {
    background: var(--hover);
    .overviewStep p {
      background: var(--hover);
    }
  }
}

.step {
  border-radius: 99999999999px;
  width: var(--sizeStep);
  height: var(--sizeStep);
  background: var(--black);
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
}

.overviewStep {
  display: grid;
  grid-template-columns: repeat(10, 10%);
  grid-template-rows: repeat(5, 20%);

  p {
    grid-column: 2/10;
    grid-row: 1/6;
    text-align: center;
    margin: 0;
    z-index: 1;
    background: white;
  }

  div {
    grid-row: 3/4;
    grid-column: 1/11;
    display: flex;
    align-items: center;

    div {
      height: 0;
      width: 100%;
      border: 1px solid var(--black);
    }
  }
}

.mobileStatus {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .steps {
    --hover: #474747;
  }

  .overviewStep {
    p {
      background: var(--secondaryBackground);
    }
  }
}

@media only screen and (max-width: 768px) {
  .mobileStatus {
    display: block;
  }

  .steps{
    display: none;
  }
}
