.pictureContainer {
  border: 1px solid gray;
  display: flex;
  justify-content: center;
}

.previewPicture,
.updateProfilePicture {
  display: grid;
  grid-template-columns: repeat(4, 75px);
  grid-template-rows: repeat(4, 75px);
  justify-content: center;
  canvas {
    grid-row: 1/5;
    grid-column: 1/5;
  }
}

.editProfilePicture {
  grid-row: 4/5;
  grid-column: 4/5;
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.updateProfilePicture {
  grid-template-columns: repeat(4, 87.5px);
  grid-template-rows: repeat(4, 87.5px);
}

.scale {
  grid-row: 5/6;
  grid-column: 1/3;
  margin: 10px 0;
}

.buttonsEditionPicture {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  grid-row: 5/6;
  grid-column: 3/5;
}

.buttonEdition {
  height: 35px;
  width: 35px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    background-color: rgba(128, 128, 128, 0.111);
  }
}
