.headerContainer {
  // background: var(--primaryBackground) !important;
  background: var(--secondaryBackground);
  justify-content: center;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 5px;
  z-index: 50;
  align-items: center;
  color: var(--primaryColor);
}

.containerLogo {
  max-width: 25%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  box-sizing: border-box;
  img,source{
    height: 100%;;
  }
}

.containerBanner {
  max-width: 60%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  * {
    height: 100%;
    width: 100%;
  }
}

.menu > p {
  font-size: 0.75rem;
}

.menu {
  box-sizing: border-box;
  margin: 0 10px;
  display: flex;
  justify-content: end;
  // * {
  //   font-size: 0.75rem;
  // }
  align-items: center;
  p {
    margin: 0;
  }
  max-height: 40px;
  max-width: 25%;
}

.quickOptions {
  display: flex;
}

.quickOptions > button {
  font-size: 0.75rem;
}

.quickOptions {
  button {
    * {
      font-size: 1rem;
    }
  }
}

.noProfilePic {
  color: gray;
  margin: 0 5px 0 0;
  font-size: 2rem;
}

.icon {
  color: gray;
  cursor: pointer;
  border: 1px solid red;
  border-radius: 50%;
  width: 25px !important;
  padding: 2.5px;
  height: 25px !important;
  font-size: 1;
}

.containerProfilePicture {
  // z-index: -1000;
  display: flex;
  align-items: center;
  transition: background 0.25s ease;
  border-radius: 0.25rem;
  padding: 0.25rem;
  &:hover {
    background: var(--cardBackground);
  }
}

.mobileMenu {
  display: none;
  align-items: center;
  grid-template-columns: 20% 50% 20%;
  justify-content: space-around;
  grid-template-rows: 100%;
  img {
    width: 100%;
  }
}

.menuMobileIcon {
  background: var(--cardBackground);
  max-height: 75%;
  border-radius: 10px;
  margin: 0.75rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    padding: 0.5rem;
    color: var(--primaryColor);
  }
}

.menuMobileNavigation {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;

  > *:last-child {
    margin: 0 0 1rem 0;
  }
}

//////////////////////// MOBILE ////////////////////////

@media (max-width: 767px) {
  .mobileMenu {
    display: grid;
  }

  .headerContainer {
    display: none;
  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .mobileMenu {
    color: var(--primaryColor);
    background: var(--primaryBackground);
  }
}

//////////////////////// LIGHT MODE ////////////////////////

@media (prefers-color-scheme: light) {
  .headerContainer {
    background: transparent;
  }
}
