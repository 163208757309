.pillsContainer {
  display: none;
  p {
    margin: 0;
  }

  > * {
    background: var(--primaryBackground);
    padding: 1rem;
    border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .pillsContainer {
    display: flex;
    gap: 0 1rem;
  }
}
