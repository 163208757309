.header,
.row {
  display: grid;
  grid-template-columns: 5% 15% 10% 20% 20% 15% 10%;
  justify-content: space-between;
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin: 0;
  }
  padding: 10px 0;
}

.containerRows {
  height: 40vh;
  overflow: auto;
}

.header {
  text-align: center;
}

.header {
  font-weight: bold;
}

.table {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: var(--inputBackground);
  padding: 20px;
  border-radius: 10px;
  transition: all 0.25s ease;

  label {
    &:hover {
      background-color: var(--secondaryBackground);
    }
  }
  input:checked + label {
    background-color: var(--selectedRow);
  }
}

.row {
  font-weight: normal !important;

  & p:nth-child(1) {
    text-decoration: underline;
    text-align: center;
  }
}

.filterUsers {
  display: flex;
  gap: 0 1rem;
  align-items: flex-end;

  > *:nth-child(1) {
    width: 40%;
  }
}

.containerUserCards {
  .cardUser:first-of-type {
    margin: 1rem 0;
  }
  overflow: auto;
  display: none;
}

.cardUser {
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 1rem;
  margin: 0 0 1rem 0;
}

.headerUsers{
  position: sticky;
  top:0;
  background: white;
  
}

.usersContainer {
  height: 100%;
  padding:0 2rem;
  overflow: auto;
}

.usersOptions{
  display: flex;
  min-height: 3.125rem;
  gap:1rem;
  margin:1rem 0;
}

////////////////////////////////////////////////////////////////////////

@media (max-width: 767px) {
  .header,
  .containerRows {
    display: none;
  }

  .usersOptions{
    margin:0;
    min-height: 0;
    > div{
      > button{
        display: none;
      }
    }
  }

  .table{
    background: transparent;
    box-shadow: none;
    padding:0;
    margin:0 0 1rem 0;
  }

  .containerUserCards {
    display: block;
  }

  .headerUsers{
    padding:0 0 1rem 0;
  }

  .filterUsers {
    justify-content: space-between;

    > *:nth-child(1) {
      width: 80%;
    }
  }
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .headerUsers{
    position: sticky;
    top:0;
    background: var(--secondaryBackground);
  }
}
