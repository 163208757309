.quill {
  background: var(--cardBackground);
  * {
    border-color: var(--primaryColor) !important;
    color: var(--primaryColor) !important;

    border: none !important;
  }

  > pre {
    height: 50px;
  }

  border:1px solid #b7b7b7;
}

.mobileInput{
  display: none;
}

.boxComment {
  display: grid;
  grid-template-columns: 88% 10%;
  justify-content: space-between;

  svg{
    display: block !important;
  }

  button{
    height: 100% !important;
  }
}

.commentOptions{
  display: flex;
  gap:10px 0;
  flex-direction: column;
  justify-content: space-between;
  button{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
}

@media only screen and (max-width: 1023px) {
  .mobileInput{
    display: block;
  }

  .quill{
    display: none;
  }

  .boxComment{
    display: grid;
    grid-template-columns: 65% 30%;
    justify-content: space-between;
  }

  .commentOptions{
    flex-direction: row;
    button{
      padding:0 1rem;
    }
  }
}
