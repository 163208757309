.container {
  border: 1px solid rgb(233, 233, 233);
  border-style: none none solid none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: var(--primaryColor);
}

.gridItem {
  display: flex;
  gap: 0 1rem;
  align-items: flex-start;
}

.overviewGrid {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .gridItem {
    flex-direction: column;
    gap: 1rem 0;

    > * {
      width: 100% !important;
    }

    input {
      width: 100% !important;
    }
  }
}
