// https://blog.hubspot.com/website/scrolling-text-css
.banner {

  cursor: pointer;
  width: 100%;
  height: 70%;
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  svg {
    width: 20px;
  }
  span {
    &::after {
      content: " | ";
      margin: 0 10px;
    }
  }

  p {
    width: 100%;
    display: inline-block;
    margin: 0;
    white-space: nowrap;    
    
    color:var(--primaryColor);
    span:last-child {
      &::after {
        content: "";
      }
    }
  }
}

.scrollingBanner{
  display: flex;
  gap:0 10px;
  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

.modalBanner {
  
  svg {
    width: 20px;
  }
  p {
    border: 1px solid rgb(230, 230, 230);
    border-style: none none solid none;
    padding: 15px 0;
    margin: 0;
    color:var(--primaryColor);
  }
  p:last-child {
    border: none;
  }
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
