.container {
  margin: 25px 5% 0 5%;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    gap: 0 20px;
    > div {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
    }
  }
}

.elementsHeader {
  display: flex;
  gap: 0 20px;
}
