.body {
  display: grid;
  grid-template-columns: 85% 13%;
  grid-template-rows: 1fr;
  justify-content: space-between;
  height: 100%;

  > *:nth-child(1) {
    overflow: auto;
  }

  > * {
    background: var(--cardBackground);
  }
}

.containerAddEgressModule {
  height: 100%;
  overflow: auto;
}

.gridAddEgress {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;

  grid-template-rows: 30% 65%;
  gap: 2.5% 0;
}

.amountsAssociation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: var(--cardBackground);
}

.body > div {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 15px;
  border-radius: 15px;
  // margin: 15px 0;
}

.options {
  display: flex;
  gap: 0 1rem;
  align-items: flex-end;

  > *:nth-child(2) {
    width: 15%;
  }

  > *:nth-child(4) {
    width: 17.5%;
  }

  > *:nth-child(1) {
    width: 20%;
  }
}

.containerOptions {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 15px;
  border-radius: 15px;
  margin: 15px 0;
  background: var(--cardBackground);
}

.row {
  background: var(--primaryBackground);
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .containerOptions {
    display: flex;
    flex-direction: column;
  }

  .body{
    display: flex;
    flex-direction: column-reverse;
    gap:1rem 0;
  }

  .options {
    flex-direction: column;
    gap: 1rem 0;

    > div {
      width: 100% !important;
    }
  }

  .gridAddEgress {
    display: flex;
    flex-direction: column;
  }
}
