:root {
  --mainHeaderColor: #d4d4d4;
  --subRowColor: #e1f4ff;
  --subRowHeader: #ffffff;
}

$gray: #d4d4d4;
$subrow: #e1f4ff;
$subrowHeader: #ffffff;

.module {
  height: 100%;
  overflow: auto;
}

.containerTable {
  overflow: auto;
}

.table {
  border-radius: 10px;
  background: var(--primaryBackground);
  overflow: auto;
}

.contentRows {
  height: 40vh;
}

.header {
  > p {
    span:nth-child(2) {
      background-color: var(--subRowHeader);
    }
  }
}

.header,
.subrow,
.row {
  display: grid;
  grid-template-columns: 20% 30% 10% 10% 10% 10% 10%;
  align-items: center;
}

.row,
.subrow {
  border: 1px solid var(--primaryColor);

  border-style: none none solid none;
  background: var(--mainHeaderColor);
  p {
    margin: 0;
    padding: 0.25rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.subrow {
  background: transparent;
}

.header {
  p {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    > span:nth-child(1) {
      background-color: var(--mainHeaderColor);
    }

    span {
      padding: 0.25rem;
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

.containerRows {
  height: 40vh;
  overflow: auto;
}

.filter {
  margin: 10px 0;
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  > div:nth-child(1) {
    width: 30%;
  }
}

.row,
.header,
.subrow {
  > p:last-child {
    background-color: var(--subRowColor);
    span {
      background-color: var(--subRowColor);
    }
  }
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  padding: 0 0 1rem 0;
  position: sticky;
  top: 0;
  background: #ffffff;
}

.content {
  margin: 0 5rem;
}

/////////////////////////////////////////////// RESPONSIVE///////////////////////////////////////////////

@media (max-width: 767px) {
  .table {
    width: 1750px;
  }

  .content {
    margin: 0 1rem;
  }

  .filter {
    > *:nth-child(2),
    > *:nth-child(3) {
      display: none;
    }

    > div:nth-child(1) {
      width: 100%;
    }
  }
}

/////////////////////////////////////////////// OSCURO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  :root {
    --mainHeaderColor: #0b0b0b;
    --subRowColor: #003a5b;
    --subRowHeader: #363636;
  }

  .headerContent{
    background: var(--secondaryBackground);
  }
}
