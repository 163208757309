.elipsisMobile {
  background: var(--cardBackground);
  display: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0.15rem solid var(--cardBackground);
  transition: all 0.25s ease;

  svg {
    pointer-events: none;
    color: var(--primaryColor);
  }
  &:hover {
    border-color: var(--primaryColor);
    background: var(--secondaryBackground);
  }
}

@media (max-width: 767px) {
  .elipsisMobile {
    display: inline-block;
  }
}
