.containerMovements {
  height: 100%;
  overflow: auto;
  padding: 0 1rem;
}

.movementsTop {
  padding: 0 0 1rem 0;
  background: white;
  display: flex;
  flex-direction: column;
  gap:0.5rem 0;
}

.header,
.row {
  display: grid;
  grid-template-columns: 10% 10% 20% 40% 20%;
  width: 100%;
  padding: 15px 0;
  p {
    margin: 0;
  }
}

.header {
  text-align: center;
  font-weight: bold;
  position: sticky;
  top:0;
  background: var(--inputBackground);
}

.row {
  background: var(--inputBackground);
  font-weight: normal;
  transition: 0.25s ease;
  &:hover {
    background-color: var(--secondaryBackground);
  }
}

.moreInfo {
  text-decoration: underline;
}

.row input {
  display: none;
}

.row > :nth-child(1),
.row > :nth-child(2),
.row > :nth-child(3),
.row > :nth-child(4),
.row > :nth-child(5) {
  text-align: center;
}

.row > :nth-child(4){
  text-align: right;
}

.row > :last-child {
  text-align: center;
}

.containerTable {
  border-radius: 10px;
  padding: 20px;
  background: var(--inputBackground);
  color: var(--primaryColor);
}

.containerRows {
  height: 33vh;
  overflow: auto;
  margin: 0 0 10px 0;

  transition: background 0.25s ease;
  > label{
    &:hover{
      background: var(--secondaryBackground);

    }
  }
}

.containerRows input:checked + label {
  background-color: var(--selectedRow);
}


.containerRows{
  label{
    font-weight: normal !important;
  }
}

.filterIncomings {
  display: flex;
  gap: 0 10px;
  
  > *:nth-child(1) {
    display: none;
  }
}

.noAccount{
  display: flex;
  gap:0 1rem;
  margin:1rem 4rem;
  align-items: flex-end;
}

/////////////////////////////////////////////////////////////////////////

@media (max-width: 767px) {
  .containerTable {
    background: transparent;
    padding: 0;
  }

  .containerRows {
    height: 100%;
  }

  .header,
  .row {
    display: none;
  }

  .filterIncomings {
    > *:nth-child(1) {
      display: inline-block;
    }

    > *:nth-child(2),
    > *:nth-child(3),
    > *:nth-child(4),
    > *:nth-child(6),
    > *:nth-child(8),
    > *:nth-child(9),
    > *:nth-child(7) {
      display: none;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .movementsTop {
    background: var(--secondaryBackground);
  }
}
