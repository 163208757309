.charactes {
  font-weight: normal !important;
  margin: 0 0 0 5px;
  &::after {
    content: ")";
  }
  &::before {
    content: "(";
  }
}
