.container {
  height: 35vh;
  overflow: auto;
}

.abcContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p{
    margin: 0;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .abcContainer {
    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem 0;
      align-items: flex-start;
      div {
        width: 100% !important;
      }
    }
  }
}
