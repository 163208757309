.filterSearch {
  margin: 10px 0 0 0;
  display: grid;
  grid-template-columns: 15% 15% 15% 40% 10%;
  gap: 10px;
  align-items: flex-end;
  justify-content: space-between;
}

.row,
.header {
  display: grid;
  grid-template-columns: 12.5% 15% 12.5% 20% 10% 15% 10%;
  justify-content: space-between;
}

.header {
  text-align: center;
  font-weight: bold;
}

.row {
  transition: background 0.2s ease;
  &:hover {
    background: var(--secondaryBackground);
  }

  font-weight: normal;

  > p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 0;
  }

  > p:nth-of-type(1),
  > p:nth-of-type(6) {
    text-align: right;
  }
  > p:nth-of-type(4),
  > p:nth-of-type(5),
  > p:nth-of-type(7),
  > p:nth-of-type(2),
  > p:nth-of-type(3) {
    text-align: center;
  }
}

.creditNotesEmited {
  padding: 20px;
  background: var(--inputBackground);
  color: var(--primaruColor);
  border-radius: 10px;
  margin:0 10%;
}

.containerRow {
  > input {
    display: none;
  }

  input:checked + label {
    background: var(--selectedRow);
  }
}

.containerRows {
  overflow: auto;
  height: 35vh;
}

.containerOptionsCreditNote {
  min-height: 50px;
  display: flex;
  margin: 0 10% 10px 10%;

  > div,
  > a,
  > button {
    margin: 0 10px 0 0;
  }
}

.rangeDate {
  display: flex;
  justify-content: space-around;
}

////////////////////////////////////// RESPONSIVE //////////////////////////////////////

@media (max-width: 767px) {
  .creditNotesEmited {
    background: transparent;
    margin:0 10px;
    padding: 0;
  }

  .header {
    display: none;
  }



  .containerRow {
    label {
      display: none;
    }
  }

  .containerRows {
    height: 100%;
  }

  .containerOptionsCreditNote {
    display: none;
    height: 0;
  }
}


.accountingHeader,.accountingRow{
  display: grid;
  grid-template-columns: repeat(8,12%);
  justify-content: space-between;
  padding:10px 10px;
  margin:0 20px;
  >*{
    margin:0;
  }
}

.accountingHeader{
  position: sticky;
  top:0;
  font-weight: bold;
  text-align: center;
  background: var(--inputBackground);
}

.tableAccounting{
  max-height: 40vh;
  margin:0 10%;
  overflow: auto;
  background: var(--inputBackground);
  position: relative;
  >*:last-child{
    position: sticky;
    bottom:0;
    padding: 10px;
  }
  border-radius: 10px;



  label{

    transition: background 0.2s ease;

    &:hover{
      background: var(--secondaryBackground);  
    }
  }

  input:checked + label{
    background: var(--selectedRow);
  }
}

.accountingRow{
  font-weight: normal;
}