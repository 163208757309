.containerCard {
  p {
    margin: 0 0 0.4688rem 0;
    display: flex;
    flex-direction: column;
    padding: 0 0 0.25rem 0 !important;
  }
  b {
    margin: 0 0.3125rem 0 0;
  }
  // box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: var(--primaryBackground);
  border-radius: 1.06em;
  padding: 1em;
  margin: 0.9375rem;
  height: 100%;
}

svg{
  color:var(--primaryColor);
}

.infoCustomerContainer {
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infoCustomer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-areas:
    "sr sr sr type"
    "cn cn cn sn"
    "id ex ex ex";
}

.type {
  grid-area: type;
}

.sr {
  grid-area: sr;
}

.cn {
  grid-area: cn;
}

.sn {
  grid-area: sn;
}

.id {
  grid-area: id;
}

.ex {
  grid-area: ex;
  p {
    margin: 0;
  }
}

.adressInfo {
  display: grid;
  grid-template-columns: repeat(12, 8.33%);
  grid-template-areas:
    "rfc rfc rfc street street street street street street street street street"
    "noExt noExt noExt noInt noInt noInt cp cp cp . . ."
    "colony colony colony city city city state state stata country country country"
    "tel tel tel cel cel cel em em em em em em";
}

// "colony colony city city  state state city city";

.rfc {
  grid-area: rfc;
}

.street {
  grid-area: street;
}

.noExt {
  grid-area: noExt;
}

.noInt {
  grid-area: noInt;
}

.cp {
  grid-area: cp;
}

.colony {
  grid-area: colony;
}

.city {
  grid-area: city;
}

.state {
  grid-area: state;
}

.country {
  grid-area: country;
}

.tel {
  grid-area: tel;
}

.cel {
  grid-area: cel;
}

.em {
  grid-area: em;
}
/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .infoCustomerContainer {
    svg{
      display: none;
    }
    display: flex;
    flex-direction: column;
  }

  .infoCustomer{
    display: flex;
    flex-direction: column;
  }

  .adressInfo{
    display: flex;
    flex-direction: column;
  }
}
