$bgSelected: #e3f2fd;

.search {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  > div:nth-child(2) {
    min-width: 40%;
  }
}

.containerContacts {
  height: 100%;
  overflow: auto;
}

.topContacts {
  position: sticky;
  top: 0;
  padding: 0 0 1rem 0;
  background: white;
}

.tableContacts {
  background: var(--inputBackground);
  padding: 15px;
  border-radius: 15px;
}

.header {
  font-weight: bold;
  text-align: center;
}

.header,
.row {
  display: grid;
  grid-template-columns: 20% 13% 13% 20% 20% 10%;
  justify-content: space-between;
  > p {
    margin: 0;
    padding: 10px 0;
  }

  > *:nth-child(1) {
    > div {
      height: 100%;
    }
  }
}

.mobileItem {
  display: none;
  background: var(--primaryBackground);
  margin: 0 0 1rem 0;
  border-radius: 10px;
  padding: 10px;
}

.row {
  font-weight: normal;

  > p:nth-child(1) {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }

  > p {
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis;
  }

  > p {
    text-align: right;
  }

  > p:nth-child(1),
  > p:nth-child(4),
  > p:nth-child(5),
  > p:nth-child(6) {
    text-align: left;
  }

  transition: background 0.25s ease;

  &:hover {
    background-color: var(--secondaryBackground);
  }
}

.containerRows {
  height: 35vh;
  overflow: auto;
  input:checked + label {
    background: var(--selectedRow);	
  }
}

.moreInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  height: 100%;
}

.footerContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > input {
    width: auto;
  }
}

.moreInfoCustomer {
  text-decoration: underline;
  margin: 0 1rem;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.containerOptions {
  min-height: 50px;
  margin: 10px 0;
  display: flex;
  gap: 10px;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .search {
    > *:nth-child(3) {
      display: none;
    }
  }

  .containerOptions {
    min-height: 0;
    > a,
    > button {
      display: none;
    }
  }

  .tableContacts {
    padding: 0;
  }

  .mobileItem {
    display: block;
  }

  .row,
  .header {
    display: none;
  }

  .containerRows {
    height: auto;
  }

  .tableContacts {
    background: transparent;
  }
}

@media (prefers-color-scheme: dark) {

  $bgSelected: #3a3a3a;

  .topContacts {
    background: var(--secondaryBackground);
  }
}
