.containerTable {
  display: grid;
  // height: 600px;
  max-height: 370px;
  height: 370px;
  grid-template-columns: 1fr;
  grid-template-rows: 80% 20%;
  grid-template-areas:
    "table"
    "pagination";

  .tableContent {
    td,tr{
      font-size: 0.85rem;
    }
    display: flex;
    align-items: flex-start;
    grid-area: table;
    overflow-y: auto;
    tbody {
      tr {
        transition: 0.25s ease-in;
        &:hover {
          // background-color: #e2e2e2;
        }
      }
    }
    table {
      margin: 0;
      tr:nth-child(1) {
        position: sticky;
        top: 0;
        background: white;
      }
    }
  }
}

.actions {
  min-width: 150px;
  display: flex;
  justify-content: space-evenly;
  button {
    display: flex;
    align-items: center;
    padding: 4px;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: #fff;
    color: hsl(0, 0%, 41%);
    border: none;
  }

  button.notEmpty {
    color: hsl(204, 100%, 63%);
  }

  button.empty {
    color: hsl(0, 0%, 41%);
  }

  .pdfAction,
  .delete {
    color: #ff0000;
  }

  .sendPdfAction {
    color: hsl(204, 100%, 63%);
  }
}
