.container {
  * {
    font-size: 1rem;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  span {
    color: rgb(42, 87, 202);
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }
}
