.filter {
  display: none;

  > button {
    padding: 0 1rem;
  }
}

.footerFilter{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  button{
    // height: 100% !important;
    // padding: 0 !important;
    padding:0 1rem;
  }
}

.content{
  display: flex;
  flex-direction: column;
  gap:1rem 0;
}

@media (max-width: 767px) {
  .filter {
    height: 3.125rem;
    overflow: auto;
    margin:0 10px;
    display: flex;
    gap: 0 0.5rem;
  }
}
