.container {
  transition: background 0.4s ease;
  border-radius: 0.25rem;
  margin:5px;
  cursor: pointer;
  &:hover {
    background-color: rgb(235, 235, 235);
  }

  svg {
    padding: 0.25rem;
    box-sizing: content-box;
    pointer-events: none;
  }
}

/////////////////////////////////////////////// MODO OSCURO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .container{
    &:hover{
      background: var(--primaryBackground);
    }
  }
}
