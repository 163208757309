.container {
  display: flex;
  gap: 0 1rem;
  width: auto !important;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    gap:1rem 0;
  }
}
