.containerOptionsCatalogue {
  min-height: 3.125rem;
  margin: 1rem 0;
}

// containerOptionsCatalogue
@media (max-width: 767px) {
  .containerOptionsCatalogue {
    min-height: 0;
    height: 0;
    margin: 0;

    > button{
      display: none;
    }
  }
}
