.containerField {
  display: flex;
  svg {
    height: 20px;
  }
  justify-content: space-between;
  align-items: center;
}

.satCodeContainer {
  width: 100%;
}

.modalButton {
  border-radius: 999px;
  border: none;
  background: var(--primaryBackground);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-color: 2px solid var(--primaryColor) !important;
  }
}
