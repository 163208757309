.formProyect {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  margin: 20px 30%;
}

.container {
  height: 100%;
  overflow: auto;

  > *:nth-child(1) {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

@media (max-width: 768px) {
  .formProyect {
    margin: 20px;
  }
}
