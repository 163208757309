.mobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}
