.managmentOc {
  padding: 20px;
  border-radius: 5px;
  background: var(--primaryBackground);
  * {
    color: var(--primaryColor);
  }
  display: none;
}

.options {
  display: flex;
  gap: 0 10px;

  > svg:first-child {
    height: 40px;
  }

  align-items: center;
}

.checkBox {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  input {
    margin: 0;
  }
}

.confirmation{
  border:1px solid var(--primaryColor);
  border-style: solid none none none;
  padding:10px 0;
  display: grid;
  grid-template-columns: 48% 48%;
  grid-auto-rows: 1fr;
  justify-content: space-between;
  text-align: center;
  gap:10px 0;
  p{
    margin:0;
    font-weight: bold;
  }

  p:nth-child(n+3){
    display: none;
  }

  input{
    grid-column: 1/3;
  }
}

@media only screen and (max-width: 768px) {
  .managmentOc {
    display: flex;
    flex-direction: column;
    gap: 5px 0;
    margin: 0 0 20px 0;
  }
}
