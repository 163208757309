.createTicket {
  label {
    margin: 10px 0 0 0;
  }
}

.createTicket > div:nth-child(1) {
  overflow: auto;
  padding: 0 20px;
}

.footerCreateTicket {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e7e7e7;
  padding: 10px 0 0 0;
  margin: 10px 0 0 0;
  align-items: center;
  border-style: solid none none none;
  p {
    margin: 0;
  }
}

.associatedFiles{
  display: flex;
  flex-direction: column;
  gap:10px 0;
  margin:10px 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .footer {
    > button {
      padding: 0 1rem;
      height: 100%;
    }
  }
}
