.container {
  display: flex;
  gap: 0 1rem;
  align-items: flex-end;
  > *:nth-child(2) {
    width: 20%;
  }

  > button {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    padding: 10px;
    border-style: none;
    transition: all 0.25s ease;
    background: #191919;
    border: 2px solid #191919;
    svg {
      color: white;
    }
    &:hover {
      svg {
        color: #191919;
      }
      background: transparent;
    }
  }
}

.filter {
  display: grid;
  grid-template-columns: 75% auto;
  justify-content: space-between;
  align-items: flex-end;
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .container {
    > button {
      &:hover {
        border-color: white;
        svg {
          color: white;
        }
        background: transparent;
      }
    }
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .container {
    gap: 0 0.25rem;
  }

  .cancel{
    padding: 0 1rem !important;
      height: 3.125rem !important;
  }

  .filter {
    > button {
      padding: 0 1rem;
      height: 3.125rem;
    }
    > form {
      > *:nth-child(1) {
        width: 40%;
      }

      > *:nth-child(2) {
        width: 60%;
      }

      > *:nth-child(3),
      > *:nth-child(4),
      > *:nth-child(5) {
        display: none;
      }
    }
    display: flex;
    gap: 0 0.25rem;
  }
}
