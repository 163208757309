.bannerPreview,
.currentBanner {
  img {
    height: 70px !important;
  }
  p{
    margin:0;
  }
  gap:15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:20px 0;
}

.previewDark,.previewLight{
  p{
    font-weight: bold;
  }
}

.previewDark {
  background: #2e2e2e;
  * {
    color: white !important;
  }
}

.previewLight {
  background: white;
  * {
    color: rgb(12, 12, 12) !important;
  }
}
