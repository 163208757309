.container-fluid {
  height: 100vh;
  .loginLeftSide {
    // background-color: #4D1352;
    img {
      width: 100%;
      height: 100%;
    }
    justify-content: center;
    align-items: center;
    display: flex;
    .saikoLogo {
      position: absolute;
      .simbolo {
        margin-left: auto;
        margin-right: auto;
        background-image: url("../../assets/svg/simbolo-login.svg");
        width: 273px;
        height: 311px;
      }
      .letras-saiko {
        // display: flex;
        margin-left: auto;
        margin-right: auto;
        background-image: url("../../assets/svg/letras-saiko.svg");
        width: 587px;
        height: 105px;
      }
    }
  }
  .loginRightSide {
    // background-image:        url("../../assets/svg/fondo2-login.svg");
    // background-size:         cover;
    // background-color:        rgba(0, 0, 0, 0.25);
    background-color: #2b2c34;
    // border-top-left-radius:     10px;
    // border-bottom-left-radius:  10px;
    box-shadow: -2px 1px 15px black;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .formContainer {
      width: 550px;
      color: whitesmoke !important;
    }
  }

  // input[type="text"], input[type="password"]
  #inputUser,
  #inputPass,
  #inputRecoverPassword {
    background: transparent !important;
    border: none;
    color: whitesmoke !important;
    border-bottom: 3px solid whitesmoke !important;
  }

  .logtransparent {
    background-color: transparent !important;
    border: transparent;
    border-bottom: 3px solid whitesmoke !important;
  }

  #btnLogin {
    border: none;
  }

  .logoempresa {
    position: absolute;
    top: 0;
    width: 250px;
  }
}

.otpContainer {
  
  border-radius: 1.06em;
  width: 100%;
  padding: 3em 0em;
  height: 100%;

  > div:nth-child(1) {
    display: flex;
    width: 100%;
  }

  > div p:nth-child(1) {
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.5s ease;
    padding: 0.75rem;
    &:hover {
      border: 1px solid white;
    }
    display: flex;
    align-items: center;
    svg {
      margin: 0 0.5rem 0 0;
      height: 1.5rem;
    }
  }

  > p {
    font-weight: bold;
    font-size: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
  }
}

.loginContainer {
  background: var(--secondaryBackground);
  display: grid;
  grid-template-columns: 50vw 50vw;
  grid-template-rows: 90vh 10vh;
  overflow: auto;
  > img:nth-child(1) {
    object-fit: cover;
    height: 100%;
    object-position: center center;
    width: 100%;
  }

  > *:nth-child(2) {
    align-items: center;
    margin: 15%;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 100%;
    padding: 4rem;
  }

  > *:last-child {
    grid-column: 1/3;
  }
}

.loginForm {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: var(--cardBackground);
  overflow: auto;
  form {
    > button {
      margin: 1rem 0;
      width: 100%;
    }

    > p:last-child {
      margin: 0 0 1rem 0;
      color: var(--primaryColor);
      text-align: center;
      text-decoration: underline;
      cursor: default;
      &:hover {
        font-weight: bold;
      }
    }
  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .loginForm {
    box-shadow: rgba(0 0 0 / 20%) 0px 8px 24px;
  }

  .loginContainer {
    > img:nth-child(1) {
      content: url("https://images.unsplash.com/photo-1510519138101-570d1dca3d66?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGFyayUyMG9mZmljZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80");
    }
  }
}

//////////////////////// MOBILE ////////////////////////

@media (max-width: 767px) {
  .loginContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 10dvh 80dvh 10dvh;

    > *:nth-child(2) {
      align-items: center;
      margin: 5%;
      border-radius: 1rem;
      display: grid;
      grid-template-columns: 100%;
      padding: 1rem;
    }
    > img:nth-child(1) {
      grid-row: 1/2;
    }

    .loginForm {
      grid-row: 2/3;
    }

    > div:last-child {
      grid-row: 3/4;
    }
  }
}
