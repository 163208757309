.containerSpinner {
  p {
    text-align: center;
    color: var(--primaryColor);
  }
}

@media (max-width: 767px) {
  .containerSpinner {
    > div:nth-child(1) {
      display: none !important;
    }
  }
}
