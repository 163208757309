.table {
  background: var(--inputBackground);
  padding: 10px;
  border-radius: 10px;
}

.containerDocuments {
  height: 35vh;
  overflow: auto;
  margin: 1rem 0;
  input:checked + label {
    background: var(--selectedRow);
  }
}

@media (max-width: 767px) {
  .containerDocuments {
    height: 100%;
  }

  .table {
    background: transparent;
    padding: 0;
  }
}
