.container {
  height: 100%;
  overflow: auto;
}

.header {
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  top: 0;
  
  > *:nth-child(n + 2) {
    margin: 0 5rem;
  }
  background: #ffffff;
}

.content {
  margin: 0 5rem;
}

////////////////////////////////////////////  [RESPONSIVE]  ////////////////////////////////////////////

@media (max-width: 767px) {
  .content {
    margin: 0 1rem;
  }

  .header{
    padding:0 0 1rem 0;
  }
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .header {
    background: var(--secondaryBackground);
  }
}
