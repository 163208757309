.pagination {
  width: 100%;
  color: var(--primaryBackground);
  > div:first-child {
    display: flex;
    gap: 10px;
    input,
    select {
      width: auto;
    }
    input {
      width: 100px;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    width: 200px;
    border-radius: 0 !important;
    padding: 10px;
  }
  background-color: var(--inputBackground);
}

.mobilePagination {

  display: none !important;
}

.searchPage {
  display: flex;
  gap:0 1rem;
  input{
    border-radius: 0;
  }
}

.page {
  svg: {
    pointer-events: none;
  }
  span {
    color: var(--primaryColor);
  }
  button {
    color: var(--primaryColor);
    transition: 0.2s ease-in;
    &:disabled {
      cursor: not-allowed;
    }
    &:hover {
      background-color: var(--secondaryBackground);
    }
    background-color: var(--inputBackground);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
  display: flex;
  align-items: center;

  span:last-of-type {
    margin: 0 10px 0 0;
  }

  span:first-of-type {
    margin: 0 5px 0 10px;
    &::after {
      content: " - ";
    }
  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .pagination {
    input {
      border-radius: 5px 5px 0 0;
      background: var(--secondaryBackground);
    }
  }
}

//////////////////////// MOBILE DESIGN ////////////////////////

@media (max-width: 767px) {
  .mobilePagination {
    position: relative;
    display: flex !important;
    width: 100%;
    justify-content: space-between;
    button {
      width: 45%;
      padding: 0.2rem 0;
      background: var(--primaryBackground);
      color: var(--primaryColor);
      text-align: center;
      border: 1px solid var(--primaryBackground);
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .pagination {
    background: transparent;
    position: relative !important;
  }

  .page,
  .searchPage {
    display: none;
  }
}
