.card,
.empty {
  width: 100%;
  padding: 15px;
  margin: 5px;
  min-height: 250px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.empty {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--primaryColor);
  background: var(--primaryBackground);
}

.card {
  background: var(--primaryBackground);
  .folio {
    &:before {
      content: "#";
    }
  }

  .currency {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    p {
      display: flex;
      align-items: center;
    }
    img {
      margin: 0 10px 0 0;
    }
    p {
      margin: 0 0 2px 0;
    }
  }

  .header {
    img {
      width: 30px;
    }

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    p {
      span:nth-child(even) {
        &::before {
          margin: 0 2.5px;
          content: ":";
        }
        margin: 0 20px 0 0;
      }
    }
  }

  h4 {
    margin: 10px 0;
  }

  .amounts {
    p {
      display: flex;
      justify-content: space-between;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    p {
      margin: 0 0 5px 0;
    }

    span {
      margin: 0 0 0 15px;
    }
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .empty {
    min-height: auto;
  }
}
