$backgroundRow: #e4e4e4;

.row,
.header {
  display: grid;
  grid-template-columns: 8% 5% 15% 5% 13% 6% 10% 16% 10%;
  justify-content: space-between;
}

.cancelMobile{
  > button{
    display: none;
  }
}

.header {
  font-weight: bold;
  text-align: center;
}

.uenContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    &:after {
      content: ",";
    }
    &:last-child {
      &::after {
        content: "";
      }
    }
  }
}

.mobileItem {
  display: none;
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 1rem;
  margin: 0 0 1rem 0;
}

.table {
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 15px;
}

.row {
  font-weight: normal !important;
  transition: background 0.25s ease;
  border:1px solid var(--primaryColor);
  border-style: none none solid none;

  &:hover {
    background: var(--secondaryBackground);
  }

  > p {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 10px 0;
  }

  > div:nth-of-type(1) {
    display: flex;
    text-decoration: underline;
    justify-content: flex-end;
    align-items: center;
    > p {
      margin: 0;
    }
  }


}

.bodyTable {
  height: 40vh;
  overflow: auto;
  input[type="radio"] {
    display: none;
  }

  input:checked + label {
    transition: background 0.25s ease;
    background: var(--secondaryBackground);
  }
}

.mobileLabel {
  display: flex;
  gap: 0 1rem;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .header {
    display: none;
  }

  .table {
    background: transparent;
  }

  .bodyTable {
    height: auto;
  }

  .mobileItem {
    display: block;
  }

  .row {
    display: none;
  }
}
