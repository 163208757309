.spaceBetween {
  display: flex;
  justify-content: end;
  .spaceBetween-item {
    margin: 0 15px 20px 0;
  }
}

.required {
  &::after {
    content: " *";
    color: red;
  }
}

.hyperlink{
  color:#006ec9;
  text-decoration: underline;
  &:hover{
    font-weight: bold;
  }
}

////////////////////// [COLORS] ///////////////////////////////7
.correct {
  color: #99da66;
}

svg {
  pointer-events: none;
}

.default-pagination {
  display: flex;
  justify-content: space-between;
  .w-50 {
    width: auto !important;
  }

  @media (prefers-color-scheme: dark) {
    input {
      border-radius: 0 !important;
      border-style: none none solid none !important;
      background: var(--secondaryBackground) !important;
    }

    > div:nth-child(2) {
      * {
        background: transparent !important;
      }
    }
  }
}

.default-pagination-search {
  display: none !important;
}

.disabled {
  cursor: not-allowed;
  select {
    pointer-events: none;
  }
}

.align-right {
  text-align: right;
}

.cursorMove {
  cursor: grab;
}

.cursorDragging,
.sortable-ghost,
.sortable-chosen {
  cursor: grabbing;
}

.iconCard {
  &:hover {
    svg {
      opacity: 0.5;
      transition: 0.25s ease-out;
    }
  }
  svg {
    transition: 0.25s ease-out;
    opacity: 1;
  }
}
///////////// [SIZE] ///////////////////////////////////
.h-50 {
  height: 3.125em;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.width-auto {
  width: auto;
}

.w-75 {
  width: 75%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-13 {
  width: 13%;
}

.w-15 {
  width: 15%;
}

.fit {
  width: auto;
}

.cardSize {
  max-height: 500px;
  min-height: 500px;
  overflow: auto;
}

////////////////////////////////////////////////////////
.cursorHand {
  cursor: pointer;
  display: inline-block;
}

.cursorPointer {
  cursor: pointer;
}

.cursorBlock {
  cursor: not-allowed;
}

.go-back {
  border-radius: 0.625em;
  padding: 0.5em 1em;
  border: 0.125em solid #000;
  display: inline-block;
}

.documentSize {
  max-height: 600px;
  min-height: 600px;
  overflow-y: auto;
}

// Modal
.modal50 {
  min-width: 50vw;
}

//////////////////////////////////////////////
.hoverHand {
  transition: ease-out 0.5s;
  cursor: pointer;
  display: inline-block;
  &:hover {
    font-size: 1.05em;
  }
}

.width-25 {
  width: 25% !important;
}

.width-75 {
  width: 75% !important;
}

/////////////////////////////////////////////////////////
.shadowBackground {
  // box-shadow:             0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  // background-color:       rgb(65, 65, 65);
  background-color: transparent;
  border-radius: 1.06em;
  width: 100%;
  padding: 3em 0em;
  // background-color:    white;
  height: 100%;
  // margin:              auto;
}

.shadowBackgroundTable {
  box-shadow: 0.25em 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.25);
  background-color: var(--primaryBackground);
  border-radius: 0.5em;
  width: 100%;
  display: block;
  padding: 3em 0em;
  color: var(--primaryColor);
  height: 100%;
  margin: auto;
  thead {
    background: var(--primaryBackground) !important;
    color: var(--primaryColor);
  }

  .default {
    .selectedRow {
      background: var(--secondaryBackground) !important;
    }

    tr {
      background-color: var(--primaryBackground) !important;
      color: var(--primaryColor) !important;
    }
  }
}

.card {
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 1.06em;
  padding: 1em;
}

/* /////////////////////// [SPINNER] ////////////////////// */

.spinner {
  width: 40px;
  height: 40px;
  background-color: var(--primaryColor);
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

//////////////////////////////////////////////////////////////

input:disabled {
  cursor: url("./not_allowed.png"), auto !important;
}

@media (prefers-color-scheme: dark) {
  .hyperlink{
    color:#83c7ff;
  }
}
