.overviewInvoiceReception {
  display: grid;
  color:var(--primaryColor);
  grid-template-columns: repeat(4, 25%);
  grid-template-areas:
    "invoice invoice customer customer"
    "partialities partialities partialities partialities";
}

.info {
  margin: 0 0 10px 0;
  span:nth-child(1) {
    font-weight: bold;
  }

  span:nth-child(2) {
    &:before {
      margin: 0 10px 0 0;
      content: ":";
    }
  }
}

.containerTabs {
  padding: 15px;
}

.containerCustomer {
  grid-area: customer;
  height: 100%;
}

.containerInvoice {
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: var(--primaryBackground);
  border-radius: 1.06em;
  padding: 1em;
  margin: 0.9375rem;
  grid-area: invoice;
  height: 100%;
}

.emptyCustomer {
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: var(--primaryBackground);
  border-radius: 1.06em;
  padding: 1em;
  margin: 0.9375rem;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .overviewInvoiceReception {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
