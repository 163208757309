.filter {
  display: flex;
  gap: 0 10px;
  align-items: flex-end;
}

.table {
  * {
    background: var(--primaryBackground) !important;
    color: var(--primaryColor) !important;
  }
}

.module {

  margin: 10px 10% 0 10%;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}
