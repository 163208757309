.labelInvoice {
  display: flex;
  align-items: center;
  small {
    display: flex;
    align-items: center;
    color: rgb(0, 78, 167);
    font-weight: bold;
    margin: 0 10px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.containerCreateCreditNote {
  > div {
    margin: 0 0 10px 0;
  }
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    width: 100%;
  }
}

.warningCreditNote {
  color: #e98100;
  font-weight: bold;
  text-align: center;
  margin:10px 0;
}

.invoiceOverview {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  justify-content: space-between;
  div:nth-child(1) {
    grid-column: 1/4;
    grid-row: 1/2;
  }
}
