.boxComment {
  display: flex;
  justify-content: space-between;
  height: 70px;
  button {
    height: 100%;
  }
  textarea {
    resize: none;
    height: 100%;
  }
  > *:nth-child(1) {
    width: 80%;
  }

  > *:nth-child(2) {
    width: 18%;
  }
}

/////////////////////////////////// MOBILE ///////////////////////////////////

@media (max-width: 767px) {
  .boxComment {
    height: 100%;
    textarea {
      overflow: hidden;
    }

    > *:nth-child(1) {
      width: 75%;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
