.filter {
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
}

.combos {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 0 10px;
  > div:nth-child(-n + 2) {
    width: 23vw;
  }
}

.overviewRecords {
  width: 100%;
  justify-content: flex-end;
  p {
    margin: 0;
  }
  svg {
    height: 25px;
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .mobileStatus {
    display: block;
  }

  .overviewRecords{
    display: none;
  }

  .combos {
    margin: 10px 0 0 0;
    flex-direction: column;
    gap: 10px 0;

    > div {
      width: 100% !important;
    }

    > button {
      display: none;
    }
  }

  .steps {
    display: none;
  }
}
