$user-grey: #bdbdbd;
$fontFamily: "Century Gothic";
.header-container {
  position: sticky;
  margin-left: 1.3125em;
  margin-right: 1.3125em;
  z-index: 0;
  // margin-bottom: 0.5em;
  // height: 15.3125em;
  // padding-top: 1.6343em;
  background: white;
  height: fit-content;
  .header-logo {
    width: 14.8521em;
    height: 3.6593em;
  }
  .header-user-info {
    // margin-right: 4.375em;
    .header-user-user {
      font-size: 1em;
      font-family: $fontFamily;
      color: $user-grey;
      margin: 0;
      p {
        margin: 0;
      }
    }
    .header-user-logout {
      font-family: $fontFamily;
      font-size: 0.875em;
      color: $user-grey;
      padding-left: 15px;
    }
  }
  .header-user-logo {
    float: left;
    color: $user-grey;
  }
  .bredcrum-container {
    height: 8.5em;
  }
  .botonera-container {
    position: relative;
    // height: 8.5em;
    margin-bottom: 15px;
    border: 0.0625em solid;
    border-radius: 0.625em;
    background: transparent;
    .botonera-barra1 {
      position: relative;
      height: 0.99em;
      background: #191919;
      border-radius: 0.625em;
    }
    .botomera-barra2 {
      position: relative;
      height: 2.8125em;
      background: #191919;
      border-radius: 0em 0em 2.3303em 2.3303em;
      margin-left: 12.375em;
      margin-right: 12.375em;
      .headerTitle {
        color: #fff;
        text-align: center;
        font-size: 1.357em;
        font-family: "Century Gothic";
      }
    }
    .navPosition {
      position: relative;
      top: 1.625em;
      left: 0em;
    }
    .nav-pills {
      border-radius: 3.125em;
    }
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #191919;
      width: 100%;
      box-shadow: 0em 0.5em 1em 0em rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
    .dropdown:hover .dropdown-content {
      display: block;
    }
    .dropdown:hover .hoveringLink {
      background-color: #191919;
      color: #f9f9f9;
    }
  }
  .no-button {
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
  }
  .doprDownMenuContent {
    display: none;
    position: absolute;
    min-width: 90px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
  }
  .dropdownMenu:hover .doprDownMenuContent {
    display: block;
  }
}

.bredcrums-barra2 {
  position: relative;
  height: 2.5em;
  background: #191919;
  border-radius: 0em 0em 2.3303em 2.3303em;
  margin: 0 12.375rem;
  // margin-left: 12.375em;
  // margin-right: 12.375em;
}

@media (max-width: 767px) {
  .bredcrums-barra2 {
    margin: 0 2rem;
  }
}

@media (prefers-color-scheme: dark) {
  .header-container {
    background: var(--secondaryBackground);
  }

  .botonera-barra1,
  .bredcrums-barra2 {
    background: var(--primaryBackground) !important;
  }
}
