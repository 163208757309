.row,
.header {
  display: grid;
  grid-template-columns: 4.1% 14.1% 9.1% 14.1% 11.1% 11.1% 14.1% 11.1% 10.1%;
  margin: 5px 0;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-weight: bold;
  text-align: center;
}

.row {
  p {
    margin: 0;
  }
  padding: 15px 0;
  font-weight: normal !important;

  transition: background 0.5s ease;
}

.summary {
  padding: 15px 20px;
  color: var(--primaryColor);

  text-align: left;
}

.summary + h4 {
  padding: 0 20px;
  text-align: left;
}

.detailsAssociations{
  display: flex;
  flex-direction: column;
  gap:10px 0;
}

@media (max-width: 767px) {
  .row,.header{
    width: 1000px;
  }
}
