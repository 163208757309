.formContainer {
  overflow: hidden;
  > div {
    input + p {
      margin: 5px 0 0 0;
    }
  }

  > div {
    margin: 0 0 10px 0;
  }
}

.submitContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
