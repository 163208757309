.card {
  display: none;
}

.related {
  display: flex;
  gap: 0 1rem;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .card {
    background: var(--primaryBackground);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 0 0 1.5rem 0;
  }
}
