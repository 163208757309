$bgChip: #F9F9F9;
$borderChip: #E5E5E5;
$sizeBorder:0.1875em;

.header {
  font-weight: bold;
  text-align: center;
}

.row,
.header {
  display: grid;
  grid-template-columns: 25% 25% 25% 12.5% 12.5%;
}

.row {
  font-weight: normal;

  > p {
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.containerRows {
  max-height: 40vh;
}

.uenChip {
  padding: 5px 10px;
  border-radius: 50%;
}

.containerChips {
  display: flex;
  
  justify-content: space-between;
  min-height:3.125em;
  > div:nth-child(1) {
    width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;
    align-items: center;
    > p{
      margin:0;
    }
    > span {
      background: $bgChip;
      white-space: nowrap;
      padding: 5px 15px;
      border-radius: 10px 10px 0 0;
      border: $sizeBorder solid $borderChip;
      border-style: solid solid none solid;
    }
  }

  > div:nth-child(2){
    display: flex;
    gap:0 0.5rem;
  }
}

.chipAction {
  background: $bgChip;
  border: $sizeBorder solid $borderChip;
  border-radius: 10px;
}

.styleViewChips{
  li{
    list-style-type: decimal;

  }
}