li {
  list-style: none;
}

.AsignContainer {
  height: 100%;
}

.EngineerInfo {
  padding: 0 7px 0 7px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  transition: 0.15s;
  font-weight: normal;
}

.EngineerInfo:hover {
  background-color: lightgray;
}

.EngineerInfo::selection {
  background-color: gray;
}

.engineerslist {
  height: 80%;
  padding: 5px;
  overflow-y: scroll;
}

.engineerslist::-webkit-scrollbar {
  -webkit-appearance: none;
}

.engineerslist::-webkit-scrollbar:vertical {
  width: 8px;
}

.engineerslist::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: none;
}

.footerbutton {
  height: 15%;
  display: flex;
  align-items: end;
  justify-content: end;
}

.EngineerSelected {
  box-shadow: 0 0 3px black;
  padding: 0 7px 0 7px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  transition: 0.3s;
  font-weight: bolder;
}

.usersToAssign label {
  padding: 1rem 0.5rem;
}

.usersToAssign input:checked + label {
  background: var(--primaryBackground);
}

.usersToAssign span {
  display: none;
}

.usersToAssign input:checked + label span {
  display: inline-block;
}

/* //////////////////////// DARK MODE //////////////////////// */

@media (prefers-color-scheme: dark) {
  .EngineerInfo {
    border: 1px solid var(--primaryColor) !important;
    border-style: none none solid none !important;
  }

  .usersToAssign div > label:last-child {
    border: none !important;
  }

  .EngineerInfo:hover {
    background: var(--primaryBackground);
  }
}
