.containerAdvancesModule {
  height: 100%;
  overflow: auto;

  padding: 0 0 1rem 0;
}

.header {
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  p{
    margin:0;
  }
}

.header,
.row {
  display: grid;
  grid-template-columns: 5% 15% 20% 20% 20% 10% 10%;
  padding: 5px 0;
}

.row {
  p {
    margin: 0;
    padding: 10px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > *:last-child {
    text-align: right;
  }

  > *:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > {
    p:nth-child(1),
    p:nth-child(2),
    p:nth-child(4),
    p:nth-child(6) {
      text-align: center;
    }

    p:nth-child(7) {
      text-align: right;
    }
  }

  font-weight: normal !important;
}

.tableContainer {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  background: var(--primaryBackground);
  padding: 15px 20px;
  input {
    display: none;
  }
  label {
    transition: 0.25s ease;
    &:hover {
      background-color: var(--secondaryBackground);
    }
  }
}

.containerAdvances {
  height: 40vh;
  overflow: auto;
  margin: 10px 0;
}

.tableContainer input:checked + label {
  background: var(--secondaryBackground);
}

.filter {
  display: flex;
  gap: 0 10px;

  align-items: flex-end;

  > *:nth-child(1) {
    width: 25%;
  }
}

.mobileAdvance {
  color: var(--primaryColor);
  display: none;
  background: var(--primaryBackground);
  padding: 1rem;
  border-radius: 10px;
  gap: 1rem 0;
  margin: 0 0 1rem 0;
}

.amounts {
  display: flex;
  gap: 0 1rem;
}

.topAdvance {
  position: sticky;
  top: 0;
  background: white;
  padding: 0 0 1rem 0;
}

.filter,.tableContainer {
  margin: 0 2rem !important;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media (max-width: 767px) {
  .filter {
    > *:nth-child(1) {
      width: 100%;
    }

    > *:nth-child(n + 2) {
      display: none;
    }
  }

  .mobileAdvance {
    display: flex;
    flex-direction: column;
    label {
      &:hover {
        background: none;
      }
    }
  }

  .tableContainer {
    box-shadow: none;
    background: transparent;
    padding: 0;
  }

  .containerAdvances {
    height: 100%;
  }

  .header,
  .row {
    display: none;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .topAdvance {
    background: var(--secondaryBackground);
  }
}
