.banner {
  height: 50%;
  display: grid;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 30px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background: var(--cardBackground);
  border-radius: 10px;
}

.container {
  width: 98vw;
  display: grid;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.redirect {
  color: rgb(0, 122, 221);
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
}
