.searchFilter {
  display: grid;
  grid-template-columns: 50% 10%;
  align-items: flex-end;
  gap: 10px;
  margin: 10px 0;
}

.container {
  height: 100%;
  overflow: auto;
}

.header {
  position: sticky;
  top: 0;
  padding: 0 0 1rem 0;
  background: var(--secondaryBackground);
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.filter {
  display: flex;
  gap: 0 1rem;
  align-items: flex-end;
  margin: 0 10%;

  > *:nth-child(4) {
    width: 35%;
  }
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: light) {
  .header {
    background: #ffffff;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .filter {

    > *:nth-child(4) {
      width: 75%;
    }

    margin: 0 10px;
    > *:nth-child(-n + 3) {
      display: none;
    }

    > button {
      padding: 0 1rem !important;
    }
  }
}
