.options {
  display: flex;
  gap: 0 1rem;
  height: 3.125rem;
  margin:1rem 0;
}

////////////////////////////////////////////  [RESPONSIVE]  ////////////////////////////////////////////

@media (max-width: 767px) {
  .options {
    display: none;
    height: 0;
  }
}
