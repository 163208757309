.manufacturer,
.header {
  display: grid;
  grid-template-columns: 40% 40% 10%;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
  }
  padding: 10px 0;
}

.header {
  font-weight: bold;
}

.add {
  display: flex;
  flex-direction: column;
}

.manufacturer{
  border:1px solid var(--cardBackground);
  border-style:solid none none none;
}

@media only screen and (max-width: 1023px) {
  .header,
  .manufacturer {
    grid-template-columns: 100%;
    gap: 10px 0;
  }

  .add {
    b {
      display: none;
    }
  }
}
