.ocManagment {
  height: 100%;
  overflow: auto;
  position: relative;
}

.margin,
.tableContainer,
.topModule {
  margin: 0 20px;
}

.topModule {
  max-height: 30vh;
}

.tableContainer {
  min-height: 30vh;
  max-height: 40vh;
  overflow: auto;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .topModule {
    > form {
      display: none;
    }
  }

  .tableContainer {
    height: 100%;
    overflow: initial;
  }
}
