.mobileBankAccount {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  background: var(--primaryBackground);
  border-radius: 10px;
  padding:1rem;
  color:var(--primaryColor);

  >*:nth-child(2){
    grid-column: 3/5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  >*:nth-child(3){
    grid-column: 2/4;
    grid-row: 2/3;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >*:nth-child(4){
    grid-column: 3/5;
    grid-row: 3/4;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin:0;
  }
}
