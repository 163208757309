.list {
  display: flex;
  span {
    &:last-child {
      &::after {
        content: "";
      }
    }
    &:after {
      content: ",";
      margin: 0 4px;
    }
  }
}
