@font-face {
  font-family: GoldmanSans;
  src: url("./GoldmanSans_W_Rg.woff");
  font-weight: normal;
}

@font-face {
  font-family: GoldmanSans;
  src: url("./GoldmanSans_W_Lt.woff");
  font-weight: lighter;
}

.praxia {
  width: 100vw;
  gap:0 20px;
  z-index: -1;
  height: 100%;
  background-color: #191919;
  box-sizing: content-box;

  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 50%;
  }
  span {
    font-family: GoldmanSans;
    font-weight: lighter;
    color: rgb(225, 225, 225);
    font-size: 4vh;
    line-height: 40px;
  }
  p {
    margin: 0 0 0 10px;
    &:hover {
      color: rgb(52, 77, 130);
    }
    color: rgb(225, 225, 225);
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    cursor: default;
    text-transform: uppercase;
  }
}

.praxiaEnviroment{
  filter: invert(100%);

}