.typeTickets {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  justify-content: center;
  width: 240px;
}

.ticketFilter {
  font-weight: normal !important;
  width: 240px;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  transition: 0.4s ease;

  &:hover {
    background-color: #e7e7e7;
  }
}
