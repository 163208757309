.containerReport {
  height: 30vh;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}

.inputs {
  display: grid;
  grid-template-columns: 70% 20%;
  justify-content: space-around;
  gap:2rem 0 ;
}

@media (max-width: 767px) {
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
  }

  .containerReport{
    height: 100%;
    align-items: flex-start;
  }
}
