.commentBox {
  display: grid;
  grid-template-columns: 70% 10%;
  align-items: end;
  gap: 10px;
  margin: 0 0 10px 0;
}

.comment {
  display: grid;
  grid-template-columns: 90% 5% 5%;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 15%;
  * {
    margin: 0 1px;
  }
}

.commentRegular {
  display: flex;
  align-items: center;
}

.content {
  margin: 0;
  text-align: left;
  max-height: 3rem;
  overflow-y: auto;
  width: 85%;
}
