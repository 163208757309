.exchangeExecutive {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  justify-content: space-between;
  gap: 10px 0;
  align-items: center;
  p {
    margin: 0;
  }
  b{
    text-align: center;
    position: sticky;
    top:0;
    padding: 15px 0;
    background: var(--secondaryBackground);
    z-index: 1;
  }
}
