.container {
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: 100%;

//  >*:nth-child(1){
//   z-index: 10;
//  }

  > *:nth-child(2) {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20% 80%;
  }
}

@media (max-width: 767px) {
  .container {

 >*:nth-child(1){
  z-index: 10;
 }


    > *:nth-child(2) {
      display: flex;
      flex-direction: column;
    }
  }
}
