.containerBank {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  padding: 20px;
  border-radius: 10px;
  background: var(--cardBackground);
  width: 30%;
}

.containerModule {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: flex-start;
}

.date {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  justify-content: space-between;
}
