.header {
  font-weight: normal;
  padding: 10px 0;
  align-items: center;
  input[type="checkbox"][disabled] {
    cursor: not-allowed;
  }
}

.header,
.row {
  display: grid;
  grid-template-columns: 5% 10% 28.5% 8% 10% 10% 20% 6.5%;
  justify-content: space-between;
}

.row {
  font-weight: normal;
  align-items: center;
  transition: 0.1s ease-in;
  &:last-of-type {
    margin: 0 0 10px 0;
  }

  > label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    &:disabled {
      cursor: not-allowed;
    }
  }

  /////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0;
    align-items: flex-start;
    justify-content: flex-start;

    > * {
      text-align: left !important;
      &::before {
        font-weight: bold;
        white-space: pre;
      }
    }

    > *:nth-child(1) {
      &:before {
        content: "No. mov.\A";
      }
    }

    > *:nth-child(2) {
      &:before {
        content: "Fecha \A";
      }
    }

    > *:nth-child(3) {
      &:before {
        content: "Referencia \A";
      }
    }

    > *:nth-child(4) {
      &:before {
        content: "Concepto \A";
      }
    }

    > *:nth-child(5) {
      &:before {
        content: "Ingreso \A";
      }
    }

    > *:nth-child(6) {
      &:before {
        content: "Egreso \A";
      }
    }

    > *:nth-child(7) {
      &:before {
        content: "Estatus \A";
      }
    }

    > *:nth-child(8) {
      &:before {
        content: "Conciliar \A";
      }
    }

    > *:nth-child(9) {
      &:before {
        content: "Contabilizar \A";
      }
    }
  }
}

.header {
  font-weight: bold;
  text-align: center;
  background: var(--inputBackground);
  padding: 15px;
  color: var(--primaryColor);
}

.containerRows {
  height: 29vh;
  overflow: auto;
  padding: 0 15px 15px 15px;
}

.container {
  border-radius: 0.5em;
  width: calc(100% - 4rem);
  background: var(--inputBackground);
  display: block;
  padding: 1rem 2rem;
  color: var(--primaryColor);
  overflow: auto;
  margin: 0 2rem 1rem 2rem;
}

.anchor {
  cursor: default;
  > div > p {
    margin: 0;
    &:hover {
      font-weight: bold;
    }
  }
  text-decoration: underline;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .header {
    display: none;
  }

  .container {
    background: transparent;
    overflow: none;
    height: auto;
    padding: 0 2rem 1rem 2rem;
    margin: 0;
    width: 100%;
  }

  .containerRows {
    padding: 0;
    height: auto;
    overflow: none !important;
  }

  .row {
    background: var(--primaryBackground);
    margin: 0 0 1rem 0;
    border-radius: 10px;
    padding: 1rem 1.2rem;
  }
}
