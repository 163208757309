.contentModal {
}

.quill {
  background: var(--cardBackground);
  * {
    border-color: var(--primaryColor) !important;
    color:var(--primaryColor) !important;

    border:none !important;
  }
  

  > pre {
    height: 200px;
  }


}
