.container {
  height: 100%;
  overflow: auto;
}

.content {
  margin: 10px 5% 0 5%;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.header {
  display: flex;
  gap: 0 15px;
  width: 100%;
  justify-content: space-between;
}

.overviewHeader {
  display: flex;
  gap: 20px;
  align-items: center;
}
