.bankReportFooter {
  display: flex;
  gap: 0 1rem;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.error {
  * {
    color: red;
    font-weight: bold;
    text-align: center;
  }

  > * {
    &::before {
      content: "🔴";
      margin: 0 0.5rem 0 0;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}
