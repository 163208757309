.customerInfo {
  display: grid;
  grid-template-columns: 40% 15% 25% 10%;
  justify-content: space-between;
  align-items: flex-end;
}

.containerCxc {
  margin: 0;
}

.gridCxc {
  margin: 15px 0;
}

.searchCxc {
  position: sticky;
  left: 0;
}

.headerAssociation {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 15px;
  margin: 15px 0;
  background: var(--cardBackground);
}

.header,
.row {
  display: grid;
  grid-template-columns: 15% 15% 20% 20% 20% 10%;
  justify-content: space-between;
  border:2px solid var(--secondaryBackground);
  border-style: none none solid none;
  padding:10px 0;
}

.header{
  padding: 0;
  margin:10px 0;
}

.row > div:last-child,
.header > div:last-child {
}

.containerCxc {
}

.header {
  font-weight: bold;
  text-align: center;
  align-items: center;
  // border-style: solid solid none solid;
  margin: 20px 0 0 0;
  position: sticky;
  top: 0;
  background-color: var(--primaryBackground);
  color: var(--primaryColor);
}

.row > div {
  // border: 1px solid black;
}

.header > p,
.header > div {
  display: flex;
  align-items: stretch;
  height: 100%;
  align-items: center;
  justify-content: center;

}

// .header {
//   &:last-child {
//     border-style: none;
//   }
// }

.row {
  background: var(--primaryBackground);
  color: var(--primaryColor);

}

.row {
  > :nth-child(1),
  > :nth-child(2) {
    text-align: center;
  }
}

.header p {
  margin: 0;
}

.doubleHeader {
  display: flex;
  flex-direction: column;
}

.doubleHeader div {
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  height: 100%;

}

.doubleHeader {
  div {
    p {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p:first-child {

    }
  }
}

.doubleInfo {
  display: flex;
  justify-content: center;
  p {
    padding: 0 5px;
  }
}

.doubleInfo {

}

.doubleInfo > * {
  width: 50%;
  margin: 0;
  text-align: center;
  align-items: center;
}

.amountStatus {
  display: flex;
  margin: 0 0 20px 0;
}

.amountStatus > div {
  margin: 0 20px 0 0;
}

.headerConcept,
.rowConcept {
  display: grid;
  grid-template-columns: 50% 25% 25%;
}

.headerConcept {
  font-weight: bold;
  text-align: center;
  position: sticky;
  top: 0;
  background: transparent;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.containerTabs {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 20px;
  border-radius: 10px;
  background: var(--cardBackground);
}

.amountsInfo {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background: var(--cardBackground);
  padding: 20px;
  border-radius: 10px;
  color: var(--primaryColor);
}

.amountsInfo > div {
  margin: 0 0 10px 0;
}

.containerBodyAssociation {
  display: grid;
  grid-template-columns: 83.5% 15%;
  justify-content: space-between;
}

.containerAddEgress {
  height: 100%;
  overflow: auto;
}


/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .gridCxc {
    width: 1200px;
    overflow: auto;
  }

  .containerCxc{
    width: 1200px;
    overflow: auto;
  }

  .containerBodyAssociation {
    display: flex;
    flex-flow: column-reverse;
    gap: 1rem 0;
  }

  .customerInfo {
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;

    > * {
      width: 100%;
    }
  }
}
