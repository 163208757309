:root {
  --primaryBackground: #191919;
  --secondaryBackground: #2e2e2e;
  --cardBackground: #222222;
  --primaryColor: #ebebeb;
  --inputBackground: var(--primaryBackground);
  --selectedRow:#e3f2fd;
}

///////////////////////////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic.ttf");
}

@import "../styles/Variables.scss";
@import "../styles/react-tree-menu.scss";

/* Utilities are styles that could be use for other components
like the rectangle white with a shadow, etc.
*/

@import "../styles/Utilities.scss";
@import "../pages/Login/Login.scss"; /////////////////////////////////// [GLOBAL CONFIGURARTIONS] /////////////////
* {
  // font-size: 14px !important;
  font-family: "Century Gothic";
  box-sizing: border-box;
}

section {
  label {
    font-weight: normal;
  }
}

@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../components/individual/buttons/Buttons";
@import "../components/individual/inputs/Inputs";
@import "../components/individual/banerMessage/bannerMessage";
@import "../components/general/userMenu/userMenu";
@import "../components/individual/breadCrums/breadCrums";
@import "../components/individual/Table/Table";
@import "../../node_modules/js-smart-table/dist/styles/main.css";

body {
  padding: 0;
}

// .rdt_Pagination{
//   background: var(--primaryBackground) !important;
//   color:var(--primaryColor) !important;
//   svg{
//     fill:var(--primaryColor) !important;
//   }
// }

.saiko-select__menu{
  z-index: 1000 !important;
}

// Comentario

.tableSize {
  // height: 500px !important;
  max-height: 370px;
  position: relative;

  div:last-child {
    // background-color: red;
    // background-color: red;
    margin: 170px 0 0 0;
    align-items: flex-end;
  }
}

.default-pagination {
  // position:relative;
  bottom: 0;
}
.default-paginationV2 {
  position: relative;
  bottom: 0;
}

.form-control {
  background-color: $bg-input-color !important;
  border: 0.1875em solid $border-input-color !important;
  height: 3.125em !important;
  display: inline-block !important;
  color: $place-holder-color !important;
  margin: 0 !important;
  padding: 0 0 0 45px !important;
}

th {
  text-align: center !important;
}

.spacingOptions {
  height: 50px;
  margin-bottom: 5px;
}

.commentBox {
  display: flex;
  width: 100%;
  justify-content: center;
  .quill {
    width: 93%;
  }
  .ql-toolbar {
    height: 40%;
  }
  .ql-container {
    height: 60%;
  }
}

@import "./react-datepicker.scss";
// @import "./react-tabs.scss";

dialog {
  background: var(--primaryBackground);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  // scrollbar-width: none;
  scrollbar-color: #212121 #e5e5e5;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #e5e5e5;
}

*::-webkit-scrollbar-thumb {
  background-color: #212121;
  border-radius: 10px;
  border: 18px none #ffffff;
}

.ReactModal__Overlay{
  z-index: 500;
  background-color: rgb(0 0 0 / 75%) !important;
  border:none !important;
}

.ReactModal__Content{
  border:none !important;
}

b{
  font-weight: bold;
  color:var(--primaryColor);
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    border: none;
    box-sizing: content-box;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  * {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  * {
    font-size: 11.75px;
  }
}

@media only screen and (min-width: 1600px) {
  * {
    font-size: 16px;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li {
  color: var(--primaryColor);
}

.swal2-popup {
  background: var(--primaryBackground) !important;
}

.swal2-content {
  * {
    color: var(--primaryColor) !important;
  }
}

.rc-tree-list{
  color:var(--primaryColor);
}

.rc-steps-item-title{
  color:var(--primaryColor) !important;
}

.ql-editor.ql-blank::before{
  color:var(--primaryColor) !important;
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: light) {
  :root {
    --borderInput: #e5e5e5;
    --inputBackground: #f9f9f9;
    --inputPlaceholder: #737373;
    --primaryBackground: #f1f1f1;
    --secondaryBackground: #f9f9f9;
    --cardBackground: #ffffff;
    --primaryColor: #0d0d0d;
    --linkColor: rgb(0, 0, 129);

  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {

  :root{
    --linkColor:rgb(148, 212, 255);
    --selectedRow:var(--secondaryBackground);
  }

  main {
    background: var(--secondaryBackground);
  }

  .rc-tooltip-inner{
    border:none;
    background: var(--primaryBackground) !important;
    color:var(--primaryColor) !important;
  }

  :root {
    --red: #ff6e7c;
  }

  a {
    color: #7ba5ff;
    &:hover {
      color: #7ba5ff;
    }
  }
}
