.row,
.header {
  display: grid;
  grid-template-columns: 5.1% 11.5% 5.1% 10.1% 11.5% 5.1% 14.1% 14.1% 14.1% 8%;
  margin: 5px 0;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-weight: bold;
  text-align: center;
}

.containerOverviewEgress{
  color:var(--primaryColor);

  display: flex;
  flex-direction: column;
  gap:20px 0;

  hr{
    border-color:var(--primaryColor);
  }
}