.filter {
  display: flex;
  gap: 10px;
  align-items: flex-end;

  > div:nth-child(1) {
    width: 15%;
  }

  > div:nth-child(2) {
    width: 15%;
  }

  > div:nth-child(3) {
    width: 15%;
  }

  > div:nth-child(4) {
    width: 15%;
  }

  > div:nth-child(5) {
    width: 15%;
  }
}

.row,
.header {
  display: grid;
  align-items: center;
  grid-template-columns: 7% 12% 8% 18% 15% 6.5% 10.5% 5.5% 13.5%;
  justify-content: space-between;
}

.row {
  border: 1px solid #f3f3f3;
  border-style: none none solid none;
  padding: 5px 0;
}

.compoundHeader {
  > p:nth-child(1) {
    grid-column: 1/3;
  }
}

.dateColumn {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  > p:nth-child(1) {
    grid-column: 1/4;
  }
}

.compoundCell,
.compoundHeader {
  display: grid;
  grid-template-columns: 50% 50%;
}

.compoundHeader,
.header {
  text-align: center;
  font-weight: bold;
}

.tableHistoricalContracts {
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 10px;
  * {
    font-size: 0.9rem;
  }
  p {
    margin: 0 !important;
  }
  color: var(--primaryColor);
}

.contentRows {
  height: 40vh;
  overflow: auto;
}

.margin {
  margin: 0 5rem;
}

.headerModule {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  padding: 0 0 1rem 0;
  background: #ffffff;
}

.historical {
  height: 100%;
  overflow: auto;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .tableHistoricalContracts {
    width: 1750px;
    overflow: auto;
  }

  .contentRows {
    height: 100%;
  }

  .margin {
    margin: 0 1rem;
  }

  .filter {
    > *:nth-child(-n + 2),
    > *:nth-child(4) {
      display: none;
    }

    > *:nth-child(3) {
      width: 100% !important;
    }

    > button {
      padding: 0 1rem;
    }
  }
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .headerModule {
    background: var(--secondaryBackground);
  }
}
