.navItem {
  background: var(--cardBackground);
  padding: 0.75rem 1rem;
  color: var(--primaryColor);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin: 0 0 0 0.5rem;
  }
  cursor: pointer;
  border: 0.15rem solid var(--cardBackground);
  transition: all 0.25s ease;
  &:hover {
    background: transparent;
    border-color: var(--primaryColor);
  }
}
