.profileInfo {
  position: sticky;
  top: 0;
  color: var(--primaryColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
  }
  background: var(--secondaryBackground);

  margin: 0 0 1rem 0;
  padding: 1rem 0;
  border: 2.5px solid var(--cardBackground);
  border-style: none none solid none;

  > div:nth-child(1) {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  > *:nth-child(3) {
    display: flex;
    gap: 0 5px;
  }
}

.actionProfile {
  pointer-events: none;
  cursor: pointer;
  width: 40px !important;
  height: 40px !important;
  padding: 10px;
  border: 0.15rem solid var(--cardBackground);
  border-radius: 50%;
  background: var(--cardBackground);
  transition: all 0.25s ease;
  &:hover {
    border-color: var(--primaryColor);
    background: transparent;
  }
}
