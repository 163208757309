.footerAddFiles {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}

.containerFilesQueue {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  width: 100%;
  padding: 0 1rem;
}

.gridUploadFiles {
  display: grid;
  grid-template-columns: 20% 78%;
  justify-content: space-between;
  grid-template-areas:
    "upload queue"
    "ext ext";

  > div {
    height: 40vh;
    overflow: auto;
  }

  > div:nth-child(1) {
    grid-area: upload;
  }

  > div:nth-child(2) {
    grid-area: queue;
  }

  > p {
    grid-area: ext !important;
  }
}

@media (max-width: 767px) {
  .gridUploadFiles {

    h4{
      display: none !important;
    }

    height: 100%;
    > div {
      height: 100%;
    }

    > div:nth-child(2) {
      padding:1rem 0.75rem;
    }

    grid-template-columns: 100%;
    grid-template-rows: 10% 80% 10%;
    grid-template-areas:
      "upload"
      "queue"
      "ext";
  }
}
