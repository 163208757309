.mobileMovement {
  background: var(--primaryBackground);
  border-radius: 15px;
  padding: 1rem;
  margin: 0 0 1rem 0;
  display: none;
}

.dateAndStatus {
  display: flex;
  gap: 1rem;
}



@media (max-width: 767px) {
  .mobileMovement {
    display: block;
  }
}
