.container {
  height: 100%;
  overflow: auto;
  flex-direction: column;
  display: flex;
}

.margin {
  margin: 0 5rem;
}

@media (max-width: 767px) {
  .margin {
    margin:0 1rem;
  }
}
