.container {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.containerSave{
  width: 100%;
  button{
    width: 100%;
  }
}