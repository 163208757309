.money {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 0.1875em solid #e5e5e5;
  border-radius: 5px;
  color: var(--primaryColor);
  height: 3.125em;
  span {
    padding: 0 5px 0 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    border: 0.1875em solid #e5e5e5;
    // border-style: solid none solid none;
    border-style: none;
    display: inline-block;
    color: #737373;
    margin: 0;
    padding: 0;
  }
}

@media (prefers-color-scheme: dark) {
  .money{
    border-radius: 0 ;
    border:3px solid var(--primaryColor);
    border-style: none none solid none;
    border-color: var(--primaryColor);
    background-color: var(--primaryBackground);
    span{
      background: var(--primaryBackground);
    }
    input{
      color:var(--primaryColor);
      border-color: var(--primaryBackground);
    }
  }
}