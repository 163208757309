.documentOptions {
  display: flex;
  justify-content: end;
  margin: 0 0 20px 0;
  * {
    margin: 0 0 0 5px;
  }
}
.documentOptions2 {
  display: flex;
  justify-content: end;
  margin: 0 0 20px 0;
}

.tab {
  overflow: hidden;
  background: transparent;
  border-bottom: 4px solid var(--primaryBackground);
  transition: border 0.15 ease;
  font-weight: bold;
}

.test {
  display: flex;
  justify-content: space-between;
  max-width: 813px;
}

.invalidDocument {
  &::after {
    content: "*";
    color: red;
  }
}

// .sizeTab {
//     min-height: 500px;
//     max-height: 500px;
//     overflow: auto;
//     .tabcontent {
//         min-height: 500px;
//         max-height: 500px;
//         // overflow: ;
//     }
// }

.amounts {
  margin: 20px 0 0 0;
  display: flex;
  div {
    margin: 0 30px 0 0;
  }
}

.tab button,
.tab div {
  display: block;
  text-align: center;
  float: right;
  padding: 10px 24px 8px;
  background: var(--secondaryBackground);

  position: relative;
  cursor: pointer;
  color: var(--primaryColor);
  transition: all 250ms ease;
  border: none;
  outline: none;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  &.active {
    background-color: var(--primaryBackground);
    color: var(--primaryColor);
  }
  &::before {
    right: -24px;
    transform: skew(30deg, 0deg);
    box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 5px,
      inset rgba(255, 255, 255, 0.09) -1px 0;
  }
  &::after {
    left: -24px;
    transform: skew(-30deg, 0deg);
    box-shadow: rgba(0, 0, 0, 0.1) -3px 2px 5px,
      inset rgba(255, 255, 255, 0.09) 1px 0;
  }
  &:hover {
    background-color: var(--secondaryBackground);
    color: var(--primaryColor);
  }
}

.tabcontent {
  display: none;
  // border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 10px 10px;
  width: 100%;
  animation: fadeEffect 1s;
}

/* Go from zero to full opacity */

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.flotante {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.boton-flotante {
  align-items: flex-end;
  margin: 15px 0 0 0;
  height: 3.5rem;
}

.bodyPDF {
  display: inline-block;
  align-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.myPdftable {
  border-collapse: collapse;
  width: max-content;
}

.header-headerText {
  font: 15px "Arial";
  line-height: 15px;
  text-align: center;
  height: min-content;
}

.tableClass {
  align-items: center;
  // border: 1px solid black;
  width: 720px;
}

.header-tableHeader {
  width: 720px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.thead {
  background: #acb9ca;
  text-align: center;
  padding: 0px;
  // border: 1px solid black;
}

.thead-partidas {
  text-align: center;
  padding: 0px;
  // border: 1px solid rgba(0, 0, 0, 0.116);
  border: 1px solid rgba(223, 223, 223, 0.055);
}

.tdata-partidas {
  // border: 1px solid rgba(0, 0, 0, 0.116);
  border: 1px solid rgba(223, 223, 223, 0.055);
  text-align: center;
  font: 15px "Arial body";
  line-height: 5px;
}

.tdata {
  font: 15px "Arial";
  line-height: 15px;
  margin: 5px 5px 0px 0px;
  padding: 5px;
}
.tdataV2 {
  font: 15px "Arial";
  line-height: 12px;
  margin: 5px 5px 0px 0px;
  padding: 5px;
}
.tdata2 {
  font: 15px "Arial body";
  line-height: 15px;
  margin: 5px 5px 0px 0px;
  padding: 5px;
}

.tdata-header-partida {
  text-align: center;
  font: 15px "Arial body";
  line-height: 17px;
  border: 1px black solid;
}

.theadText {
  text-align: center;
  // font: 18px "Arial body";
  font: 15px "Arial" bold;
  line-height: 16px;
  margin: 1px;
  white-space: nowrap;
}

.theadText-partida {
  text-align: center;
  font: 18px "Arial body";
  line-height: 16px;
}

.tinyTable {
  // border: 1px solid rgba(0, 0, 0, 0.116);
  border: 1px solid rgba(223, 223, 223, 0.055);
}

.imageSaiko {
  width: 197.76px;
  height: 56.64px;
}

.partidasTable {
  align-items: center;
  border: 1px solid rgba(223, 223, 223, 0.055);
  width: 720px;
}

.contract {
  width: 13%;
  margin: 0 1% 0 0;
  display: inline-block;
}

/*
-----------------------NUEVA SECCION PARA EL PDF--------------------------------
*/

.ResumentPartidas {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  // border: 1px solid rgba(223, 223, 223, 0.055);
  width: 100%;
  max-width: 720px;
  height: min-content;
  // background-color: #acb9ca;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}
.ResumenPartidasColumn {
  min-width: 102.85px;
  max-width: 180px;
  width: 100%;
  height: min-content;
  // border: 1px black solid;
}
// .ResumenPartidasColumnResumen {
//   max-width: 720px;
//   width:100% ;
//   height: min-content;
//   // border: 1px black solid;
// }
.ResumenPartidasHeader {
  text-align: center;
  text-decoration: solid;
  font: 15px "Arial" bold;
  line-height: 16px;
  margin-top: 1px;
  margin-bottom: 1px;
  width: 100%;
  font-weight: bold;
  background-color: #acb9ca;
}
.ResumenPartidasText {
  text-align: center;
  font: 15px "Arial";
  line-height: 17px;
  margin: 0%;
  margin-top: 5px;
  // background-color: white;
  // border-top: 1px black solid;
  width: 100px;
}
.ResumenPartidasMoneyHeader {
  text-align: center;
  // text-decoration: solid;
  background-color: #acb9ca;
  font: 15px "Arial" bold;
  line-height: 16px;
  margin-top: 1px;
  margin-bottom: 1px;
  width: 102px;
  // font-weight: bold;
}
.ResumenPartidasMoneyText {
  text-align: center;
  font: 15px "Arial";
  line-height: 17px;
  margin: 0%;
  margin-top: 5px;
  background-color: white;
  // border-top: 1px black solid;
  width: 102px;
}
.backgroundHeader {
  background-color: #acb9ca;
}

.PartidasContainer {
  display: flex;
  // border: 1px solid rgba(223, 223, 223, 0.055);
  // width: 720px;
  justify-content: left;
  align-items: left;
  // background-color: #acb9ca;

  .textBold {
    font-weight: bold;
  }
  .PartidasHeader {
    text-align: center;
    // text-decoration: solid;
    // font-weight: bold;
    font: 15px "Arial" bold;
    line-height: 16px;
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .PartidasText {
    text-align: center;
    justify-self: center;
    font: 15px "Arial body";
    line-height: 17px;
    margin-bottom: 1px;
    // border-top: 1px black solid;
  }
  .PartidasTextDescription {
    text-align: left;
    justify-self: left;
    font: 15px "Arial";
    line-height: 17px;
    margin-bottom: 1px;
    // border-top: 1px black solid;
  }
  .PartidasTextMoney {
    text-align: right;
    justify-self: right;
    font: 15px "Arial";
    line-height: 17px;
    margin-bottom: 1px;
    // border-top: 1px black solid;
  }
  .PartidasClaveHeader {
    width: 10px;
    // font-weight: bold;
  }
  .PartidasCantidadHeader {
    width: 75px;
    font-weight: bold;
  }
  .PartidasCantidadText {
    width: 75px;
  }
  .PartidasDescriptionHeader {
    width: 441px;
    font-weight: bold;
  }
  .PartidasDescriptionText {
    width: 441px;
  }
  .PartidasMoneyHeader {
    width: 102px;
    font-weight: bold;
  }
  .PartidasMoneyText {
    width: 102px;
    text-align: right;
    justify-content: right;
    align-items: right;
  }
  .PartidasTextTEST {
    text-align: center;
    justify-self: center;
    // text-decoration: solid;
    font: 15px "Arial" bold;
    line-height: 17px;
    margin-bottom: 1px;
    // font-weight: bold;
  }
  .espacioBlanco {
    width: 720px;
    height: 17px;
    // border-right: 1px black solid;
    // border-left: 1px black solid;
    // border-bottom: 1px black solid;
  }
  .espacioBlanco2 {
    width: 516px;
    height: 19px;
    // border-right: 1px black solid;
    // border-left: 1px black solid;
    // border-bottom: 1px black solid;
  }
  .espacioBlanco3 {
    width: 720px;
    height: 25px;
    // border-right: 1px black solid;
    // border-left: 1px black solid;
    // border-bottom: 1px black solid;
  }
  .ResumenPartidasColumnV2 {
    width: min-content;
    height: min-content;
    // border-bottom: 1px black solid;
    // border-right: 1px black solid;
  }
}
