.availableBrowsers {
  color: var(--primaryColor);
  font-weight: bold;
  margin: 0 0.5rem;
  svg {
    width: 1.5rem;
    margin: 0 0.5rem 0 0;
    height: 1rem;
  }

  p {
    text-align: center;
  }

  > div {
    display: flex;
    justify-content: center;
  }
}

.containerAvailableBrowsers {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  hr {
    border: none;
    color: var(--primaryColor);
    width: 100%;
    height: 2px;
    background-color: var(--primaryColor);
  }
}

.appInstallation {
  text-align: center;
  margin: 2rem 0 0 0;
  padding: 1rem;
  margin: 2rem 15% 0 15%;
  text-decoration: underline;
  border-radius: 10px;
  transition: all 0.25s ease;
  color: rgb(0, 0, 129);
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.deviceType {
  &:before {
    content: "de escritorio";
  }
}

@media (prefers-color-scheme: light) {
  input {
    color: var(--primaryColor);
  }

  label {
    color: var(--primaryColor);
  }

  .inputUser {
    margin: 0 0 1rem 0;
    > div {
      span {
        color: var(--primaryColor);
      }
    }

    input {
      background-color: var(--secondaryBackground);
      border: 0.188rem solid var(--primaryColor);
    }
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .deviceType {
    &:before {
      content: "de móvil";
    }
  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .appInstallation {
    color: rgb(148, 212, 255);
  }
}
