.password {
  button {
    background-color: transparent;
    border: none;
    width: 3.438rem;
    height: 100%;
  }
  svg {
    padding: 25%;
    pointer-events: none;
  }
  > div {
    display: flex;
    border-radius: 0.5rem 0.5rem 0 0;
    justify-content: space-between;
    align-items: center;
    border-style: none none solid none !important;
    input {
      border: none !important;
      width: 90%;
    }
    div {
      width: 10%;
    }
  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .password {
    button {
      transition: background 0.35s ease;
      &:hover {
        background: var(--primaryBackground);
      }
    }

    svg {
      color: var(--primaryColor);
    }

    > div {
      border: 0.188rem solid var(--primaryColor);

      background-color: var(--secondaryBackground);
    }
  }
}

//////////////////////// LIGHT MODE ////////////////////////


@media (prefers-color-scheme: light) {
  .password {
    button {
      transition: background 0.35s ease;
      &:hover {
        background: var(--primaryBackground);
      }
    }

    svg {
      color: var(--primaryColor);
    }

    > div {
      border: 0.188rem solid var(--borderInput);

      background: var(--F9F9F9);
    }
  }
}
