.header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  background: #ffffff;
  z-index: 2;

}

/////////////////////////////////////////////// MODO OSCURO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .header {
    background: var(--secondaryBackground);
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .header {
    padding: 0 0 1rem 0;

  }
}
