.row,
.header {
  display: grid;
  grid-template-columns: 10% 10% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%;
  justify-content: space-between;
  align-items: center;
}

.fillData {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2rem;
  margin: 1rem 0;
  background: var(--primaryBackground);
  border-radius: 20px;
}

.row {
  padding: 0 0 1rem 0;

  p {
    white-space: nowrap; /* Prevents the text from wrapping to the next line */
    overflow: hidden; /* Hides the overflowed content */
    text-overflow: ellipsis;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.header {
  color: var(--primaryColor);
  text-align: center;
  font-weight: bold;
  justify-content: space-between;
  padding: 10px 0;
  background: var(--primaryBackground);
}

.container,
.noResult {
  padding: 20px;
  background: var(--primaryBackground);
  border-radius: 10px;
}

.noResult {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.containerRows {
  // height: 35vh;
  overflow: auto;
  padding: 10px 0;
  input {
    height: 30px !important;
    border-style: solid !important;
    border-radius: 10px !important;
    background: var(--secondaryBackground);
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .container {
    width: 1750px;
  }
}
