.filterOptions {
  display: grid;
  grid-template-columns: repeat(4, 24%);
  justify-content: space-between;
  width: 75%;
  margin: 0 15px 0 0;
}

.filterNoAccounting,
.filterAccounting {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 0 0.7rem;
}

.filterNoAccounting {
  > *:nth-child(1) {
    width: 20%;
  }
}

.filterAccounting {
  > *:nth-child(1) {
    width: 20%;
  }
}

.actions {
  width: 25%;
  display: flex;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .filterNoAccounting {
    > button {
      padding: 0 1rem !important;
    }

    > *:nth-child(1) {
      width: 100%;
    }

    > *:nth-child(n + 2) {
      display: none !important;
    }

    > *:nth-child(n + 6) {
      display: block !important;
    }
  }

  .filterAccounting {
    > *:nth-child(-n + 3) {
      display: none !important;
    }

    > *:nth-child(4) {
      width: 100%;
    }

    > button {
      padding: 0 1rem !important;
    }
  }
}
