.bannerContainer {
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    .animatedMessage {
        padding: 5px;
        animation-duration: 15s;
        animation-name: sliden;
        animation-iteration-count: infinite;
        // animation-direction: alternate;
    }
}

@keyframes sliden {
    0% {
        transform: translateX(100%);
    }
    50% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
    // from {
    //     margin-left: 100%;
    //     width: 300%;
    // }
    // to {
    //     margin-left: -69%;
    //     width: -100%;
    // }
}