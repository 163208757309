.filter {
  display: flex;
  gap: 0 1rem;
  align-items: flex-end;
}

.table {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  padding: 15px;
  background: var(--primaryBackground);
}

.withOutDetails,
.withDetails,
.rowWithoutDetails {
  display: grid;
  grid-template-columns: 40% 60%;
  text-align: center;
  align-items: center;
  > p {
    margin: 0;
    padding: 0.5rem 0;
  }
}

.withDetails {
  grid-template-columns: 30% 10% 60%;
  background: var(--cardBackground);
}

.rowWithoutDetails {
  background: var(--cardBackground);

  > p {
    justify-content: flex-start !important;
    text-align: left;
  }
}

.headerResidues,
.rowResiduesInvoice {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  > p {
    margin: 0;
    padding: 0.5rem 0;
  }

  > *:nth-child(1) {

    // border: 1px solid rgb(245, 245, 245);
    // border-style: none none solid none;
  }
}

.rowResiduesInvoice {
  > *:nth-child(1) {
    grid-column: 1/2;
    border-style: none;
  }
}

.module {
  height: 100%;
  overflow: auto;
}

.containerRowsOldResidues {
  height: 40vh;
  overflow: auto;
}

.headerModule {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  padding: 0 0 1rem 0;
  background: #ffffff;
}

.margin {
  margin: 0 5rem;
}

/////////////////////////////////// [RESPONSIVE] ////////////////////////////////////////////////////////////

@media (max-width: 767px) {
  .filter {
    max-width: 100% !important;
    max-height: 100% !important;
    border: none;
    box-sizing: content-box;
    padding: 0;

    > *:nth-child(2),
    > *:nth-child(3) {
      display: none !important;
    }

    > button {
      padding: 0 1rem !important;
    }
  }

  .margin {
    margin: 0 1rem;
  }

  .table {
    width: 1750px;
  }
}

/////////////////////////////////////////////// MODO OSCURO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .headerModule {
    background: var(--secondaryBackground);
  }
}
