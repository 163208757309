.filters,
.filterItem {
  display: flex;
  gap: 10px;
  align-items: end;
}

.filters {
  justify-content: space-between;
}

.options{
  height: 3.125rem;
  display: flex;
  gap:10px;
}

.search{
  width: 40%;
}

.status,.folio{
  width: 20%;
}


.leftFilter{
  width: 80%;
}

.container{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:10px;
  overflow: auto;
}