.filter {
  display: flex;
  height: 40vh;
  align-items: flex-start;
  justify-content: space-between;
  > div:nth-child(1) {
    width: 30%;
  }
  > div:nth-child(2) {
    width: 65%;
  }
}

.table {
  margin: 1rem 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
  border-radius: 10px;
}

.row,
.header {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 6.65% 6.65% 6.65% 6.65% 6.65% 6.65%;
}

.header {
  font-weight: bold;
  text-align: center;
}

.containerRows {
  height: 40vh;
}

.containerExcel {
  display: flex;
  justify-content: flex-end;
}

////////////////////////////////////////////////////////////////////////////////////

@media (max-width: 767px) {
  .filter {
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    gap: 1rem 0;
    > * {
      width: 100% !important;
    }
  }
}
