.formProyects {
  display: flex;
  gap: 0 10px;
  align-items: flex-end;

  > div:nth-child(2),
  > div:nth-child(3) {
    min-width: 300px;
  }
}

.proyects {
  display: flex;
  flex-direction: column;
  gap: 15px 0;

  > *:nth-child(n + 2) {
    margin: 0 5%;
  }
}
