.parameters {
  display: grid;
  overflow-y: auto;
  justify-content: space-between;
  overflow: auto;
  grid-template-columns: 30% 67%;
  height: 90%;
  padding: 2rem;

  > *:nth-child(2) {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 2rem;
  }
}

.containerParameters {
  height: 100%;
  overflow: auto;

  > *:nth-child(1) {
    position: sticky;
    top: 0;
    padding: 0 0 1rem 0;

    button {
      display: none;
    }
  }
}

.mobileGoBack {
  display: none;
}

.mobileParameters {
  display: none;
}

.mobileOptionSelected {
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.backOptionMobile {
  padding: 0 1rem;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .mobileParameters {
    display: block;
    height: 85%;
    padding: 0 1rem;

    > *:nth-child(1) {
      height: 100%;
    }
  }

  .parameters {
    display: none;
  }

  .containerParameters {
    > *:nth-child(1) {
      background: var(--secondaryBackground);
      // padding:0 1rem 1rem 1rem;
      button {
        margin: 1rem 0 0 0;
        display: block;
        width: 100%;
      }
    }
  }
}
