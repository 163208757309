.mobileFilter {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.pillFilter {
  background: var(--primaryBackground);
  color: var(--primaryColor);
  padding: 0 1rem;
  height: 3.125rem;

  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
  display: none !important;
}

.containerPills {
  gap: 0 0.5rem;
  display: none;
}

.mobileOptions{
  display: flex;
  gap:0 0.5rem;
  margin:0.5rem 0;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .containerPills {
    display: flex;
  }

  .pillFilter {
    display: flex;
  }
}
