$darkModeGreen: #9ee79e;
$lightModeGreen: #5d985d;

.btn,
.activeNotifications,
.disactiveNotifications {
  border: none;
  background-color: transparent;
  position: relative;
  transition: 0.14s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 0 0 0 5px;
  background: var(--primaryBackground);

  border: 1px solid var(--primaryBackground);
  &:hover {
    background: transparent;
    border-color: var(--primaryColor);
  }
  svg {
    color: var(--primaryColor) !important;
  }
  position: relative;
}

.activeNotifications {
  svg {
    color: $lightModeGreen !important;
  }
  border-color: var(--primaryBackground);
}

.disactiveNotifications {
  svg {
    color: var(--red);
  }
  border-color: var(--primaryBackground);
}

////////////////////////// [DARK MODE]  //////////////////////////

@media (prefers-color-scheme: dark) {
  .activeNotifications,
  .disactiveNotifications {
    background: var(--cardBackground) !important;
    border-color: var(--cardBackground) !important;
  }
}

////////////////////////// RESPONSIVE  //////////////////////////

@media (max-width: 767px) {
  .activeNotifications,
  .disactiveNotifications {
    width: 40px;
    height: 40px;
  }
}
