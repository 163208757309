.options {
  display: flex;
  align-items: center;
  margin: 20px 0;
  min-height: 50px;
  > a,
  > button,
  > div {
    margin: 0 10px 0 0;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .options{
    display: none;
  }
}