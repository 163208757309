.containerOptions {
  height: 3.125rem;
  margin: 0 0 10px 0;
  display: flex;
  gap: 0 10px;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .containerOptions {
    display: none;
  }
}
