.header,
.row {
  display: grid;
  grid-template-columns: repeat(9, 10%);
  justify-content: space-between;
}

.header {
  font-weight: bold;
  text-align: center;
}

.row {
  font-weight: normal !important;
  padding: 0.7rem 0;

  > p {
    margin: 0;
  }

  transition: all 0.25s ease;

  &:hover{
    background: var(--secondaryBackground);
  }
}

.moreInfo {
  text-decoration: underline;
  margin: 0;
  text-align: center;
}

.containerRows {
  height: 35vh;
  overflow: auto;
}

.table {
  padding: 15px;
  border-radius: 10px;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .table {
    background: transparent;
    padding: 0;
  }

  .row,
  .header {
    display: none;
  }

  .containerRows {
    height: 100%;
  }
}
