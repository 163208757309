.module {
  margin: 20px 5% 0 5%;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

.container {
  height: 100%;
  overflow: auto;

  details > div > div > div > div {
    table-layout: fixed;
  }

  details{
    summary + div{
      max-height: 100% !important;
    }
  }

  .rdt_TableRow{
    display: grid !important;
    grid-template-columns: repeat(14,7.132%) !important;
  }
}

.header {
  display: grid;
  grid-template-columns: repeat(14, 7.1%);
  justify-content: center;
  p {
    color:var(--primaryColor);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin:0;
    padding: 5px 0;
  }

  p:nth-child(1) {
    grid-column: 5/8;
  }

  p:nth-child(2) {
    grid-column: 8/11;
  }

  p:nth-child(3) {
    grid-column: 11/14;
  }
}


@media (prefers-color-scheme: dark) {
  .header {
    background: #424242;
  }
}
