.form {
  border-radius: 10px;
  width: 100%;
  button{
    width:100%;
    margin:10px 0 ;
  }
  label{
    margin:15px 0 0 0 ;
  }
}

.containerForm{
  
  justify-content: center;
  
}