.filterSearch {
  display: flex;

  > div:nth-child(1),
  > div:nth-child(2) {
    width: 10%;
  }

  > div:nth-child(3) {
    width: 40%;
  }

  gap: 10px;
  align-items: flex-end;
}

.optionsReport {
  margin: 10px 0;
}

.header {
  position: sticky;
  top: 0;
  padding: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.content,
.filterSearch {
  margin: 0 5rem;
}

@media (max-width: 767px) {
  .content,
  .filterSearch {
    overflow: auto;
    margin: 0 1.5rem;
  }

  .filterSearch {
    > *:nth-child(-n + 2) {
      display: none;
    }

    > div:nth-child(3) {
      width: 100%;
    }
  }
}
