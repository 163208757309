.table {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 15px;
  border-radius: 10px;
  background: var(--primaryBackground);
}

.row,
.header {
  display: grid;
  grid-template-columns: 10% 45% 15% 15% 10%;
  justify-content: space-between;
}

.header {
  font-weight: bold;
  text-align: center;
}

.containerRows {
  height: 40vh;
  overflow: auto;
}

.itemConcepts {
  span:nth-child(1) {
    margin: 0 0 0 0.5rem;
  }

  span:nth-child(n + 1) {
    &:after {
      content: ",";
    }
  }

  span:last-child {
    &:after {
      content: "";
    }
  }
}

.row {
  > *:last-child {
    text-align: center;
  }
}
