.pill {
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 0.85rem;
  white-space: nowrap;
  p{
    margin:0;
  }
}
