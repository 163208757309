.checkIn {
  color: var(--primaryColor);
  p{
    margin:0;
  }

  > div{
    margin: 0 0 30px 0;
  }
  background: var(--cardBackground);
  padding: 20px;
  border-radius:10px;
  height: 100%;
}

.itemCheckIn {
  display: flex;
  font-weight: bold;
  align-items: center;
  gap: 0 10px;
  p {
    margin: 0;
  }
  margin:0 0 10px 0;
}
