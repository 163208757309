.contentTicket {
  overflow: auto;
}

.ticket {
  // overflow: auto;
  p {
    margin: 0;
  }
  color: var(--primaryColor);
  padding: 10px;
  width: 100%;

  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  h2 {
    font-size: 1.2rem;
    margin: 0;
  }
}

.containerTitle {
  display: flex;
  justify-content: space-between;
  img {
    width: 80px;
    &:hover {
      cursor: pointer;
    }
  }
}

.ticketOptions {
  display: flex;
  justify-content: space-between;
}

.ticketOptions > div > button {
  margin: 0 10px 0 0;
}

.dates {
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  .date:first-child {
    margin-left: 0;
    padding-left: 0;
  }
}

.date {
  display: flex;
  flex-direction: column;
  border: 1.5px solid var(--cardBackground);
  border-style: none solid none none;
  margin: 0.313rem 0.313rem 0.313rem 0.313rem;
  padding: 0 0.313rem;
}

.action {
  border: 0.15rem solid var(--cardBackground);
  background-color: var(--cardBackground);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: all 0.25s ease;
  &:hover {
    background: transparent;
    border-color: var(--primaryColor);
    // background-color: rgb(230, 230, 230);
  }
}

.notificationsToggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .ticket {
    hr {
      border-color: var(--cardBackground);
    }
  }
}

//////////////////////// RESPONSIVE ////////////////////////

@media (max-width: 767px) {
  // .dates {
  //   display: flex;

  //   flex-direction: column;
  //   .date {
  //     margin-left: 0;
  //     padding-left: 0;
  //     border-style: none none solid none;
  //   }

  //   .date:last-child {
  //     border-style: none;
  //   }
  // }

  .containerTitle{
    &+hr{
      display: none;
    }
    display: none;
  }

  .ticketOptions {
    display: none;
  }
}
