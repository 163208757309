.dateOverview {
  display: flex;
  align-items: center;
  margin:0 0 20px 0;
  p {
    margin: 0;
  }
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  > div:nth-child(2){
    text-align: right;
    justify-content: flex-end;
  }
}
