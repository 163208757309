.autocompleteEmail {
  &:invalid {
    border-color: 1px solid var(--red);
  }

  position: relative;
  display: flex;
  flex-direction: column;
}

.optionsFound {
  margin:10px 0 0 0;
  position: absolute;
  max-height: 50svh;
  overflow: auto;
  z-index: 10;
  top: 6rem;
  border: 2px solid var(--primaryColor);
  > div {
    display: flex;
    padding: 10px;
    gap: 0 10px;
    border: 2px solid var(--primaryColor);
    border-style: none none solid none;
    transition: background 0.2s ease;
    &:hover {
      background: var(--secondaryBackground);
      cursor: pointer;
    }
  }
}

.profilePicture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 999999999999px;
  margin: 0 0 0 10px;
  background: var(--secondaryBackground);
  svg {
    width: 20px;
  }
}

.optionCurrenList {
  margin:10px 0 0 0;

  align-items: center;
}

.optionCurrenList,
.optionsFound {
  background: var(--primaryBackground);
  border-radius: 10px;
  p {
    margin: 0;
  }
}

.contentCurrentList {
  display: flex;
  gap: 0 10px;

  transition: background 0.15s ease;

  button {
    border: none;
    background: none;
    padding: 0 10px;
    height: 100%;
    &:hover {
      background: var(--red);
    }
  }

  svg {
    width: 20px;
  }
}

.infoEmail {
  padding: 10px;
}

.currentEmails {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
