.filter {
  display: flex;
  align-items: center;
  gap: 10px;
  align-items: flex-end;
}

.content{
    display: flex;
    gap:10px;
    justify-content: center;
    color:var(--primaryColor);
}