.concepts {
  margin: 0;
  span {
    &:after {
      content: ",";
    }
  }
  span:last-child {
    &:after {
      content: "";
    }
  }
}

.containerConcepts {
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
}
