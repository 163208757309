$backgroundHeaders: #e9e9e9;

:root {
  --cashFlowHeader: #080808;
}

.filters {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.error {
  text-align: center;
  margin: 50px;
  svg {
    color: red;
    max-height: 35px;
  }
}

.headerBanks {
  background: var(--cashFlowHeader);
  display: grid;
  grid-template-columns: 8% 37% 55%;
  font-weight: bold;
  text-align: center;
  color:white;
  > div:nth-child(1) {
    grid-column: 1/3;
    grid-row: 1/2;
  }
}

.headerDayBanks {
  display: grid;
  text-align: center;
  grid-template-columns: repeat(5, 20%);
  text-align: center;
  grid-column: 3/4;
  grid-row: 1/3;
  > div:nth-child(1) {
    grid-column: 1/6;
  }
}

.header {
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.row,
.totalBankAccounts,
.details,
.header {
  display: grid;
  grid-template-columns: 8% 37% 11% 11% 11% 11% 11%;
  justify-content: space-between;

  p {
    margin: 0;
  }
}

.contentTable {
  overflow: auto;
  max-height: 45vh;
  padding: 0 4rem;
}

.header {
  font-weight: bold;
  text-align: center;
}

.row {
  > p:nth-child(1) {
    text-decoration: underline;
    cursor: default;
    &:hover {
      font-weight: bold;
    }
  }
}

.row {
  > p:nth-child(n + 3) {
    background-color: rgb(199, 199, 199);
    text-align: center;
  }

  > p:nth-child(4) {
    background-color: white;
    text-align: right;
  }
}

.row,
.totalBankAccounts,
.details {
  > p {
    text-align: right;
  }

  > p {
    padding: 5px;
  }

  > p:nth-child(1) {
    text-align: left;
  }

  > p:nth-child(2) {
    text-align: left;
  }
}

.totalGrouped {
  margin: 10px 0 0 0;
  position: sticky;
  bottom: 0;
}

.totalBankAccounts,
.totalDetails {
  border: 2px solid rgb(230, 230, 230);
  border-style: solid none solid none;
  > p:nth-child(2) {
    text-align: right;
    font-weight: bold;
  }
  // background: white;
  margin: 0 0 15px 0;
}

.tableCashFlow {
  border-radius: 10px;
  padding: 10px;
  // margin: 0 20px;
  margin: 0 0 1rem 0;
  background: var(--primaryBackground);

  p {
    white-space: nowrap; /* Prevent line breaks within the text */
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis;
  }
}

.containerCashFlow {
  height: 100%;
  overflow: auto;
}

.detailsSocialReason,
.details {
  display: grid;
  grid-template-columns: 45% 11% 11% 11% 11% 11%;
}

.detailsSocialReason {
  background: var(--cashFlowHeader);
  font-weight: bold;
  > p {
    margin: 0;
    color:white;
  }
  align-items: center;
  text-align: center;
  > p:nth-child(2) {
    &::after {
      content: "\A Ayer";
      white-space: pre;
    }
  }
  > p:nth-child(3) {
    &::after {
      content: "\A Hoy";
      white-space: pre;
    }
  }
}

.details {
  > p {
    text-align: right !important;
  }

  > p:nth-child(1) {
    text-align: left !important;
  }
}

.typeOfDetails {
  text-transform: uppercase;

  &:after {
    content: "-";
    margin: 0 10px;
  }
}

.totalDetails {
  display: grid;
  grid-template-columns: 45% 11% 11% 11% 11% 11% !important;

  p {
    margin: 0;
    padding: 10px 5px;
  }

  > p:nth-child(1) {
    font-weight: bold;
    text-align: right;
  }

  > p:nth-child(n + 2) {
    font-weight: normal;
    text-align: right;
  }
}

.headerTableCashFlowV1 {
  display: grid;
  grid-template-columns: 8% 7% 30% 55%;
  font-weight: bold;
  background: var(--cashFlowHeader);
  text-align: center;
  > p {
    color:white !important;
    margin: 0;
    padding: 5px 0;
  }
}

.rowTableCashFlowV1 {
  display: grid;
  grid-template-columns: 8% 7% 30% 9.17% 9.17% 9.17% 9.17% 9.17% 9.17% !important;
  > p {
    margin: 0;
    padding: 5px 0;
    color: var(--primaryColor);
  }
  > p:nth-child(n + 4) {
    text-align: right;
  }
}

.tableCashFlowV1,
.rowCashFlowV1,
.totalCashFlow {
  display: grid;
  grid-template-columns: 45% 9.17% 9.17% 9.17% 9.17% 9.17% 9.17% !important;
}

.tableCashFlowV1 {
  text-align: center;
  font-weight: bold;
  background: var(--cashFlowHeader);
  p {
    padding: 5px 0;
    color:white !important;
    margin: 0;
  }
}

.totalCashFlow {
  border: 2px solid rgb(230, 230, 230);
  border-style: solid none solid none;
  p {
    margin: 0;
    padding: 5px 0;
  }
  > p:nth-child(1) {
    font-weight: bold;
    text-align: right;
  }

  > p:nth-child(n + 2) {
    text-align: right;
  }
}

.rowCashFlowV1 {
  > p:nth-child(n + 2) {
    text-align: right;
  }
}

.topCashFlow{
  padding:0 0 1rem 0;
  position: sticky;
  top:0;
  background: white;
}

.filtersContainer{
  padding: 0 4rem;
}

////////////////////////////////// MODO OSCURO //////////////////////////////////

@media (prefers-color-scheme: dark) {
  :root {
    --cashFlowHeader: #080808;
  }

  .topCashFlow{
    background: var(--secondaryBackground);
  }

  .row {
    > p:nth-child(n + 3) {
      background-color: var(--cashFlowHeader);
      text-align: center;
    }
  
    > p:nth-child(4) {
      background-color: transparent;
      text-align: right;
    }
  }
  
  
}

////////////////////////////////// RESPONSIVE //////////////////////////////////

@media (max-width: 767px) {
  .filters {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    justify-content: space-between;
  }

  .contentTable{
    padding:0 1rem;
  }

  .tableCashFlow{
    width: 2000px;
    overflow: auto;
  }

  .filtersContainer{
    padding: 0 1rem;
  }

  .contentTable {
    height: auto;
    max-height: none;
  }
}
