.excel {
  p {
    margin: 0 0 0 10px;
    color:#ffffff !important;
  }
  &:hover {
    background-color: hsl(150, 78%, 30%);
  }
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #007139;
  color: white;
  svg {
    width: 35px;
    color: white;
  }
}

.fileName {
  display: flex;
  align-items: center;
  background-color: white;
  color: #007139;
  border: 2.5px solid #007139;

  margin: 0;
  padding: 0 5px;
  width: 200px;
}

.container {
  display: flex;
}
