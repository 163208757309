.documentsRelated {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // background: var(--cardBackground);
}

.documentsRelated > div {
  width: 48%;
  // background: var(--cardBackground);
}

.containerDocsRelated {

  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .documentsRelated > div {
    width: 100%;
  }

  .documentsRelated {
    flex-direction: column;
  }
}
