.row,
.header {
  display: grid;
  grid-template-columns: 15% 27% 27% 27%;
  align-items: center;
  p {
    margin: 0;
  }
  padding: 7.5px 0;
}

.header {
  font-weight: bold;
  text-align: center;
}

.row {
  &:hover {
    background-color: #80808029;
  }

  font-weight: normal;
  p {
    margin: 0;
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:last-child {
      text-align: right;
    }
  }
}

@media (max-width: 767px) {
  .row,
  .header {
    grid-template-columns: 10% 45% 45%;

    > *:nth-child(3) {
      display: none;
    }
  }
}
