.bodyModal {
  height: 50vh;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overviewItem {
  display: grid;
  grid-template-columns: 40% 10% 40%;
  gap: 0 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;

  svg {
    height: 40px;
  }
}

.overviewHint {
  color: var(--blue);
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  padding: 5px;
}

.containerOverview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px 0;

  > *:not(:last-child) {
    border: 1px solid var(--primaryColor);
    border-style: none none solid none;
  }
}
