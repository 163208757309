.header,
.row {
  display: grid;
  // grid-template-columns: repeat(7, 14.1%);
  grid-template-columns: 5.1% 19.1% 5.1% 14.1% 5.1% 14.1% 14.1% 14.1% 8%;
  margin: 5px 0;
  justify-content: space-between;
}

.header {
  font-weight: bold;
  text-align: center;
}

.row {
  font-weight: normal;
  transition: all 0.25s ease;
  padding: 15px 0;
  input {
    display: block !important;
  }
  p {
    text-align: right;
    margin: 0;
  }

  p:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p:nth-child(2),
  p:nth-child(3),
  p:nth-child(4),
  p:nth-child(5) {
    text-align: center;
  }
}

.summary {
  padding: 15px 20px;

  text-align: left;
}

.contentAssociations {

}


@media (max-width: 767px) {
  .contentAssociations{
    width: 1000px;
  }
}