.mobileItem {
  display: none;
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 15px;
  margin: 0 0 1rem 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.amount,.dates {
  display: flex;
  gap: 0 1rem;
}

////////////////////////////////////////////  [RESPONSIVE]  ////////////////////////////////////////////

@media (max-width: 767px) {
  .mobileItem {
    display: flex;
    flex-direction: column;
    gap:1rem 0;
  }
}
