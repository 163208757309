.row,
.header {
  display: grid;
  grid-template-columns: 20% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
  justify-content: space-between;
  align-items: center;
  * {
    margin: 0;
  }
}

.header {
  font-weight: bold;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px 0;
}

.row {
  margin: 0 0 10px 0;
}

.container {
  overflow: auto;
  height: 40vh;
  padding: 0 15px;
}
