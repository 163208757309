.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60vh 1fr;
}

.folio {
  text-decoration: underline;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  &:hover{
    font-weight: bold;
  }
}
