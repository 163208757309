.header,
.row {
  display: grid;
  grid-template-columns: 10% 10% 25% 15% 12% 12% 10%;
  justify-content: space-between;
}

.moreInfo{
  text-decoration: underline;
  text-align: center;
  margin:0;
  &:hover{
    font-weight: bold;
  }
}

.header {
  font-weight: bold;
  text-align: center;
}

.table {
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 15px;
  p{
    margin:0;
  }
}

.row{
  padding: 0.75rem 0;
  p{
    margin:0;
  }
}

.containerRows {
  height: 35vh;
  overflow: auto;
}
