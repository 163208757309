.filter {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  align-items: flex-end;
  > div:nth-child(1) {
    width: 35%;
  }
}

.container {
  height: 100%;
  overflow: auto;

  > *:nth-child(n + 2) {
    margin: 0 2.5%;
  }
}

.containerOptions {
  min-height: 3.125rem;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
