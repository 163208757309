.bankButtonsContainer > button {
  margin: 10px 10px 10px 0;
}
.PositionForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  @media (max-width: 767px) {
    grid-template-columns: 100%;

    .no-flip{
      display: none;
    }
  }
}
.LefttSideModal {
  grid-column-start: 1;
  grid-row-start: 1;
  > div{
    margin:0 0 1rem 0;
  }
}
.RightSideModal {
  grid-column-start: 2;

  @media (max-width: 767px) {
    article{
      display: none;
    }

    grid-column-start: auto;
  }
}
