.modal-body{
  max-height: 50vh;
}

@media (prefers-color-scheme: dark) {
  .modal-content {
    background: var(--secondaryBackground);
  }
}

@media (max-width: 767px) {
  .modal-dialog,.modal-content {
    margin: 0;
    width: 100vw !important;
    height: 100vh !important;
  }

  .modal-body{
    max-height: 100%;
  }
}
