.row,
.header {
  display: grid;
  grid-template-columns: 10% 10% 40% 9% 9% 9% 9%;
  justify-content: space-between;
}

.header {
  font-weight: bold;
  text-align: center;
}

.table {
  background: var(--inputBackground);
  border-radius: 10px;
  padding: 20px;
}

.row {
  padding: 0.75rem 0;
  > p {
    display: flex;
    align-items: center;
    margin: 0;
  }
}
