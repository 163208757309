.details {
  summary {
    padding: 20px 10px;
    text-align: left;
  }
  background: var(--cardBackground);
  border-radius: 10px;
  color: var(--primaryColor);
  &:hover {
    summary{
      background: var(--primaryBackground);

    }
  }
}

.customContent {
  // max-height: 0;
  overflow: hidden;
  transition: max-height 0.9s ease-out;
  padding: 20px;
}

.details[open] .customContent,
.details:not([open]) .customContent {
  // max-height: 1000px; /* Set to a sufficiently large value */
  // max-height: 100%;
}


@media (prefers-color-scheme: light) {
  .details{
    summary{
      background: var(--primaryBackground);

    }
  }
}
