.itemUser {
  display: inline-flex;
  align-items: center;
  gap: 0 10px;
  padding: 20px;
  border-radius: 5px;
  color: var(--primaryColor);
  cursor: pointer;
  svg {
    max-height: 50px;
    background-color: var(--secondaryBackground);
    border-radius: 9999px;
    padding: 10px;
  }
  background-color: var(--cardBackground);
}

.formExecutives {
  > div:last-child {
    position: sticky;
    bottom: 0;
    background: var(--secondaryBackground);
    width: 100%;
    padding: 10px 0;
    button {
      width: 100%;
    }
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: 10px 0;

  input:checked + label {
    border: 2px solid var(--primaryColor);
    animation: selectedAnimation 0.5s ease infinite alternate;
  }

  label {
    &:hover {
      border: 2px solid var(--primaryColor);
    }
  }
}


.executivesModule {
  height: 100%;
  overflow: auto;
}

@keyframes selectedAnimation {
  0% {
    border-color: var(--primaryColor);
  }
  100% {
    border-color: transparent;
  }
}
