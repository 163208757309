.tutorialAnchor {
  cursor: pointer;
  color: rgb(14, 80, 179);
  text-decoration: underline;
  margin:0;
  &:hover {
    font-weight: bold;
  }
}

@media (prefers-color-scheme: dark) {
  .tutorialAnchor{
    color:rgb(111, 169, 255);
  }
}
