.logOverview {
  color: var(--primaryColor);
  white-space: pre-wrap;
}

.containerOverviewLog {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  padding: 15px;
}

.logSummary {
  display: inline-flex;
  align-items: center;
  gap: 0 15px;
  margin: 0 0 0 15px;
}
