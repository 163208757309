.skeleton-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  grid-auto-rows: 40px;
  width: 100%;
  overflow:hidden ;
}

.skeleton-row {
  background-color: var(--secondaryBackground);
  border-radius: 5px;
  animation: loading 0.5s infinite alternate;
}

@keyframes loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
