.form {
  width: 50%;
  label {
    margin: 10px 0 0 0;
  }
  button {
    margin: 10px 0;
    width: 100%;
  }
}

.footerContainer{
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .form {
    width: 100%;
  }
}

