.informativeLabel {
  margin: 0 0 8px 0;
  span:first-child {
    font-weight: bold;
    &::after {
      content: ":";
    }
    margin: 0 5px 0 0;
  }
}
