.containerIncomingOverview {

  display: flex;
  flex-direction: column;
  gap:20px 0;



//   display: grid;
//   grid-template-columns: 100%;
//   grid-template-rows: 80% auto;
//   height: 100%;

//   > *:nth-child(1) {
//     overflow: auto;
//   }

//   > *:nth-child(2) {
//     padding: 0.25rem 0 0 0;
//     margin: 0.25rem 0 0 0;
//     height: 100%;
//     border: 1px solid var(--primaryColor);
//     border-style: solid none none none;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//   }
// }

// @media (max-width: 767px) {
//   .containerIncomingOverview {
//     grid-template-rows: 90% 10%;
//   }
}
