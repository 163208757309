.mobileFilter {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.footer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    height: 100% !important;
  }
}
/////////////////////////////////////////////// RESPONSIVE///////////////////////////////////////////////

@media (max-width: 767px) {
  .mobileFilter {
    display: flex;
    gap: 0 1rem;
  }
}
