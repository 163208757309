.tableCorporatives {
  p {
    margin: 0;
  }
  margin: 20px 0;
  padding: 1rem;
  background-color: var(--primaryBackground);
  border-radius: 10px;

  transition: all 0.25s ease;
  input:checked + label {
    background: var(--secondaryBackground);
  }

  label {
    &:hover {
      background: var(--secondaryBackground);
    }
  }
}

.rowsContainer {
  height: 30vh;
  overflow: auto;
}

.moreInfo {
  text-decoration: underline;
  &:hover {
    font-weight: bold;
  }
}

.row,
.header {
  display: grid;
  grid-template-columns: 5% 35% 10% 30% 10%;
  justify-content: space-between;
  p {
    padding: 0.5rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.row {
  font-weight: normal !important;
}

.header {
  font-weight: bold;
  text-align: center;
}

.cardCorporative {
  margin: 1rem 0 0 0;
  display: none;
  background: var(--primaryBackground);
  padding: 1rem;
  border-radius: 10px;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .tableCorporatives {
    background: transparent;
    padding:0;
  }

  .header,.rowsContainer{
    display: none;
  }

  .cardCorporative {
    display: block;
  }

  .containerMobilePagination {
    display: flex;
    z-index: 50;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    button{
      width: 45%;
    }
  }
}
