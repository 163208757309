.formBanner {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  justify-content: space-between;
  button{
    width: 100%;
  }
}

.inputForm {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.previewBanner{
    height: 100px;
    width: 100%;
    object-fit: contain;
}

.overview{
  padding: 0 0 20px 0;
}

@media only screen and (max-width: 768px) {
  .formBanner{
    grid-template-columns: 100%;
    gap:20px 0;
  }
}