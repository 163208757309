.Card {
  box-shadow: 0px 2.245033025741577px 23.572847366333008px 0px #00000040;
  margin: 0 0 10px 0;
  padding: 10px;
  box-sizing: border-box;
  width: 350px;
}

.bankAccountReportOptions{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.moreinfo {
  right: 10px;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #e2e22d;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  cursor: pointer;
}

.moreinfo:hover {
  transform: scale(1.1);
}

.frontSide {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 25% 50% 25%;
}

.headerCard {
  display: flex;
  justify-content: space-between;
}

.infoclave {
  margin-right: 5px;
}

.bankcode {
  display: inline-flex;
  padding-right: 5px;
}

.bankcode:after {
  content: "🟨";
}

.footerCard {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: end;
}

.bodyCard {
  padding: 15px 0;
}

@media screen and (max-width: 768px) {
  .Card {
    font-size: 0.75rem;
  }
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card,
.no-flip {
  background-color: transparent;
  width: 100%;
  height: 200px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  border-radius: 15px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: var(--primaryBackground);
  color: var(--primaryColor);
  border-radius: 15px;
  -webkit-backface-visibility: visible; /* Safari */
  backface-visibility: visible;
}

/* Style the back side */
.flip-card-back {
  /* background-color: rgba(155, 155, 155, 0.5); */
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: black;
  transform: rotateY(180deg);
  box-shadow: 0px 2.245033025741577px 23.572847366333008px 0px #00000040;
  border-radius: 15px;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap:5px;
  padding:0 10px;
}

.buttonsContainer > button , .buttonsContainer > a{
  height: 60px;
  justify-content: center;
}

.buttonsContainer > a > button{
  height: 100%;
  width: 100%;
  justify-content: center;
}

.buttonsContainer * {
  font-size: 0.85rem;
}

.buttonsContainer button {
  padding: 5px !important;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.buttonsContainer a {
  text-decoration: none;
}

.btnsections {
  width: 3.5rem;
  height: 3.5rem;
  transition: 0.3s;
  background-color: black;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 2.245033025741577px 5px 0px #00000040;
  color: white;
}

.btnsections svg {
  height: 1.5rem;
  width: 1.5rem;
}

.btnsections svg {
  font-size: 1.25rem;
}

.btnsections:hover {
  transform: scale(1.15);
}

.containercardback {
  display: flex;
  justify-content: center;
  align-items: center;
}
