.mobileView {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 90%;
  overflow: auto;
}

.titleIndex {
  font-size: 2.5rem;
  text-align: center;
}

.containerHomeQuotes{
  height: 100%;
  overflow: auto;
}

.containerQuotes {
  margin: 1rem 5rem 0 5rem;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .titleIndex {
    font-size: 1rem;
  }

  .containerQuotes {
    margin: 1rem 1rem 0 1rem;
  }
}
