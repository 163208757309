.commentedBy {
  display: inline-flex;
  align-items: center;
  &:after {
    content: "-";
    margin: 0 10px;
  }
}

.commentsContainer {
  max-height: 150px;
  overflow: auto;
  margin: 10px 0 !important;
}
