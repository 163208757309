.desktopFilter {
  display: flex;
  gap: 0 10px;
  align-items: flex-end;
  form {
    display: flex;
    gap: 0 10px;
  }
}

.module{
  margin:0 5%;
}