.containerBreadcrum {
  --marginList: 5px;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(5, 10px);

  > div:nth-child(1),
  > div:nth-child(2) {
    grid-column: 1/1;
    color: var(--primaryColor);
  }

  > div:nth-child(1) {
    grid-row: 1/3;
    border-radius: 0 0 10px 10px;
    background: var(--primaryColor);
  }

  ul {
    background: var(--primaryColor);
    height: 100%;
    margin: 0;
    padding: 0 20px;
    width: 80%;
    display: flex;
    gap: 0 var(--marginList);
    * {
      color: white !important;
      font-size: 1.35rem;
    }

    li:nth-last-child(2) {
      font-weight: bold;
    }

    li {
      a {
        text-decoration: underline;
      }

      cursor: default;

      &:after {
        content: " > ";
        font-weight: normal;
        margin: 0 var(--marginList);
      }

      &:last-child {
        a {
          text-decoration: none;
        }

        &:after {
          content: "";
        }
      }
    }

    border-radius: 0 0 40px 40px;
    justify-content: center;
    align-items: center;
  }

  > div:nth-child(2) {
    padding: 0;
    margin: 0;
    grid-row: 1/6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (prefers-color-scheme: dark) {
  .containerBreadcrum {
    background: var(--secondaryBackground);
    > div:nth-child(1),
    ul {
      background: var(--primaryBackground) !important;
    }

    
  }
}

@media (prefers-color-scheme: light) {
  .containerBreadcrum {
    background: white;
  }
}
