.grid {
  p {
    margin: 0;
  }
}

:global {
  .rdt_TableRow {
    .rdt_TableCell:not(:last-child) {
      > div {
        width: 100%;
      }
    }
  }
}
