.Container {
    background-color: white;
    display: inline-flex;
    width: 100%;
    height: 100%;
}

.ContainerTicketsSection {
    width: 25%;
    height: 80vh;
    background-color: white;
}


.HeaderTicketsSort{
    padding: 10px;
    border-radius: 0px;
}

.MyTickets{
    font-size: 0.85rem;
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
}

.ContainerButtons{
    display: inline-flex;
    padding-bottom: 10px;
    padding-left: 2%;
}

.SortButton {
    border-radius: 10px;
    box-shadow: 0px 0px 3px black;
    width: fit-content;
    margin-right: 5%;
}

.SortTicket {
    padding:  5px 10px 5px 10px;
    margin: 0;
    border: 0 !important;
    height: min-content !important;
    padding: 5px 15px 5px 15px !important;
}

.ContainerTickets {
    border-radius: 10px;
    height: 65%;
    box-shadow: 0px 0px 3px black;
    background-color: white;
    overflow-y: scroll;
}

.ContainerTickets::-webkit-scrollbar {
    -webkit-appearance: none;
}

.ContainerTickets::-webkit-scrollbar:vertical {
    width:8px;
}

.ContainerTickets::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
    border: none;

}

.divcomment{
    z-index: 0 !important;
}

.txtlabelcomment{
    font-size: 0.85rem;
}

.ContainerTickets::-webkit-scrollbar-track {
    border-radius: 10px;  
}

.ContainerTicketsView {
    cursor: pointer;
    padding-bottom: 5px;
    box-shadow: 0px 0px 2px black;
    transition: 0.3s;
}
.ContainerTicketsViewNoActive {
    background-color: white;
    transition: 0.3s;
}

.ContainerTicketsViewActive {
    background-color: black;
    color: white;
    transition: 0.3s;
}

.ContainerTicketsView:hover{
    padding-top: 5px;
    padding-bottom: 10px;
    background-color: black !important;
    color: white;
}

.HeaderTicketView {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.TitleTicketView {
    margin: 0 ;
}

.CompleteTicket {
    height: 5%;
}

.BodyTicketView {
    font-size: 0.8rem;
}

/* ----------------------------------------------*/

.ContainerShowTickets {
    /* display: flex; */
    width: 100%;
    padding-left: 0 !important;
}

.addTickets {
    width: 100%;
    height: 20%;
}

.ContainerAddButton {
    width: 100%;
    height: 9.5%;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: white;
}

.ButtonAddTickets { /* 25 pixeles padding bottom */
    padding-bottom: 5px;  
    margin-right: 10px;
    border-radius: 10px !important; 
    box-shadow: 0px 0px 3px black;
    height: 80% !important;
    margin-bottom: 5px;
}

.ButtonAddTickets:hover {
    border: 0 !important;
    height: 80% !important;  
    border-radius: 10px !important; 
    box-shadow: 0px 0px 3px black !important;
}

input[type=radio]{
    accent-color: black;
}

.ContainerFullTicket {
    background-color: white;
    height: 80%;
    border-radius: 20px;
    box-shadow: 0px 0px 3px black;
}

.TitleFullTicket {
    padding-bottom: 0 !important;
    font-size: 1rem;
}

.assignto {
    display: inline-flex;
}

.TxtAssign {
    margin-bottom: 0;
    font-size: 0.85rem;
}

.FirstDateAssign{
    padding-right: 2px !important;
}

.InpText {
    font-size: 0.85rem;
}

/* .SelectAssign{
    margin-left: 10px;
    font-size: 0.85rem;
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
    outline: none;
    border: 0;
    box-shadow: 0px 0px 3px black;
} */

.InfoDates {
    display: inline-flex;
    font-size: 0.85rem;
}

.ContainerDescription{
    font-size: 0.85rem;
}

.CommentSection{
    display: flex;
}

.divcomment{
    display: contents !important;
}

.ButtonSelected{
    background-color: black !important;
    color: white !important;
    transition: .5s !important;
    border: 0 !important;
}

.ButtonSelected:hover{
    background-color: white !important;
    color: black !important;
}

.ButtonNoSelected {
    background-color: white !important;
    color: black !important;
    transition: .5s !important;
    border: 0 !important;
}
.ButtonNoSelected:hover{
    background-color: black !important;
    color: white !important;
}
