.form {
  display: grid;
  grid-template-columns: 50% 23% 23%;
  justify-content: space-between;
  padding: 50px 0;
}

.optionsDownloadBankAccount{
  display: flex;
  justify-content: flex-end;
  gap:10px;
}