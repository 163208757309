.table {
  background: var(--primaryBackground);
  padding: 10px;
  border-radius: 10px;

  transition: background 0.25s ease;

  input:checked + label {
    background: var(--secondaryBackground);
  }

  input:disabled + label,
  input:disabled {
    cursor: url("./not_allowed.png"), auto;
  }
}

.content {
  height: 35vh;
  overflow: auto;
}

.moreInfo {
  text-decoration: underline;
  margin: 0;
  &:hover {
    font-weight: bold;
  }
}

.row,
.header {
  display: grid;
  grid-template-columns: repeat(6, 15%);
  justify-content: space-between;
}

.header {
  font-weight: bold;
  text-align: center;
}

.row {
  font-weight: normal !important;
  padding: 0.75rem 0;
  transition: background 0.25s ease;
  &:hover {
    background: var(--secondaryBackground);
  }
}
