.date {
    display: flex;
    gap:0 1rem;
}

.status{

}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .date,.status {
    display: none;
  }
}
