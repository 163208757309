.emailContainer {
  details {
    background: var(--cardBackground);
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
  }

  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 0 15px !important;
  margin:15px 0;
  display: flex;    
  flex-direction: column;
  gap:10px 0;

  summary {
    color: var(--primaryColor);
    font-weight: bold;
  }
}

.updateFooter{
  button{
    width: 100%;

  }
  padding: 15px;
  position: sticky;
  background: var(--cardBackground);
  margin:10px 0 0 0;
  bottom:0;
}