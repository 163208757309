.commentOc {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  time {
    color: var(--primaryColor);
    font-weight: bold;
  }
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background: var(--cardBackground);
  border-radius: 10px;
  padding: 15px;
}

.commentsOc {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  margin: 0 0 10px 0;
}

.sentByEmail {
  padding: 5px 15px;
  background: var(--secondaryBackground);
  color: var(--primaryColor);
  border-radius: 4px;
}

.commentedAt {
  display: flex;
  gap: 0 10px;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .commentedAt {
    flex-direction: column;
    gap: 10px 0;
    align-items: flex-start;
  }
}
