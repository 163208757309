.containerAddUen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;

    font-weight: bold;
  }
}
