.card {
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 1.06em;
  width: 100%;
  padding: 20px;
  background-color: white;
  height: 100%;
  display: grid;
  grid-template-columns: 90% 7%;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 0 15px 0;
}

.infoCard {
  display: grid;
  grid-template-columns: repeat(5, 20%);
}

.delete {
  border-radius: 50% !important;
  width: 35px !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
