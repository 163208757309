.item {
  background: var(--primaryBackground);
  padding: 15px;
  margin: 0 0 1rem 0;
  border-radius: 10px;
  display: none;
}

.related {
  display: flex;
  gap: 0 1rem;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .item {
    display: flex;
    flex-direction: column;
    gap:1rem 0;
  }
}
