.lengthInput {
  span {
    vertical-align: super;
    font-size: smaller;
  }

  margin: 0 0 0 5px;

  span:nth-child(1) {
    &:before {
      content: "(";
    }

    &:after {
      content: "/";
      margin: 0 5px;
    }
  }

  span:nth-child(2) {
    &:after {
      content: ")";
    }
  }
}
