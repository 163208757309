.licenseValidation {
  grid-template-columns: repeat(10, 10%);
  grid-template-rows: repeat(10, 10%);
  width: 100svw;
  height: 100svh;
  display: grid;
  background: var(--primaryBackground);
  color: var(--primaryColor);
}

.loadingMessage {
  grid-column: 5/7;
  grid-row: 5/7;
  display: flex;
  justify-content: center;
  align-items: center;
}
