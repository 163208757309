.customerInfo {
  display: flex;

  p {
    margin: 0;
  }

  > p:nth-child(1) {
    font-weight: bold;
    &:after {
      content: ":";
      margin: 0 0.25rem;
    }
  }
}

@media (max-width: 767px) {
  .customerInfo {
    display: none;
  }
}
