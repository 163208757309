.containerTotals {
  display: grid;
  grid-template-columns: repeat(13, 7.69%);

  p {
    margin: 0;
    padding: 15px 16px;
    box-sizing: border-box;
    text-align: right;
  }

  p:nth-child(2) {
    font-weight: bold;
  }

  p:nth-child(1) {
    grid-column: 1/4;
  }
}

@media (prefers-color-scheme: dark) {
  .containerTotals {
    background: #424242;
  }
}
