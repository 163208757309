.header {
  display: flex;
  gap: 0 20px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 2px 0;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
  }
}
