.filter {
  display: flex;
  gap: 10px;
  margin: 0 20px 10px 20px;
  align-items: flex-end;


}

.module {
  height: 100%;
}

@media only screen and (max-width: 1023px) {
  .filter {
    flex-direction: column;
    gap: 10px;

    button{
      display: none;
    }

    > * {
      width: 100%;
    }
  }

  .desktopFilter{
    display: none;
  }
}
