.emailModalBox {
  margin: 10px 0;
  .ql-toolbar {
    position: sticky;
    z-index: 10;
    background-color: white;
  }

  .ql-container {
    height: 100px;
    overflow: auto;
  }
}
