.bankAccountReport {
  display: flex;
  justify-content: center;
  p {
    margin: 0;
  }
  gap: 0 2rem;
}

@media (max-width: 767px) {
  .bankAccountReport {
    flex-direction: column;
    gap: 1rem 0;
  }
}
