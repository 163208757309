.boxComment {
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-between;

  svg {
    display: block !important;
  }

  button {
    height: 100% !important;
  }
}

.containerAction {
  width: auto;
  &:hover {
    border: 1px solid var(--primaryColor);
  }
}

.variables {
  border: 1px solid var(--primaryColor);
  border-style: solid solid none solid;
  padding: 5px 10px;
  display: flex;
  gap: 0 10px;
  flex-wrap: wrap;  

  button {
    min-width: 20px;
    background: none;
    color: var(--primaryColor);
    border: 2px solid var(--primaryBackground);
    background: var(--primaryBackground);
    padding: 7px 10px;
    border-radius: 99999px;

    &:hover {
      animation: hoverVariable 0.6s ease infinite alternate-reverse;
    }
  }
}

.variable,
.complemento {
  width: 100%;
  color: var(--primaryColor);
  &:after {
    content: "Complemento";
  }
}

.quill {
  background: var(--cardBackground);
  * {
    border-color: var(--primaryColor) !important;
    color: var(--primaryColor) !important;

    border: none !important;
  }

  > pre {
    height: 250px;
  }

  border: 1px solid #b7b7b7;
}

@keyframes hoverVariable {
  from {
    border-color: var(--primaryBackground);
  }

  to {
    border-color: var(--primaryColor);
  }
}
