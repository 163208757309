///////////////////////////////// [VARIABLES] /////////////////////////
// FONTS
$font-generl: "Century Gothic";
$font-size-sm: 0.5em;
$font-size-md: 0.75em;
$font-size-lg:1em;
$prim-color-text:#000;
$secon-color-text: #2b2b2b;
$black2:#000;

// TABLE
$row-selected:#EBEBEB;

// INPUTS
$border-input-color:#E5E5E5;
$bg-input-color:#F9F9F9;
$place-holder-color:#737373;
//Header
$header-font-size: 1.357em;
$white2: #ffff;