.containerDate {
  height: 50px;
  width: 100%;
  background-color: #f9f9f9;
  color: #737373;
  .react-date-picker__wrapper {
    border-radius: 5px;
    border: 0.1875em solid #e5e5e5;
    padding: 0 5px;
  }
}
