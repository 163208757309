.uensList {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0;
  li {
    margin: 0;
    &:after {
      content: " , ";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}

.uen {
  display: flex;
  p {
    &:after {
      content: ":";
    }
  }
}
