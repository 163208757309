.bankInfo {
  display: flex;
}

.bankInfo > div {
  margin: 0 30px 0 0;
}

.headerConcilation {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  align-items: center;
  p {
    margin: 0;
  }

  button {
    margin: 0 0 0 10px;
  }
}

.containerFilter{
  padding:1rem 2rem 0 2rem;
}

.filterConcilation {
  display: flex;
  gap: 0 1rem;
  > div {
    width: 12%;
  }

  > div:nth-child(5) {
    width: 20%;
  }
}

.optionsRow {
  display: flex;
  margin: 20px 0 0 0;
  gap: 15px;
}

.mobileOptions{
  display: none;
  gap:0 0.5rem;
  overflow: auto;
  margin:0.5rem 0;
}

.topConcilations{
  position: sticky;
  top:0;
  background: white;
  padding:0 0 1rem 0;
}

.containerConcilations{
  height: 100%;
  overflow: auto;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {

  .containerRows{
    height: auto;
  }

  .mobileOptions{
    display: flex;
  }

  .container{
    background: transparent;
    padding:0 2rem;
  }

  .optionsRow{
    display: none;
  }

  .filterConcilation {
    > * {
      display: none;
    }

    > *:nth-child(5) {
      display: block;
      width: 100% !important;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .topConcilations{
    background: var(--secondaryBackground);
  }
}
