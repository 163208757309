.cardContainer {
  max-height: 50vh;
  overflow-y: auto;
  padding: 25px;
}

.tutorial {
  width: 100%;
  height: 500px;
}

.card {
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 1.06em;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
  > div:nth-child(1) {
    width: 90%;
    overflow-y: auto;
    max-height: 3rem;
  }
  > div:nth-child(2) {
    width: 10%;
    display: flex;
    justify-content: space-evenly;
  }
  .controls {
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    transition: background 0.15s ease-in;
    &:hover {
      background-color: #ebebeb;
    }
  }
}

.delete {
  background-size: 15px;
  background-image: url("./trash-can.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: background 0.15s ease-in;
  &:hover {
    background-color: #ebebeb;
  }
}

.edit {
  background-size: 15px;
  background-image: url("./pen.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: background 0.15s ease-in;
  &:hover {
    background-color: #ebebeb;
  }
}

.save {
  background-size: 15px;
  background-image: url("./save.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: background 0.15s ease-in;
  &:hover {
    background-color: #ebebeb;
  }
}

.xmark {
  background-size: 15px;
  background-image: url("./xmark.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: background 0.15s ease-in;
  &:hover {
    background-color: #ebebeb;
  }
}

.pasteSolid {
  background-size: 15px;
  background-image: url("./paste-solid.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: background 0.15s ease-in;
  &:hover {
    background-color: #ebebeb;
  }
}
