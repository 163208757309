.containerToDos {
  display: grid;
  grid-template-columns: 8% 90%;
  justify-content: space-around;
  flex: 1;
  height: 100%;
  overflow: auto;
  margin: 30px 5rem;

  > *:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap:1rem 0;
  }
}

.navigationToDos {
  display: flex;
  flex-direction: column;
  button {
    border: 1px solid var(--primaryColor);
    border-style: none none solid none;
  }

  :first-child {
    border-radius: 10px 10px 0 0;
  }
  :last-child {
    border-radius: 0 0 10px 10px;
    border-style: none;
  }
}

.containerCards {
  display: grid;
  grid-template-rows: 90% 10%;
  background: var(--primaryBackground);
  overflow: auto;
  max-height: 100%;
  overflow-y: auto;
  padding: 15px;
  border-radius: 15px;
}

.module {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  > *:nth-child(1) {
    padding: 0 0 1rem 0;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .containerToDos {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
    margin: 1rem;
    justify-content: flex-start;

    > *:nth-child(1) {
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .navigationToDos {
    display: none;
  }
}
