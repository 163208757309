.header,
.row {
  display: grid;
  grid-template-columns: 7.5% 7.5% 7.5% 7.5% 30% 10% 10% 10% 10%;
}

.header {
  font-weight: bold;
  text-align: center;
  align-items: center;
  p {
    margin: 0;
  }
  font-size: 0.75rem;
}

.row {
  font-weight: normal;
  font-size: 0.75rem;
  padding: 15px 5px;
  align-items: center;
}

.containerRow {
  input[type="radio"]:checked + label {
    transition: 0.45s ease;
    background-color: var(--selectedRow);
  }

  transition: background 0.25s ease;

  &:hover {
    background: var(--secondaryBackground);
  }
}

.table {
  color:var(--primaryColor);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  padding: 20px;
  background: var(--inputBackground);
  border-radius: 10px;
}

.containerRows {
  height: 35vh;
  overflow: auto;
  margin: 10px 0;
}

.moduleInvoiceReception {
  height: 100%;
  overflow: auto;
  margin:0 2rem;
  
}

.headerModuleInvoiceReception {
  position: sticky;
  top: 0;
  padding: 0 0 1rem 0;
  background: white;

}


/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .table {
    background: transparent;
    padding: 0;
    margin: 0 0 1rem 0;
  }

  .moduleInvoiceReception{
    margin:0;
    padding:0 1rem;
  }

  .containerRow {
    display: none;
  }

  .containerRows {
    height: 100%;
    max-height: 100%;
  }

  .header {
    display: none;
  }
}

/////////////////////////////////////////////// MODO OSCURO ///////////////////////////////////////////////

@media (prefers-color-scheme: dark) {
  .headerModuleInvoiceReception {
    background: var(--secondaryBackground);
  }
}
