@import "../../../styles/Variables.scss";
label {
  font-weight: bold;
  display: block;
  margin: 0;
  padding: 0;
}

.eventDisabled {
  pointer-events: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputBlock {
  cursor: not-allowed;
  input {
    cursor: not-allowed;
  }
}

.form-control {
  all: none;
}

.no-spinners {
  -moz-appearance: textfield;
}

.helpText {
  color: var(--inputPlaceholder);
}

input,
.leftSideInput,
.fakeCalendar {
  background-color: var(--inputBackground);
  border: 0.1875em solid var(--borderInput);
  height: 3.125em;
  display: inline-block;
  color: var(--inputPlaceholder);
  margin: 0;
  padding: 0;
}

input {
  padding: 0 5.5px;
  border-radius: 0.3125em;
}

input[type="tel"] {
  border-radius: 0.3125em;
  padding-left: 45px;
}

/* Large toggl switches */

.custom-control-input-success:checked ~ .custom-control-label::before {
  background-color: #60195d !important;
  border-color: #60195d !important;
}

.custom-switch-lg .custom-control-label::before {
  left: -2.25rem;
  width: 3rem;
  border-radius: 1.5rem;
}

.custom-switch-lg .custom-control-label::after {
  top: calc(0.25rem + 3px);
  left: calc(-2.25rem + 4px);
  width: calc(1.5rem - 6px);
  height: calc(1.5rem - 6px);
  border-radius: 1.5rem;
}

.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.4rem);
}

.custom-switch-lg .custom-control-label::before {
  height: 1.5rem;
}

.custom-switch-lg .custom-control-label {
  padding-left: 1.5rem;
  line-height: 1.7rem;
}

.inputCellPhone {
  background-color: $bg-input-color;
  border: 0.1875em solid $border-input-color;
  height: 3.125em;
  display: inline-block;
  color: $place-holder-color;
  margin-bottom: 2;
  padding-left: 50px;
}

.fakeCalendar {
  cursor: pointer;
  border-radius: 0.3125em;
  padding: 0 0.5em;
  display: flex;
  align-items: center;
  // justify-content: center;
  p {
    margin: 0;
  }
}

input {
  width: 100%;
  outline: 0;
  line-height: 0;
}

.leftSideInput {
  border-right: 0;
  border-radius: 0.3125em 0 0 0.3125em;
  padding: 0 5px;
}

.logtransparent {
  background-color: transparent !important;
  border: transparent;
  border-bottom: 3px solid whitesmoke !important;
  color: whitesmoke;
}

textarea {
  width: 100%;
  background-color: $bg-input-color;
  border: 0.1875em solid $border-input-color;
  border-radius: 0.3125em;
  height: 10.125em;
  display: inline-block;
  color: $place-holder-color;
  margin: 0;
  padding: 1em;
  :focus {
    outline: none;
  }
}

.rightSideInput {
  // border-left: 0;
  // border-radius: 0 0.3125em 0.3125em 0;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  padding: 0 0;
}

.showPassword {
  cursor: pointer;
  border-radius: 0.3125em;
  height: 3.125em;
  margin: 0 0 0 1em;
  padding: 0 1em;
  display: flex;
  align-items: center;
}

.errorMessage {
  color: var(--red);
}

.select-css {
  background-color: $bg-input-color;
  border: 0.1875em solid $border-input-color;
  height: 3.125em;
  display: inline-block;
  color: $place-holder-color;
  margin: 0;
  padding: 0 2.5%;
  width: 100%;
  border-radius: 0.3125em;
  max-width: 100%;
}

input[type="radio"] {
  cursor: pointer;
  width: 1.6em;
  margin: 0 0.25em;
}

.formContainer {
  width: 100%;
}
.form-inline label {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 0;
}

.inputUser {
  input {
    border-radius: 0.5rem 0.5rem 0 0;
    border-style: none none solid none !important;
  }
  > div {
    span {
      color: black;

      &::after {
        content: ")";
      }
      &::before {
        content: "(";
      }
    }
  }
}

/* For modern browsers (using the standardized pseudo-class) */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  /* Set your desired background color */
  background-color: transparent; /* or any other color */
}

.select-css {
  background: url("../../../assets/dropdownSelectLightMode.png") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  background-size: 0.75rem !important;
  background-color: var(--inputBackground) !important;
  color: var(--primaryColor);
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {

  .react-tel-input{
    .country-list,.search{
      background: var(--primaryBackground) !important;
    }

    .highlight{
      background: var(--secondaryBackground) !important;
    }

    .country-list{
      li{
        &:hover{
          background: var(--secondaryBackground) !important;
        }
      }
    }

    input{
      background: var(--primaryBackground) !important;
      border-color: var(--primaryBackground) !important; 
      color:var(--primaryColor) !important;
    }

    .flag-dropdown{
      border-color:var(--primaryBackground);
    }

    .selected-flag{
      background:var(--primaryBackground);
      border-color: var(--primaryBackground);
      &:hover{
        background: var(--secondaryBackground);
      }
    }
  }

  input , textarea{
    color: var(--primaryColor);
    background-color: var(--primaryBackground);
    border-radius: 0.3125em 0.3125em 0 0;
    border-color: var(--primaryColor);
    border-style: none none solid none;
  }

  label {
    color: var(--primaryColor);
  }

  .inputUser {
    margin: 0 0 1rem 0;
    > div {
      span {
        color: var(--primaryColor);
      }
    }

    input {
      background-color: var(--secondaryBackground);
      border: 0.188rem solid var(--primaryColor);
    }
  }

  .select-css {
    background: url("../../../assets/dropdownSelectDarkMode.png") no-repeat;
    border-color: transparent;
  }
}
