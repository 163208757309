.filter {
  // display:grid;
  // grid-template-columns: 20% 20% 18% 18% 10% 10%;
  // justify-content: space-between;
  // align-items: flex-end;
  display: flex;
  gap: 0.625rem;
  align-items: flex-end;
  // grid-template-columns: 20% 20% 18% 18% 10% 10%;
  // justify-content: space-between;
  // align-items: flex-end;
}

.abcDirectory {
  padding: 1rem 25vw;
  @media (max-width: 767px) {
    padding: 1rem;
  }
}

.container {
  height: 100%;
  overflow: auto;
}

.abcOptionsDirectory {
  display: flex;
  gap: 0 1rem;
  margin:0 0 1rem 0;
  justify-content: flex-end;


}
