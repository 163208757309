.mobileItem {
  background: var(--primaryBackground);
  border-radius: 10px;
  padding: 20px;
  display: none;
  margin: 0 0 1rem 0;
  display: none;
  
}

.dates {
  display: flex;
  gap: 1rem;
}

.amounts {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .mobileItem {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
