.ticketCommentBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
  max-height: 200px;
  
  .quill {
    width: 100%;
  }
  .ql-toolbar {
    // height: 40%;
    position: sticky;
    top:0;
    background: var(--primaryBackground);
    z-index: 10;
  }
  .ql-container {
    height: 60%;
  }
  path {
    color: white;
  }

}

.attemptCommentTicket {
  width: 100%;
  margin: 1rem 0;
  padding: 1rem 0;
  color: var(--primaryColor);
  background-color: #191919;
  border: none;
  text-align: center;
  border: 0.125rem solid #191919;
  transition: all 0.4s ease;
  &:hover {
    path {
      color: #191919;
    }
    border: 0.125rem solid #191919;
    background: transparent;
    color: var(--primaryColor);
  }

  &:disabled {
    text-decoration: line-through;
    cursor: not-allowed;
  }

  &::after {
    color: white;
  }
}

//////////////////// MOBILE ////////////////////

@media (max-width: 767px) {
  .ql-toolbar {
    overflow: hidden !important;
  }

  .ticketCommentBox {
    z-index: 0;
    margin: 0 0 1rem 0;
    .ql-toolbar {
      display: flex;
      overflow-x: auto;
    }

    .ql-formats {
      display: flex !important;
    }

    flex-direction: column;
    .quill {
      width: 100%;
    }
  }
}

//////////////////// DARK MODE ////////////////////

@media (prefers-color-scheme: dark) {
  .attemptCommentTicket {
    &:hover {
      border-color: var(--primaryColor);
      path {
        color: var(--primaryColor) !important;
      }
    }
  }

  .ql-snow,
  .ql-fill,
  .ql-stroke {
    stroke: var(--primaryColor) !important;
    fill: var(--primaryColor) !important;
  }


}

@media (prefers-color-scheme: light) {

  .attemptCommentTicket {
    color:var(--secondaryBackground) !important;
    &:hover {
      border: 0.125rem solid #191919;
      background: transparent;
      color: var(--primaryColor);

      &::after {
        color: var(--primaryColor);
      }
    }
  }
}
