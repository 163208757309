.grid,
.headers,
.gridB,
.headersB,
.complementB,
.headerComplementB {
  p {
    margin: 0;
    padding: 5px 0;
  }
}

.grid,
.headers {
  display: grid;
  grid-template-columns: 40% 8.5% 8.5% 8.5% 8.5% 8.5% 8.5% 8.5%;
}

.headers {
  font-weight: bold;
  text-align: center;
}

.gridB,
.headersB {
  display: grid;
  grid-template-columns: 65% 30%;
  justify-content: space-between;
}

.headersB {
  font-weight: bold;
  text-align: center;
}

.complementB,
.headerComplementB {
  display: grid;
  grid-template-columns: repeat(6, 16%);
  justify-content: space-between;
}

.headerComplementB {
  font-weight: bold;
  text-align: center;
}
