.searchFilter {
  display: flex;
  gap: 10px;
  margin:0.5rem 0 0 0;
  padding: 0 3rem;
  align-items: flex-end;

  > div:nth-child(1) {
    min-width: 40%;
  }
}

.uenHeader {
  position: sticky;
  top: 0;
  padding: 0 0 1rem 0;
  background: white;
}

.uenContainer {
  overflow: auto;
  height: 100%;
  overflow: auto;
}

@media (prefers-color-scheme: dark) {
  .uenHeader {
    background: var(--secondaryBackground);
  }
}
