.container {
  img {
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
  }
}

.infoLabel {
  display: flex;
  align-items: center;
}

.warnings2fa , .instructions2fa , .recoveryItems, .twoFaExplain {
  li {
    margin:0 0 0.5rem 0;
    list-style: decimal !important;
    &::before {
      font-weight: bold !important;
    }
  }
}

.success2fa{
  margin:2rem;
  text-align: center;
}

.qrCodeContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpContainer{
  margin:1rem 0;
  p{
    font-weight: bold;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span{
    margin:0 0.5rem;
  }
}

.containerButton{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cancelFooter{
  display: flex;
  justify-content: space-between;
  align-items: center;
}