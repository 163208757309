.table {
  background: var(--inputBackground);
  padding: 15px;
  border-radius: 10px;
  p {
    margin: 0;
  }

  transition: background 0.25s ease;
  input:checked + label {
    background: var(--selectedRow);
  }
}

.row,
.header {
  display: grid;
  grid-template-columns: 10% 14.25% 14.25% 7.5% 10% 10% 10% 10% 10%;
  justify-content: space-between;
}

.header {
  font-weight: bold;
  text-align: center;
}

.row {
  > p {
    overflow: hidden; /* Hide overflowing content */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; 
  }
  font-weight: normal !important;
  padding: 0.75rem 0;
  transition: background 0.25s ease;
  &:hover {
    background: var(--primaryBackground);
  }
}

.containerRows {
  height: 35vh;
  overflow: auto;
}

.moreInfo {
  text-decoration: underline;
  text-align: center;
  &:hover {
    font-weight: bold;
  }
}

.loadingSpinner {
  display: none;
}

////////////////////////////////////////////  [RESPONSIVE]  ////////////////////////////////////////////

@media (max-width: 767px) {
  .loadingSpinner {
    display: flex;
    margin: 1rem 0;
    justify-content: center;
  }

  .table {
    background: transparent;
    padding: 0;
  }

  .header,
  .row {
    display: none;
  }

  .containerRows {
    height: 100%;
  }
}
