.effect {
  height: 50px;
  width: 175px;
  padding: 0 10px;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  transition: all 0.2s linear 0s;
  outline: none;
  text-align: center;
  display: flex;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  // &:hover ::before {
  //     background-color: #000;
  //     color: #fff;
  // }
  &:hover::after {
    content: "\00a0 \00a0 Exportar PDF";
    right: -20px;
    // background-color: #000;
    // color: #fff;
  }
  &:hover {
    background-color: #000;
    color: #fff;
    .pdfIcon {
      color: red;
    }
  }
}
.effectEmail {
  height: 50px;
  padding: 0 10px;
  outline: none;
  border: none;
  // max-width: -20px;
  width: 175px;
  font-weight: bold;
  border-radius: 10px;
  transition: all 0.2s linear 0s;
  outline: none;
  text-align: center;
  display: flex;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  &:hover ::before {
    background-color: #000;
    color: #fff;
  }
  &:hover::after {
    content: "\00a0 \00a0 Enviar documento";
    right: -20px;
    // background-color: #000;
    // color: #fff;
  }
  &:hover {
    background-color: #000;
    color: #fff;
    .pdfIcon {
      color: rgb(65, 179, 255);
    }
  }
}
