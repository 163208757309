.RatingContainer{
    padding: 15px;
    width: 100%;
}
.stars {
    --s:50px;
    position:relative;
    display:inline-flex;
    margin-left: 2%;
  }
  .stars input {
    width:var(--s);
    height:var(--s);
    margin:0;
    opacity:0;
    cursor:pointer;
  }
  .stars i {
    position:absolute;
    inset:0 0 calc(var(--s)*0.1);
    pointer-events:none;
    --v1:transparent,#000 0.5deg 108deg,#0000 109deg;
    --v2:transparent,#000 0.5deg  36deg,#0000  37deg;
    -webkit-mask:
      conic-gradient(from 54deg  at calc(var(--s)*0.68) calc(var(--s)*0.57),var(--v1)),
      conic-gradient(from 90deg  at calc(var(--s)*0.02) calc(var(--s)*0.35),var(--v2)),
      conic-gradient(from 126deg at calc(var(--s)*0.5)  calc(var(--s)*0.7) ,var(--v1)),
      conic-gradient(from 162deg at calc(var(--s)*0.5)  0                  ,var(--v2));
    -webkit-mask-size: var(--s) var(--s);
    -webkit-mask-composite: xor,destination-over;
    mask-composite: exclude,add;
    background:
      linear-gradient(rgba(218,165,32,var(--o,0.3)) 0 0),  
      linear-gradient(gold                       0 0)
      #ccc;
    background-size:calc(var(--l,0)*var(--s)) 100%,calc(var(--p,0)*var(--s)) 100%;
    background-repeat:no-repeat;
  }
  
  /* .stars:focus-within {
    outline:1px solid;
  } */
  
  input:active ~ i{--o:1}
  
  input:nth-of-type(1):checked ~ i {--p:1}
  input:nth-of-type(2):checked ~ i {--p:2}
  input:nth-of-type(3):checked ~ i {--p:3}
  input:nth-of-type(4):checked ~ i {--p:4}
  input:nth-of-type(5):checked ~ i {--p:5}
  input:nth-of-type(6):checked ~ i {--p:6}
  input:nth-of-type(7):checked ~ i {--p:7}
  input:nth-of-type(8):checked ~ i {--p:8}
  input:nth-of-type(9):checked ~ i {--p:9}
  input:nth-of-type(10):checked ~ i {--p:10}
  /*input:nth-of-type(N):checked ~ i {--p:N}*/
  
  input:nth-of-type(1):hover ~ i {--l:1}
  input:nth-of-type(2):hover ~ i {--l:2}
  input:nth-of-type(3):hover ~ i {--l:3}
  input:nth-of-type(4):hover ~ i {--l:4}
  input:nth-of-type(5):hover ~ i {--l:5}
  input:nth-of-type(6):hover ~ i {--l:6}
  input:nth-of-type(7):hover ~ i {--l:7}
  input:nth-of-type(8):hover ~ i {--l:8}
  input:nth-of-type(9):hover ~ i {--l:9}
  input:nth-of-type(10):hover ~ i {--l:10}
  /*input:nth-of-type(N):hover ~ i {--l:N}*/
  
  .divcomment, .commentrating{
    padding-top: 5px;
    padding-bottom: 50px;
    width: 100% !important;
  }

  .positionButton{
    display: flex;
    justify-content: end;
  }