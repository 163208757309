.filterMobile {
  display: none;
}

.contentFilter {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.footerFilter {
  display: flex;
  justify-content: flex-end;
  gap: 0 1rem;
  height: 100%;
  > button {
    padding: 0 1rem;
  }
}

@media (max-width: 767px) {
  .footerFilter {
    > button {
      height: 100%;
    }
  }

  .filterMobile {
    display: flex;
    overflow: auto;
    margin:1rem 0 0 0;
    padding: 0 0 1rem 0;

    > div:nth-child(1) {
      position: sticky;
      left: 0;
      padding: 0 1rem 0 0;
      background: var(--secondaryBackground);
    }

    > *:nth-child(n + 2) {
      margin: 0 1rem 0 0;
    }

    button {
      padding: 0 1rem;
    }
  }
}

/////////////////////////////////////////////// MODO CLARO ///////////////////////////////////////////////

@media (prefers-color-scheme: light) {
  .filterMobile {
    > div:nth-child(1) {
      background: #ffffff;
    }
  }
}
