.dropzone {
  width: 100%;
  height: 400px;
  border: 7.5px dashed var(--primaryBackground);
}

.fileList{
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  gap:10px;
  > *{
    width: 300px;
  }
}