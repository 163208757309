.options {
  height: 3.125rem;
  display: flex;
  gap: 0 1rem;
  margin: 0 0 1rem 0;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .options {
    display: none;
    height: 0;
  }
}
