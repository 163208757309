.notRated,
.rated {
  display: inline-flex;
  align-items: center;
  margin: 0 !important;
}

.notRated {
  svg {
    fill: gray;
    color: gray;
    width: 20px;
  }
}

.rated {
  svg {
    color: gold;
    fill: gold;
    width: 20px;
  }
}
