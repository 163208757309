.footerCorporative {
  display: flex;
  justify-content: flex-end;
}



@media (max-width: 767px) {
  .footerCorporative {
    display: none;
  }
}
