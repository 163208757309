.header {
  position: sticky;
  top: 0;

  form{
    margin: 1.25rem 0 0 0 ;
  }
}

.container {
  height: 100%;
  overflow: auto;
}

.content{
  width: 50vw;
  margin: 0 0 0 25%;
}