.header,
.row {
  display: grid;
  grid-template-columns: 5% 25% 10% 20% 13.33% 13.33% 13.33%;
}

.header {
  label {
    padding: 10px;
    transition: 0.15s ease-in;
    &:hover {
      background-color: rgb(223, 223, 223);
    }
  }
  p {
    margin: 0;
  }
  padding: 5px 0;
  :nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.header {
  text-align: center;
}

.row {
  padding: 7.5px 0;
  p {
    margin: 0;
  }

  :nth-child(2),
  :nth-child(3),
  :nth-child(4) {
    display: flex;
    align-items: center;
  }

  :nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :nth-child(5),
  :nth-child(6),
  :nth-child(7) {
    display: flex;
    justify-content: right;
    align-items: center;
  }
  font-weight: normal;
  transition: 0.15s ease-in;
  &:hover {
    background-color: rgb(223, 223, 223);
  }
}
