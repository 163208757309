.bankAccountReport {
  display: flex;
  justify-content: space-between;
  p{
    margin:0;
  }
  > *:nth-child(1) {
    width: 50%;
  }

  > *:nth-child(2) {
    width: 46%;
  }
}

@media (max-width: 767px) {
  .bankAccountReport {
    flex-direction: column;
    gap: 1rem 0;

    > *:nth-child(1),
    > *:nth-child(2) {
      width: 100%;
    }
  }
}
