.directory {
  padding: 0 1rem 1rem 1rem;
  height: 100%;
  overflow: auto;
}

.filter {
  display: flex;
  align-items: flex-end;

  gap: 0 1rem;

  > *:nth-child(n + 1) {
    width: 10%;
  }

  > *:nth-child(3) {
    width: 20%;
  }

  > *:nth-child(n + 4) {
    width: auto;
  }
}

.headerDirectory {
  position: sticky;
  top: 0;
}

.containerFilter {
  // position: sticky;
  // top: 0;
  background: var(--secondaryBackground);
  padding: 0.5rem 0;
}

.filterMobile {
  color: var(--primaryColor);
  display: none;
}

.tableDirectory,
.cardDirectory {
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.row,
.header {
  display: grid;
  grid-template-columns: 5% 27.5% 25% 10% 7.5% 10% 7.5% 7.5%;
}

.header {
  text-align: center;
  font-weight: bold;
  > p {
    padding: 0;
  }
}

.moreInfoItem {
  text-decoration: underline;
  &:hover {
    font-weight: bold;
  }
}

.row {
  font-weight: normal !important;
  p {
    margin: 0;
    padding: 1rem 0;
  }
  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: var(--secondaryBackground);
  }
}

.container {
  padding: 1rem 5rem;
}

.containerRows {
  height: 40vh;
  overflow: auto;
  transition: all 0.25s ease;

  input:checked + label {
    background: var(--secondaryBackground);
  }
}

.cardDirectory {
  display: none;
}

.titleCard {
  display: flex;
  justify-content: space-between;
}

.desktopOptions {
  height: 3.125rem;
  display: flex;
  gap: 0 1rem;
  margin: 0 0 0.5rem 0;
}

.optionMobile {
  > p {
    margin: 0;
    display: none;
  }
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .tableDirectory {
    background: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    overflow: auto;
  }

  .optionMobile {
    > p {
      display: block;
    }

    > button {
      display: none;
    }
  }

  .desktopOptions {
    display: none;
  }

  .cardDirectory {
    display: block;
    margin: 0 0 1rem 0;
  }

  .header,
  .containerRows {
    display: none;
  }

  .container {
    padding: 0 1rem 1rem 1rem;
    overflow: auto;
  }

  .filter {
    width: 100%;
    gap: 0 0.5rem;
    justify-content: space-between;

    > *:nth-child(3) {
      width: 75%;
    }

    > *:nth-child(1),
    > *:nth-child(2) {
      display: none;
    }
  }

  .containerFilter {
    form {
      button {
        padding: 0 1rem;
      }
    }
  }

  .filterMobile {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    gap: 0 0.5rem;

    > p {
      height: 100%;
      margin:0;
      width: 100%;
      display: inline-flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      background: var(--primaryBackground);
      border-radius: 10px;
      padding: 0 1rem;
    }
  }
}

@media (prefers-color-scheme: light) {
  .containerFilter {
    background: white;
  }
}
