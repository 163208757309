.containerOptionsUen {
  min-height: 3.125rem;
  margin: 0 0 1rem 0;
}

/////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

@media (max-width: 767px) {
  .containerOptionsUen {
    height: 0;
    min-height: 0;

    > button{
      display: none;
    }
  }
}
