.action,
.add {
  background: var(--primaryBackground);
  color: var(--primaryColor);
  border-radius: 99999999999999999px;
  width: 30px ;
  
  border: none;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--cardBackground);
  }
  transition: background 0.25s ease;

  &:disabled {
    cursor: not-allowed;
  }
}

.add {
  border-radius: 5px;

}

.actions {
  display: flex;
  justify-content: space-evenly;
  gap: 0 10px;
  height: 100%;
  svg {
    height: 100%;
  }
}

.delete {
  display: flex;
  justify-content: flex-end;
  gap: 0 10px;
}

@media only screen and (max-width: 1023px) {
  .action{
    width: 45%;
    height: 50px;
    border-radius: 0;
  }

  .actions{
    justify-content: space-between;
  }
}
