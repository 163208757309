.search {
  display: flex;
  border: 0.1875em solid #e5e5e5;
  border-style: solid solid solid solid;
  border-radius: 0.3125em !important;
  height: 2.8rem !important;
  box-sizing: content-box;
  background: var(--secondaryBackground);
  input {
    border: none !important;
    height: 100% !important;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.35rem 0.25rem;
    transition: 0.2s ease;
    width: 2.3rem;
    border-radius: 50%;
    &:hover {
      background: var(--secondaryBackground);
    }
    background: var(--primaryBackground);
    border: none;

    svg {
      color: var(--primaryColor);
    }
  }
}

//////////////////////// DARK MODE ////////////////////////

@media (prefers-color-scheme: dark) {
  .search {
    border-color: var(--primaryBackground);
    background: var(--primaryBackground);
  }
}
