.table {
  background: var(--inputBackground);
  border-radius: 5px;
}

.contentTable {
  margin: 0 10px;
  input:checked + label {
    background: var(--selectedRow);
  }

  input:invalid {
    border: 2px solid var(--red) !important;
  }

  

  label {
    padding: 5px 0;
    font-weight: normal !important;

    > input::placeholder {
      color: var(--red) !important;
    }

    > p {
      margin: 0;
      padding: 10px 0;
      display: flex;
      align-items: center;
    }
  }

  transition: background 0.5s ease;
  label:hover {
    background: var(--secondaryBackground);
  }
}

.header {
  position: sticky;
  top: 0;
  text-align: center;
  font-weight: bold;
  position: sticky;
  background: var(--inputBackground);
  top: 0;
  z-index: 1;
  > p {
    margin: 0;
    padding: 5px 0;
  }
}

.columnsA,
.columnsB {
  display: grid;
  justify-content: space-between;
  margin: 0 10px;
  align-items: center;
}

.columnsB {
  grid-template-columns: 5% 5% 13% 13% 7.5% 7.5% 7.5% 7.5% 7.5% 7.5% 7.5%;
}

.columnsA {
  grid-template-columns: repeat(13, 7.6%);
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .table {
    background: transparent;
    height: 100%;
  }

  .columnsA,
  .columnsB,
  .header {
    display: none !important;
  }

  .contentTable{
    margin:0;
  }

  .contentTable > label{
    display: none !important;
  }

}
